import React from "react";

const WarningFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 3.5C2.5 2.67158 3.17158 2 4 2H16C16.8285 2 17.5 2.67158 17.5 3.5V22H4C3.17158 22 2.5 21.3284 2.5 20.5V3.5Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 12C17.5 11.4477 17.9477 11 18.5 11H20.5C21.0523 11 21.5 11.4477 21.5 12V20.5C21.5 21.3285 20.8285 22 20 22H17.5V12Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 5H9.5"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 7.5H13.5"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default WarningFilledIcon;
