import React from "react";
import "./Tag.css";
import Tooltip from "@mui/material/Tooltip";
const Tag = ({ data, fullLength }) => {
  const content = data[0];
  //if there are more than two tags and length of content is less than 10, then Trim is required
  const isTrimRequired = content.length > 10 && !fullLength;

  return (
    // Choosing when to show the tooltip
    <Tooltip title={isTrimRequired ? content : ""} placement="top">
      <div
        className={fullLength ? "tag-full-length" : "tag"}
        style={{
          backgroundColor: data[1] === "white" ? "gray" : data[1],
        }}
      >
        {/* Showing the trimmed content if required else normal content */}
        {isTrimRequired ? content.substring(0, 9) + "..." : content}
      </div>
    </Tooltip>
  );
};

export default Tag;
