import React from "react";
import Switch from "@mui/material/Switch";
import "../CustomToggle/customToggle.css";

const CustomToggle = ({ value, onToggle }) => {
  const handleChange = () => {
    onToggle(value === "gainers" ? "losers" : "gainers");
  };

  return (
    <>
      <div className="toggle-container flex items-center gap-2">
        <span
          onClick={handleChange}
          className="toggle-label font-semibold text-success cursor-pointer"
        >
          Gainers
        </span>
        <label className="switch">
          <input
            type="checkbox"
            checked={value === "losers"}
            onChange={handleChange}
          />
          <span className="slider bg-primaryHovered round"></span>
        </label>
        <span
          onClick={handleChange}
          className="toggle-label font-semibold text-error cursor-pointer"
        >
          Losers
        </span>
      </div>
    </>
  );
};

export default CustomToggle;
