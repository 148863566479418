import React from "react";
import NotLoggedIn from "../../components/Icons/NotLoggedIn";
import Toast from "../../components/Toast";

function NotLoginDashboard({ loggedIn }) {
  return (
    <div className={` overflow-hidden h-full w-full flex justify-center items-center flex-col ${!loggedIn && " "}`} >
      <NotLoggedIn />
      <div className=" font-semibold">
        {loggedIn
          ? "Welcome! Click On The Sidebar To Access The Menu"
          : "Kindly Login To Get Full Access To Menu"}
      </div>
      {/* <Toast/> */}
    </div>
  );
}

export default NotLoginDashboard;
