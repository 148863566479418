import React from "react";

const NotLoggedIn = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M266.007 51.064C248.551 61.048 236.239 77.84 221.319 91.32C202.762 108.021 180.175 119.6 155.783 124.92C135.783 129.248 114.887 129.312 95.6151 136.12C58.0151 149.312 32.2631 188.464 31.6151 228.272C30.9671 268.08 53.6151 306.672 86.9031 328.496C98.2401 335.932 110.651 341.585 123.703 345.256C148.183 352.144 174.143 351.84 199.439 349.208C243.135 344.656 288.943 319.84 316.487 284.856C328.765 269.22 339.142 252.18 347.399 234.096C361.04 204.36 368.789 172.261 370.215 139.576C371.375 111.576 370.215 80.536 347.999 60.128C325.783 39.72 292.047 36.16 266.007 51.064Z"
        fill="#285CA1"
      />
      <path
        opacity="0.7"
        d="M266.007 51.064C248.551 61.048 236.239 77.84 221.319 91.32C202.762 108.021 180.175 119.6 155.783 124.92C135.783 129.248 114.887 129.312 95.6151 136.12C58.0151 149.312 32.2631 188.464 31.6151 228.272C30.9671 268.08 53.6151 306.672 86.9031 328.496C98.2401 335.932 110.651 341.585 123.703 345.256C148.183 352.144 174.143 351.84 199.439 349.208C243.135 344.656 288.943 319.84 316.487 284.856C328.765 269.22 339.142 252.18 347.399 234.096C361.04 204.36 368.789 172.261 370.215 139.576C371.375 111.576 370.215 80.536 347.999 60.128C325.783 39.72 292.047 36.16 266.007 51.064Z"
        fill="white"
      />
      <path
        d="M220.079 371.64C299.312 371.64 363.543 354.34 363.543 333C363.543 311.66 299.312 294.36 220.079 294.36C140.846 294.36 76.6152 311.66 76.6152 333C76.6152 354.34 140.846 371.64 220.079 371.64Z"
        fill="#285CA1"
      />
      <path
        opacity="0.5"
        d="M220.079 371.64C299.312 371.64 363.543 354.34 363.543 333C363.543 311.66 299.312 294.36 220.079 294.36C140.846 294.36 76.6152 311.66 76.6152 333C76.6152 354.34 140.846 371.64 220.079 371.64Z"
        fill="white"
      />
      <path
        d="M237.568 54.64H68.9041C64.9498 54.64 61.7441 57.8457 61.7441 61.8V156.352C61.7441 160.306 64.9498 163.512 68.9041 163.512H237.568C241.523 163.512 244.728 160.306 244.728 156.352V61.8C244.728 57.8457 241.523 54.64 237.568 54.64Z"
        fill="white"
      />
      <path
        d="M244.728 61.8V64.632H61.7441V61.8C61.7441 59.8973 62.4972 58.072 63.8388 56.7229C65.1804 55.3738 67.0015 54.6105 68.9041 54.6H237.6C238.541 54.6031 239.473 54.792 240.341 55.1558C241.209 55.5197 241.997 56.0513 242.659 56.7202C243.321 57.3891 243.845 58.1821 244.2 59.0538C244.555 59.9255 244.734 60.8587 244.728 61.8Z"
        fill="#285CA1"
      />
      <path
        d="M237.568 54.64H68.9041C64.9498 54.64 61.7441 57.8457 61.7441 61.8V156.352C61.7441 160.306 64.9498 163.512 68.9041 163.512H237.568C241.523 163.512 244.728 160.306 244.728 156.352V61.8C244.728 57.8457 241.523 54.64 237.568 54.64Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M244.728 61.8V64.632H61.7441V61.8C61.7441 59.8973 62.4972 58.072 63.8388 56.7229C65.1804 55.3738 67.0015 54.6105 68.9041 54.6H237.6C238.541 54.6031 239.473 54.792 240.341 55.1558C241.209 55.5197 241.997 56.0513 242.659 56.7202C243.321 57.3891 243.845 58.1821 244.2 59.0538C244.555 59.9255 244.734 60.8587 244.728 61.8Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M82.2968 59.632C82.2984 59.9947 82.1923 60.3496 81.9919 60.652C81.7916 60.9543 81.506 61.1903 81.1714 61.3302C80.8368 61.4701 80.4682 61.5075 80.1123 61.4378C79.7564 61.368 79.4292 61.1941 79.1722 60.9382C78.9152 60.6823 78.7399 60.3559 78.6686 60.0003C78.5973 59.6448 78.6331 59.276 78.7715 58.9408C78.9099 58.6055 79.1447 58.3189 79.4462 58.1173C79.7476 57.9156 80.1021 57.808 80.4648 57.808C80.9493 57.808 81.4141 57.9999 81.7574 58.3417C82.1007 58.6836 82.2947 59.1475 82.2968 59.632Z"
        fill="#263238"
      />
      <path
        d="M91.8554 59.632C91.857 59.9947 91.7509 60.3496 91.5505 60.652C91.3502 60.9543 91.0646 61.1903 90.73 61.3302C90.3954 61.4701 90.0268 61.5075 89.6709 61.4378C89.315 61.368 88.9878 61.1941 88.7308 60.9382C88.4738 60.6823 88.2985 60.3559 88.2272 60.0003C88.1559 59.6448 88.1917 59.276 88.3301 58.9408C88.4685 58.6055 88.7033 58.3189 89.0048 58.1173C89.3062 57.9156 89.6607 57.808 90.0234 57.808C90.5079 57.808 90.9727 57.9999 91.316 58.3417C91.6593 58.6836 91.8533 59.1475 91.8554 59.632Z"
        fill="#263238"
      />
      <path
        d="M99.584 61.4641C100.596 61.4641 101.416 60.6438 101.416 59.632C101.416 58.6203 100.596 57.8 99.584 57.8C98.5722 57.8 97.752 58.6203 97.752 59.632C97.752 60.6438 98.5722 61.4641 99.584 61.4641Z"
        fill="#263238"
      />
      <path
        d="M127.28 72.5601H75.5438C73.9135 72.5601 72.5918 73.8817 72.5918 75.5121V100.4C72.5918 102.03 73.9135 103.352 75.5438 103.352H127.28C128.91 103.352 130.232 102.03 130.232 100.4V75.5121C130.232 73.8817 128.91 72.5601 127.28 72.5601Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.9844 99.48H125.96"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M85.4953 93.6H79.6953V99.4H85.4953V93.6Z"
        fill="#285CA1"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M93.9836 85.9761H88.1836V99.4801H93.9836V85.9761Z"
        fill="#285CA1"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M103.544 88.296H97.7441V99.616H103.544V88.296Z"
        fill="#285CA1"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M113.593 77.024H107.793V99.224H113.593V77.024Z"
        fill="#285CA1"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M123.632 77.2001H117.832V99.4001H123.632V77.2001Z"
        fill="#285CA1"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M201.696 104.944C210.652 104.944 217.912 97.6838 217.912 88.728C217.912 79.7721 210.652 72.512 201.696 72.512C192.741 72.512 185.48 79.7721 185.48 88.728C185.48 97.6838 192.741 104.944 201.696 104.944Z"
        fill="#285CA1"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M203.025 98.84V100.504H200.305V98.84C199.188 98.5698 198.2 97.9188 197.511 96.999C196.822 96.0792 196.474 94.948 196.529 93.8V93.048L199.609 92.712V93.512C199.609 95.056 200.409 95.992 201.665 95.992C201.938 96.0146 202.212 95.978 202.469 95.8846C202.726 95.7913 202.96 95.6433 203.155 95.4511C203.349 95.2588 203.5 95.0267 203.596 94.7707C203.693 94.5147 203.732 94.2409 203.713 93.968C203.713 92.488 202.329 91.216 200.697 89.864C198.761 88.264 196.833 86.36 196.833 83.672C196.833 80.984 198.097 79.168 200.305 78.624V76.952H203.025V78.608C205.257 79.128 206.553 81.008 206.553 83.688V84.232L203.473 84.56V84C203.473 82.4 202.841 81.52 201.721 81.52C200.601 81.52 199.969 82.216 199.969 83.544C199.969 85.024 201.393 86.264 202.993 87.648C204.897 89.248 206.857 91.152 206.857 93.84C206.904 94.9901 206.546 96.1202 205.846 97.0337C205.146 97.9473 204.148 98.5865 203.025 98.84Z"
        fill="#263238"
      />
      <path
        d="M71.248 119.08H75.9841"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.248 128.232H75.9841"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.248 137.392H75.9841"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.248 146.544H75.9841"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M86.4238 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.9277 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M101.432 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M108.943 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M116.447 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M123.951 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M131.457 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M138.969 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.473 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M153.977 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M161.48 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M168.992 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M176.496 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.504 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.729 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M205.953 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M213.168 153.456V149.776"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M232.271 113.48V149.048C232.271 151.488 229.791 153.456 226.735 153.456H76.7992C75.8863 153.488 74.9782 153.31 74.1456 152.934C73.3131 152.557 72.5785 151.994 71.9992 151.288C71.4987 150.647 71.2182 149.861 71.1992 149.048V113.48C71.1992 111.08 73.6792 109.072 76.7352 109.072H226.735C229.791 109.072 232.271 111.04 232.271 113.48Z"
        stroke="#285CA1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.64 44.592L138.776 109.072L120.472 133.728L100.472 120.992L75.9997 153.456H75.6477C74.8804 153.457 74.1262 153.257 73.4597 152.877C72.7932 152.496 72.2376 151.949 71.8477 151.288L99.3437 114.8L119.344 127.512L133.032 109.112L182.944 41.912L186.64 44.592Z"
        fill="#263238"
      />
      <path
        d="M174.496 45.408L192.088 34.744L187.224 53.192L184.12 44.656L174.496 45.408Z"
        fill="#263238"
      />
      <path
        d="M340.984 248.416H59.6317L28.0957 276.456"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M59.8086 228.784V174.264"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M59.8086 245.248V234.704"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M324.047 115.776C324.047 115.776 324.047 112.48 319.431 107.872C314.815 103.264 311.855 100.624 311.855 100.624C311.855 100.624 309.543 108.2 313.831 112.816C316.98 116.068 320.398 119.049 324.047 121.728V115.776Z"
        fill="#285CA1"
      />
      <path
        d="M317.352 136.36C317.352 136.36 317.352 133.064 312.736 128.448C308.12 123.832 305.16 121.2 305.16 121.2C305.16 121.2 302.856 128.776 307.136 133.392C310.29 136.642 313.707 139.626 317.352 142.312V136.36Z"
        fill="#285CA1"
      />
      <path
        d="M323.751 121.392C323.751 121.392 324.103 118.112 329.175 114.008C334.247 109.904 337.479 107.608 337.479 107.608C337.479 107.608 338.967 115.408 334.223 119.52C330.747 122.414 327.035 125.012 323.127 127.288L323.751 121.392Z"
        fill="#285CA1"
      />
      <path
        d="M315.663 145.696C315.663 145.696 316.359 142.496 321.831 138.928C327.303 135.36 330.751 133.424 330.751 133.424C330.751 133.424 331.415 141.32 326.287 144.936C322.529 147.448 318.565 149.64 314.439 151.488L315.663 145.696Z"
        fill="#285CA1"
      />
      <path
        d="M317.191 172.184C317.191 172.184 317.191 168.888 321.799 164.28C326.407 159.672 329.375 157.032 329.375 157.032C329.375 157.032 331.687 164.616 327.399 169.224C324.255 172.48 320.84 175.464 317.191 178.144V172.184Z"
        fill="#285CA1"
      />
      <path
        d="M317.111 178.656C317.111 178.656 317.575 175.392 313.663 170.176C309.751 164.96 307.199 161.92 307.199 161.92C307.199 161.92 303.855 169.12 307.431 174.272C310.089 177.931 313.049 181.362 316.279 184.528L317.111 178.656Z"
        fill="#285CA1"
      />
      <path
        d="M312.559 197.816C312.559 197.816 312.559 194.528 317.175 189.912C321.791 185.296 324.751 182.712 324.751 182.712C324.751 182.712 327.063 190.288 322.775 194.896C319.625 198.15 316.208 201.134 312.559 203.816V197.816Z"
        fill="#285CA1"
      />
      <path
        d="M315.639 215.2C315.639 215.2 314.839 212 318.111 206.4C321.383 200.8 323.607 197.48 323.607 197.48C323.607 197.48 327.751 204.232 324.783 209.776C322.557 213.716 320.004 217.462 317.151 220.976L315.639 215.2Z"
        fill="#285CA1"
      />
      <path
        d="M324.464 114.96C324.231 118.389 323.809 121.802 323.2 125.184C321.376 134.608 316.8 140.688 314.992 149.184C313.184 157.68 318.408 168.664 317.008 179.584C315.608 190.504 311.344 196.592 312.544 203.888C313.744 211.184 320.144 230.928 320.144 230.928"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M323.711 123.584L328.679 119.76"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M323.935 119.192L321.863 115.488"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M315.271 148.8C315.271 148.8 319.431 145.672 323.711 142.552"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M317.351 142.272C317.351 142.272 313.879 135.472 309.951 130.96"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M317.352 176.872C319.735 172.494 322.74 168.485 326.272 164.968"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M316.543 181.496C315.267 178.52 313.747 175.654 311.999 172.928C309.599 169.344 309.215 168.88 309.215 168.88"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M312.559 203.88C313.615 201.26 314.869 198.725 316.311 196.296C317.75 194.034 319.371 191.894 321.159 189.896"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M317.143 220.936C316.902 218.436 317.333 215.917 318.391 213.64C320.127 210.288 322.087 205.888 322.903 204.16"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M293.84 159.792C293.84 159.792 293.48 157.736 295.872 154.336C298.264 150.936 299.872 148.96 299.872 148.96C299.872 148.96 302.136 153.456 299.952 156.816C298.329 159.194 296.509 161.431 294.512 163.504L293.84 159.792Z"
        fill="#285CA1"
      />
      <path
        d="M300.271 172C300.271 172 299.911 169.928 302.303 166.536C304.695 163.144 306.303 161.168 306.303 161.168C306.303 161.168 308.567 165.672 306.383 169.024C304.763 171.403 302.943 173.641 300.943 175.712L300.271 172Z"
        fill="#285CA1"
      />
      <path
        d="M294.632 163.28C294.632 163.28 294.072 161.264 290.432 159.28C286.792 157.296 284.528 156.16 284.528 156.16C284.528 156.16 284.44 161.176 287.872 163.28C290.364 164.718 292.973 165.944 295.672 166.944L294.632 163.28Z"
        fill="#285CA1"
      />
      <path
        d="M302.343 177.64C302.343 177.64 301.543 175.696 297.735 174.072C293.927 172.448 291.535 171.592 291.535 171.592C291.535 171.592 291.975 176.608 295.599 178.32C298.23 179.487 300.954 180.431 303.743 181.144L302.343 177.64Z"
        fill="#285CA1"
      />
      <path
        d="M304.263 194.4C304.263 194.4 303.903 192.336 300.511 189.944C297.119 187.552 294.967 186.216 294.967 186.216C294.967 186.216 294.343 191.232 297.535 193.648C299.862 195.338 302.326 196.829 304.903 198.104L304.263 194.4Z"
        fill="#285CA1"
      />
      <path
        d="M305.008 198.472C305.008 198.472 304.36 196.48 306.248 192.784C308.136 189.088 309.448 186.904 309.448 186.904C309.448 186.904 312.328 191.032 310.648 194.672C309.367 197.255 307.874 199.728 306.184 202.064L305.008 198.472Z"
        fill="#285CA1"
      />
      <path
        d="M310.799 215.136C310.799 215.136 311.039 213.056 314.287 210.472C317.535 207.888 319.607 206.472 319.607 206.472C319.607 206.472 320.519 211.432 317.471 214.04C315.245 215.86 312.871 217.493 310.375 218.92L310.799 215.136Z"
        fill="#285CA1"
      />
      <path
        d="M309.943 210C309.943 210 309.583 207.928 306.191 205.536C302.799 203.144 300.655 201.816 300.655 201.816C300.655 201.816 300.031 206.832 303.215 209.248C305.545 210.937 308.012 212.427 310.591 213.704L309.943 210Z"
        fill="#285CA1"
      />
      <path
        d="M309.896 221.232C309.896 221.232 310.08 219.136 307.4 215.968C304.72 212.8 302.984 210.952 302.984 210.952C302.984 210.952 301.112 215.632 303.584 218.792C305.41 221.02 307.416 223.094 309.584 224.992L309.896 221.232Z"
        fill="#285CA1"
      />
      <path
        d="M293.48 159.312C294.001 161.439 294.639 163.536 295.392 165.592C297.552 171.296 301.072 174.616 303.136 179.752C305.2 184.888 303.136 192.336 305.176 199.032C307.216 205.728 310.576 209.064 310.6 213.792C310.624 218.52 308.8 231.584 308.8 231.584"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M294.895 164.648L291.367 162.8"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M294.271 161.92L295.175 159.376"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M302.927 179.536C302.927 179.536 299.975 178.032 296.959 176.536"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M300.912 175.712C301.78 172.973 302.924 170.33 304.328 167.824"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M304.671 197.376C302.702 194.891 300.385 192.703 297.791 190.88"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M305.68 200.192C306.147 198.193 306.781 196.237 307.576 194.344C308.688 191.824 308.88 191.496 308.88 191.496"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M310.447 217.36C311.592 215.938 312.861 214.621 314.239 213.424C315.117 212.774 315.941 212.054 316.703 211.272"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M310.6 213.792C309.644 212.266 308.574 210.814 307.4 209.448C306.251 208.193 305.002 207.035 303.664 205.984"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M309.599 224.992C309.471 223.391 308.919 221.852 307.999 220.536C306.543 218.616 304.799 216.072 304.143 215.072"
        stroke="#263238"
        stroke-width="1.17749"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M324.944 254.112H303.936C303.519 254.112 303.118 253.949 302.819 253.658C302.52 253.366 302.347 252.969 302.336 252.552L299.32 229.304H329.496L326.48 252.552C326.47 252.959 326.306 253.346 326.021 253.636C325.736 253.926 325.351 254.096 324.944 254.112Z"
        fill="#263238"
      />
      <path
        d="M329.256 233.232H299.352C298.73 233.234 298.132 232.989 297.69 232.552C297.247 232.114 296.996 231.518 296.992 230.896C297.002 230.275 297.253 229.682 297.692 229.241C298.131 228.801 298.723 228.548 299.344 228.536H329.256C329.566 228.535 329.872 228.595 330.159 228.713C330.445 228.83 330.705 229.004 330.924 229.222C331.144 229.441 331.318 229.7 331.436 229.986C331.555 230.272 331.616 230.578 331.616 230.888C331.615 231.197 331.553 231.503 331.434 231.787C331.315 232.072 331.141 232.331 330.922 232.549C330.703 232.766 330.443 232.939 330.157 233.056C329.871 233.173 329.565 233.233 329.256 233.232Z"
        fill="#263238"
      />
      <path
        d="M261.84 140.248C261.838 146.814 259.613 153.185 255.526 158.324C251.44 163.462 245.733 167.065 239.336 168.544H239.248C238.488 168.712 237.728 168.864 236.944 168.976C235.568 169.172 234.181 169.271 232.792 169.272C225.199 169.245 217.918 166.245 212.509 160.916C207.1 155.587 203.993 148.352 203.853 140.76C203.712 133.168 206.551 125.823 211.759 120.298C216.968 114.773 224.133 111.507 231.72 111.2C231.984 111.2 232.264 111.2 232.52 111.2C232.605 111.192 232.691 111.192 232.776 111.2C232.874 111.192 232.973 111.192 233.072 111.2C236.991 111.225 240.863 112.056 244.448 113.64C249.619 115.905 254.018 119.628 257.106 124.354C260.195 129.079 261.84 134.602 261.84 140.248Z"
        fill="#285CA1"
      />
      <path
        opacity="0.4"
        d="M244.465 113.648C210.921 111.904 210.761 149.144 210.761 149.144C210.761 149.144 205.641 136.992 214.001 123.544C218.673 116.016 226.801 112.696 233.089 111.224C237.007 111.244 240.879 112.069 244.465 113.648Z"
        fill="white"
      />
      <path
        d="M261.84 140.248C261.84 146.83 259.604 153.216 255.5 158.361C251.395 163.506 245.665 167.105 239.248 168.568C237.131 169.055 234.965 169.3 232.792 169.296C225.088 169.438 217.643 166.514 212.095 161.167C206.547 155.82 203.35 148.488 203.208 140.784C203.066 133.08 205.99 125.635 211.337 120.087C216.684 114.539 224.016 111.342 231.72 111.2C232.072 111.2 232.432 111.2 232.792 111.2C240.495 111.202 247.883 114.263 253.33 119.71C258.777 125.157 261.838 132.545 261.84 140.248Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M261.841 140.248C261.84 146.83 259.605 153.216 255.5 158.361C251.396 163.506 245.666 167.105 239.249 168.568C238.897 168.568 238.537 168.568 238.177 168.568C231.046 168.549 224.172 165.909 218.862 161.151C213.552 156.392 210.177 149.847 209.38 142.761C208.582 135.676 210.418 128.544 214.538 122.725C218.657 116.905 224.773 112.803 231.721 111.2C232.073 111.2 232.433 111.2 232.793 111.2C240.496 111.202 247.883 114.263 253.33 119.71C258.777 125.157 261.839 132.545 261.841 140.248Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M242.799 158.4L243.551 161.472L238.527 162.696L237.775 159.632C233.279 159.632 229.671 156.712 228.519 152.024L228.175 150.632L233.719 148.624L234.071 150.072C234.767 152.92 236.647 154.288 238.991 153.712C239.506 153.632 239.997 153.441 240.43 153.153C240.864 152.865 241.23 152.486 241.503 152.042C241.776 151.599 241.95 151.102 242.012 150.585C242.074 150.068 242.023 149.543 241.863 149.048C241.191 146.312 238.055 144.6 234.423 142.832C230.127 140.752 225.695 138.112 224.479 133.144C223.295 128.296 224.783 124.256 228.615 122.256L227.863 119.184L232.879 117.96L233.631 121.024C237.991 120.96 241.239 123.832 242.431 128.792L242.679 129.8L237.143 131.808L236.863 130.696C236.127 127.68 234.575 126.408 232.511 126.912C230.447 127.416 229.591 128.992 230.191 131.44C230.863 134.176 234.039 135.824 237.631 137.664C241.879 139.816 246.359 142.384 247.575 147.344C248.799 152.12 246.847 156.376 242.799 158.4Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.3"
        d="M242.799 158.4L243.551 161.472L238.527 162.696L237.775 159.632C233.279 159.632 229.671 156.712 228.519 152.024L228.175 150.632L233.719 148.624L234.071 150.072C234.767 152.92 236.647 154.288 238.991 153.712C239.506 153.632 239.997 153.441 240.43 153.153C240.864 152.865 241.23 152.486 241.503 152.042C241.776 151.599 241.95 151.102 242.012 150.585C242.074 150.068 242.023 149.543 241.863 149.048C241.191 146.312 238.055 144.6 234.423 142.832C230.127 140.752 225.695 138.112 224.479 133.144C223.295 128.296 224.783 124.256 228.615 122.256L227.863 119.184L232.879 117.96L233.631 121.024C237.991 120.96 241.239 123.832 242.431 128.792L242.679 129.8L237.143 131.808L236.863 130.696C236.127 127.68 234.575 126.408 232.511 126.912C230.447 127.416 229.591 128.992 230.191 131.44C230.863 134.176 234.039 135.824 237.631 137.664C241.879 139.816 246.359 142.384 247.575 147.344C248.799 152.12 246.847 156.376 242.799 158.4Z"
        fill="black"
      />
      <path
        d="M217.943 165.224L224.079 164.944"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M214.992 163.184L220.344 162.728"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M212.592 161.032L217.944 160.576"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M211.855 120.104H216.439"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M214.119 117.992L218.687 117.784"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M216.984 115.872L221.8 115.416"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.3"
        d="M239.336 168.544H239.248C237.13 169.031 234.964 169.276 232.792 169.272C225.199 169.245 217.918 166.245 212.509 160.916C207.1 155.587 203.993 148.352 203.853 140.76C203.712 133.168 206.551 125.823 211.759 120.298C216.968 114.773 224.133 111.507 231.72 111.2C231.984 111.2 232.264 111.2 232.52 111.2C225.44 113.432 213.56 119.136 210.384 131.032C205.896 147.936 216 167.128 239.336 168.544Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M261.839 140.248C261.838 146.813 259.613 153.185 255.526 158.324C251.439 163.462 245.732 167.065 239.335 168.544H239.247C238.487 168.712 237.727 168.864 236.943 168.976L244.463 113.624C249.634 115.893 254.031 119.619 257.117 124.348C260.203 129.076 261.844 134.602 261.839 140.248Z"
        fill="black"
      />
      <path
        d="M278.321 94.52C276.021 97.7689 272.687 100.142 268.864 101.252C265.042 102.362 260.955 102.144 257.273 100.632L257.169 100.584C256.623 100.344 256.089 100.077 255.569 99.784C254.982 99.4683 254.415 99.1156 253.873 98.728C250.329 96.0215 247.945 92.0709 247.201 87.6742C246.458 83.2775 247.411 78.7626 249.869 75.0416C252.326 71.3207 256.104 68.6712 260.439 67.6284C264.775 66.5857 269.344 67.2274 273.225 69.4239L273.617 69.6719C273.801 69.7759 273.977 69.904 274.161 70.032C274.345 70.16 274.697 70.424 274.961 70.632C278.458 73.4369 280.753 77.4705 281.377 81.9098C282.002 86.349 280.909 90.8592 278.321 94.52Z"
        fill="#285CA1"
      />
      <path
        opacity="0.4"
        d="M251.808 94.76C251.808 94.76 248.936 86.304 252.752 80.08C257.552 72.28 264.904 68.72 274.912 70.64C274.912 70.64 269.488 66.976 260.408 69.576C251.328 72.176 246.456 84.672 251.808 94.76Z"
        fill="white"
      />
      <path
        d="M278.321 94.528C276.019 97.7741 272.684 100.144 268.861 101.251C265.039 102.358 260.954 102.137 257.273 100.624C256.058 100.128 254.904 99.4942 253.833 98.736C250.267 96.0005 247.883 92.0044 247.169 87.567C246.455 83.1297 247.466 78.5874 249.994 74.8715C252.522 71.1556 256.376 68.5476 260.765 67.5823C265.155 66.617 269.747 67.3675 273.601 69.68C273.777 69.784 273.961 69.904 274.137 70.032C277.934 72.7302 280.507 76.8237 281.291 81.4154C282.075 86.0071 281.007 90.7224 278.321 94.528Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M278.32 94.528C276.017 97.7741 272.683 100.144 268.86 101.251C265.037 102.358 260.952 102.137 257.272 100.624L256.736 100.272C253.26 97.7527 250.825 94.05 249.889 89.8608C248.952 85.6715 249.579 81.2845 251.652 77.5253C253.724 73.766 257.099 70.8937 261.141 69.4488C265.183 68.004 269.614 68.0862 273.6 69.68C273.776 69.784 273.96 69.904 274.136 70.032C277.933 72.7302 280.505 76.8237 281.29 81.4154C282.074 86.007 281.006 90.7224 278.32 94.528Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M262.569 96.864L261.873 98.64L258.961 97.496L259.657 95.712C258.577 94.9512 257.794 93.8386 257.444 92.5644C257.094 91.2903 257.197 89.9341 257.737 88.728L258.057 87.928L261.497 88.864L261.169 89.712C260.521 91.36 260.961 92.688 262.321 93.224C262.603 93.3644 262.913 93.4419 263.228 93.451C263.543 93.46 263.857 93.4005 264.146 93.2765C264.436 93.1525 264.696 92.9669 264.907 92.7327C265.118 92.4985 265.276 92.2212 265.369 91.92C265.993 90.32 265.041 88.392 263.865 86.256C262.473 83.728 261.209 80.88 262.337 78C263.465 75.12 265.593 73.712 268.177 74.064L268.881 72.288L271.785 73.432L271.089 75.2C273.265 76.688 273.865 79.2 272.737 82.12L272.505 82.696L269.065 81.76L269.321 81.112C270.009 79.368 269.689 78.192 268.521 77.72C267.353 77.248 266.353 77.72 265.793 79.152C265.169 80.752 266.169 82.656 267.297 84.816C268.649 87.368 269.961 90.2 268.833 93.072C268.405 94.3423 267.542 95.42 266.395 96.1142C265.248 96.8083 263.893 97.0739 262.569 96.864Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.3"
        d="M262.569 96.864L261.873 98.64L258.961 97.496L259.657 95.712C258.577 94.9512 257.794 93.8386 257.444 92.5644C257.094 91.2903 257.197 89.9341 257.737 88.728L258.057 87.928L261.497 88.864L261.169 89.712C260.521 91.36 260.961 92.688 262.321 93.224C262.603 93.3644 262.913 93.4419 263.228 93.451C263.543 93.46 263.857 93.4005 264.146 93.2765C264.436 93.1525 264.696 92.9669 264.907 92.7327C265.118 92.4985 265.276 92.2212 265.369 91.92C265.993 90.32 265.041 88.392 263.865 86.256C262.473 83.728 261.209 80.88 262.337 78C263.465 75.12 265.593 73.712 268.177 74.064L268.881 72.288L271.785 73.432L271.089 75.2C273.265 76.688 273.865 79.2 272.737 82.12L272.505 82.696L269.065 81.76L269.321 81.112C270.009 79.368 269.689 78.192 268.521 77.72C267.353 77.248 266.353 77.72 265.793 79.152C265.169 80.752 266.169 82.656 267.297 84.816C268.649 87.368 269.961 90.2 268.833 93.072C268.405 94.3423 267.542 95.42 266.395 96.1142C265.248 96.8083 263.893 97.0739 262.569 96.864Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M273.184 69.4319C268.384 68.5759 262.304 68.1039 259.024 70.2319C253 74.0959 249.248 78.9679 249.304 86.8639C249.36 94.7599 256.736 100.296 256.736 100.296L257.168 100.608C255.991 100.117 254.873 99.4968 253.832 98.7599C250.288 96.0535 247.904 92.1029 247.16 87.7062C246.417 83.3095 247.37 78.7945 249.828 75.0736C252.285 71.3527 256.063 68.7032 260.398 67.6604C264.734 66.6176 269.303 67.2594 273.184 69.4559V69.4319Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M278.32 94.52C276.02 97.7689 272.687 100.142 268.864 101.252C265.041 102.362 260.955 102.144 257.272 100.632L257.168 100.584C256.622 100.344 256.088 100.077 255.568 99.784L274.912 70.64C278.415 73.4379 280.717 77.4667 281.351 81.9044C281.984 86.342 280.9 90.8541 278.32 94.52Z"
        fill="black"
      />
      <path
        d="M269.265 319.808C261.818 328.765 252.941 336.429 242.993 342.488C239.893 344.343 236.688 346.015 233.393 347.496C210.665 357.712 184.761 358.376 160.537 352.504C109.601 340.16 82.5769 295.2 79.1449 245.288C78.2249 231.8 76.1609 217.232 81.6249 204.4C87.0889 191.568 98.1369 183.6 110.633 178.064C119.705 174.024 129.505 171.312 138.409 169.096C154.523 165.115 171.519 166.869 186.481 174.056C196.953 179.024 204.409 187.656 213.217 194.928C214.15 195.696 215.089 196.461 216.033 197.224C224.93 204.386 234.283 210.962 244.033 216.912C258.841 225.944 274.001 234.464 283.465 249.632C298.561 273.808 285.825 300.128 269.265 319.808Z"
        fill="#285CA1"
      />
      <path
        opacity="0.4"
        d="M269.265 319.808C261.818 328.765 252.941 336.429 242.993 342.488C239.893 344.343 236.688 346.015 233.393 347.496C210.665 357.712 184.761 358.376 160.537 352.504C109.601 340.16 82.5769 295.2 79.1449 245.288C78.2249 231.8 76.1609 217.232 81.6249 204.4C87.0889 191.568 98.1369 183.6 110.633 178.064C119.705 174.024 129.505 171.312 138.409 169.096C154.523 165.115 171.519 166.869 186.481 174.056C196.953 179.024 204.409 187.656 213.217 194.928C214.15 195.696 215.089 196.461 216.033 197.224C224.93 204.386 234.283 210.962 244.033 216.912C258.841 225.944 274.001 234.464 283.465 249.632C298.561 273.808 285.825 300.128 269.265 319.808Z"
        fill="white"
      />
      <path
        d="M216.001 197.224C215.057 196.467 214.118 195.699 213.185 194.92C204.385 187.632 196.913 179.024 186.449 174.056C171.486 166.873 154.491 165.119 138.377 169.096C117.201 174.352 90.9369 182.448 81.5769 204.408C76.1129 217.208 78.1769 231.8 79.1049 245.288C82.5769 295.2 109.601 340.16 160.537 352.504C188.249 359.224 218.137 357.384 242.993 342.488C252.941 336.429 261.818 328.765 269.265 319.808C285.825 300.128 298.561 273.808 283.481 249.632C274.025 234.432 258.865 225.944 244.049 216.912C234.283 210.964 224.914 204.387 216.001 197.224Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M138.841 286.224C134.6 287.508 130.485 289.173 126.545 291.2"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M164.8 281.28C158.606 281.816 152.457 282.792 146.4 284.2"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M159.4 280C159.4 280 135.496 266.096 106.648 277.24"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.1"
        d="M269.265 319.808C261.818 328.765 252.941 336.429 242.993 342.488C239.893 344.343 236.687 346.015 233.393 347.496C230.792 346.7 228.236 345.762 225.737 344.688C210.809 338.288 198.281 327.464 186.041 316.792C178.657 310.392 171.153 303.832 162.313 299.616C150.358 293.964 136.759 292.844 124.041 296.464C130.618 289.04 139.561 284.119 149.353 282.536C143.033 276.784 134.241 274.368 125.697 274.216C117.153 274.064 112.449 275.112 104.097 276.984C106.889 271.848 114.297 267.2 120.145 267.336C114.585 267.262 109.035 266.87 103.521 266.16C95.9767 264.944 92.6007 256.064 91.4727 248.512C88.3527 227.712 91.5127 205.56 102.865 187.88C105.112 184.354 107.712 181.065 110.625 178.064C119.697 174.024 129.497 171.312 138.401 169.096C154.514 165.115 171.511 166.869 186.473 174.056C196.945 179.024 204.401 187.656 213.209 194.928C214.142 195.696 215.081 196.461 216.025 197.224C224.922 204.386 234.275 210.962 244.025 216.912C258.833 225.944 273.993 234.464 283.457 249.632C298.561 273.808 285.825 300.128 269.265 319.808Z"
        fill="black"
      />
      <path
        d="M277.602 319.2C282.087 322.929 286.78 326.401 291.658 329.6C301.17 330.24 296.906 341.736 296.906 341.736L288.762 346.976C288.762 346.976 274.818 335.896 267.202 332.304C259.586 328.712 277.602 319.2 277.602 319.2Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M210.009 198.16C210.009 198.16 208.705 185.256 206.625 181.632L175.041 175.2V172.752C175.041 172.752 175.769 168.496 168.729 165.832C161.689 163.168 147.017 166.632 143.233 169.496C139.449 172.36 140.833 177.288 140.833 177.288C140.833 177.288 128.113 183.712 122.401 186.16C117.725 188.317 113.166 190.721 108.745 193.36C108.745 193.36 106.153 223.024 106.665 229.912C107.177 236.8 106.249 243.28 106.841 247.096C107.433 250.912 107.897 253.08 111.897 256.328C115.897 259.576 119.537 264.328 130.025 259.616L128.561 258.456L197.601 235.2L210.009 198.16Z"
        fill="black"
      />
      <path
        d="M210.009 198.16C210.009 198.16 208.705 185.256 206.625 181.632L175.041 175.2V172.752C175.041 172.752 175.769 168.496 168.729 165.832C161.689 163.168 147.017 166.632 143.233 169.496C139.449 172.36 140.833 177.288 140.833 177.288C140.833 177.288 128.113 183.712 122.401 186.16C117.725 188.317 113.166 190.721 108.745 193.36C108.745 193.36 106.153 223.024 106.665 229.912C107.177 236.8 106.249 243.28 106.841 247.096C107.433 250.912 107.897 253.08 111.897 256.328C115.897 259.576 119.537 264.328 130.025 259.616L128.561 258.456L197.601 235.2L210.009 198.16Z"
        fill="#285CA1"
      />
      <path
        opacity="0.05"
        d="M210.009 198.16C210.009 198.16 208.705 185.256 206.625 181.632L175.041 175.2V172.752C175.041 172.752 175.769 168.496 168.729 165.832C161.689 163.168 147.017 166.632 143.233 169.496C139.449 172.36 140.833 177.288 140.833 177.288C140.833 177.288 128.113 183.712 122.401 186.16C117.725 188.317 113.166 190.721 108.745 193.36C108.745 193.36 106.153 223.024 106.665 229.912C107.177 236.8 106.249 243.28 106.841 247.096C107.433 250.912 107.897 253.08 111.897 256.328C115.897 259.576 119.537 264.328 130.025 259.616L128.561 258.456L197.601 235.2L210.009 198.16Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M207.04 183.2H193.824C192.582 183.202 191.38 183.644 190.432 184.448C189.485 185.252 188.853 186.366 188.648 187.592L182.136 226.792C182.136 226.792 173.168 227.536 166.816 232.168C160.464 236.8 144.84 203.752 120.152 203.736C120.152 203.736 127.256 219.976 128.832 232.936C128.832 232.936 113.4 231.256 110.84 237.296C108.28 243.336 112 254.656 118.528 250.272C125.056 245.888 131.2 238.576 135.56 243.2C139.92 247.824 159.4 250.312 159.4 250.312L189.376 235.2L201 229.736L207.04 183.2Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M165.08 163.768C165.08 163.768 172.584 165.848 173.8 168.368C175.016 170.888 174.888 177.264 174.888 177.264C173.067 181.526 170.308 185.321 166.816 188.368L161.216 177.264L161.72 189.856C161.72 189.856 151.096 188.088 146.584 184.696L142.08 181.296C142.08 181.296 140.136 174.896 140.416 172.728C140.696 170.56 148.8 166.16 148.8 166.16L165.08 163.768Z"
        fill="white"
      />
      <path
        d="M290.072 357.936C289.864 357.48 293.152 351.672 290.576 345.76C288 339.848 283.896 342.128 283.896 342.128C283.896 342.128 272.208 334.688 267.296 332.336C263.757 330.558 260.349 328.532 257.096 326.272L251.904 333.264L256.704 348.112L290.072 357.936Z"
        fill="#285CA1"
      />
      <path
        d="M283.473 341.768L298.481 350.936C298.481 350.936 309.033 344.168 314.065 339.496C319.097 334.824 330.401 324.872 330.401 324.872L337.601 315.184C337.601 315.184 332.609 309.008 324.289 313.968C313.537 320.368 300.425 329.664 291.681 329.576C291.681 329.576 293.401 340.32 283.529 341.768"
        fill="#285CA1"
      />
      <path
        d="M162.537 124.984C162.537 124.984 169.345 135.288 170.585 138.624C171.825 141.96 172.825 146.896 172.577 149.272C172.329 151.648 169.833 164.376 168.577 164.744L167.369 166.112L168.169 171.504C168.169 171.504 162.841 180.712 147.177 173.96C147.177 173.96 146.569 163.208 144.665 159.832C142.761 156.456 139.913 160.472 137.057 150.72C134.201 140.968 143.297 130.72 143.297 130.72L162.537 124.984Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M331.065 312.176C331.065 312.176 323.345 313.52 320.841 318.088C318.337 322.656 313.529 331.144 311.889 335.248C310.249 339.352 296.689 349.264 296.689 349.264L298.489 350.936C298.489 350.936 309.529 344.112 316.193 337.624C322.857 331.136 337.553 314.816 337.553 314.816C337.553 314.816 336.497 313.768 331.065 312.176Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M253.816 330.696L251.904 333.264L257.776 345.44L290.072 357.048L291.568 352.32C291.568 352.32 292.064 347.52 280.728 343.872C269.392 340.224 262.48 337.816 259.256 335.312C256.032 332.808 253.816 330.696 253.816 330.696Z"
        fill="white"
      />
      <path
        d="M147.201 240.264C147.201 240.264 137.313 238.776 132.697 246.864C128.081 254.952 128.553 258.456 128.553 258.456C128.553 258.456 135.089 264.392 144.073 264.056C153.057 263.72 166.137 253.432 170.401 250.08C174.665 246.728 185.417 237.136 192.753 239.12L193.553 232.184C193.553 232.184 192.833 229.072 186.561 229.912C180.289 230.752 177.825 234.328 175.801 234.328C173.777 234.328 171.865 232.384 167.801 239.328C167.793 239.328 153.529 241.856 147.201 240.264Z"
        fill="white"
      />
      <path
        d="M160.049 139.8C160.259 141.096 160.559 142.377 160.945 143.632C161.089 144.245 161.362 144.82 161.745 145.32C162.497 146.12 163.713 146.328 164.825 146.496C165.008 146.509 165.183 146.582 165.321 146.704C165.411 146.847 165.453 147.015 165.441 147.184C165.434 148.444 165.056 149.674 164.353 150.72"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.1"
        d="M260.448 322.4L253.816 330.664C253.816 330.664 261.096 337.392 268.632 339.888C276.168 342.384 289.36 347.088 291.568 350.712L290.72 346.4C290.72 346.4 270.088 333.744 265.352 330.72C263.952 329.802 262.782 328.574 261.931 327.131C261.081 325.689 260.574 324.07 260.448 322.4Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M283.473 341.768L296.681 349.264C296.681 349.264 303.201 344.8 306.401 341.512C309.601 338.224 313.545 334.576 316.001 327.208C319.353 317.024 328.953 312.176 328.953 312.176C328.953 312.176 315.601 318.464 308.649 323.376C301.697 328.288 294.921 330.368 290.985 330.032C290.945 330.056 294.105 340.056 283.473 341.768Z"
        fill="black"
      />
      <path
        d="M155.393 152.536C155.393 152.536 160.585 156.608 165.977 155.08C165.977 155.08 160.889 165.16 155.393 152.536Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.202 143.736C155.354 144.856 155.01 145.824 154.45 145.904C153.89 145.984 153.306 145.104 153.154 144.008C153.002 142.912 153.338 141.92 153.906 141.84C154.474 141.76 155.082 142.616 155.202 143.736Z"
        fill="#263238"
      />
      <path
        d="M166.506 141.696C166.65 142.824 166.314 143.792 165.754 143.864C165.194 143.936 164.602 143.064 164.458 141.976C164.314 140.888 164.642 139.88 165.21 139.808C165.778 139.736 166.354 140.576 166.506 141.696Z"
        fill="#263238"
      />
      <path
        d="M154.401 138.984C154.401 138.984 151.377 137.424 149.225 140.768"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M162.152 136.904C162.152 136.904 165.352 134.16 167.2 136.904"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.129 149.28C144.129 149.28 142.185 146.408 140.129 146.496C138.073 146.584 137.257 149.464 137.993 152.248C138.729 155.032 141.793 158.92 144.113 158.552C145.421 158.383 146.615 157.719 147.449 156.696"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M162.769 127.304C164.953 129.12 167.265 131.096 168.369 133.768C169.345 136.04 169.561 138.568 170.457 140.864C171.409 143.32 172.449 145.888 172.529 148.568C172.487 151.048 172.162 153.514 171.561 155.92C171.229 158.006 170.734 160.063 170.081 162.072C169.001 164.944 167.009 166.712 164.337 168.072C162.393 169.064 159.153 170.472 157.049 169.144C155.825 168.344 153.985 166.056 152.457 163.952"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.129 158.552C144.129 158.552 147.569 166.984 146.737 172.912"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M143.248 147.864C143.706 147.525 144.069 147.074 144.301 146.554C144.534 146.034 144.629 145.463 144.576 144.896C144.448 143.6 143.552 142.4 143.728 141.104C143.875 140.483 144.107 139.886 144.416 139.328C144.882 138.252 145.087 137.081 145.015 135.91C144.943 134.74 144.595 133.603 144 132.592C144 132.592 154.928 133.104 160.048 129.848C165.168 126.592 169.072 118.264 165.976 114.288C162.88 110.312 156.848 115.736 152.376 116.336C147.904 116.936 132 119.472 129.464 125.6C126.928 131.728 130.792 133.928 130.912 139.776C131.032 145.624 131.032 156.704 139.776 155.688C139.776 155.688 136.504 151.456 138.776 147.096C140.32 145.264 143.248 147.864 143.248 147.864Z"
        fill="#263238"
      />
      <path
        d="M164.68 121.2C166.164 122.356 167.394 123.805 168.294 125.456C169.194 127.108 169.745 128.927 169.912 130.8C170.4 135.184 178.176 143.704 172.568 148.56C172.568 148.56 171.264 143.4 170.496 140.856C169.728 138.312 168.744 133.656 167.456 132.056C166.168 130.456 159.336 124.856 159.336 124.856L164.68 121.2Z"
        fill="#263238"
      />
      <path
        d="M145.138 172.728C145.138 172.728 150.53 176.28 160.546 176.424L161.722 189.856C161.722 189.856 150.458 189 142.082 181.296C142.082 181.296 139.818 175.304 140.418 172.728C141.018 170.152 146.402 167.6 146.402 167.6"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.793 166.72L168.113 171.52C166.001 173.719 163.412 175.403 160.545 176.44L166.817 188.384C166.817 188.384 174.297 182.56 175.041 176.44C175.041 176.44 175.105 170.176 173.801 168.384C172.497 166.592 167.745 165.752 167.745 165.752"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.8"
        d="M137.441 129.6C138.241 127.592 140.433 126.4 142.569 126.08C144.705 125.76 146.897 125.984 149.057 125.928C152.048 125.904 154.977 125.075 157.537 123.528C158.809 122.753 159.916 121.736 160.795 120.533C161.673 119.331 162.306 117.967 162.657 116.52"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.8"
        d="M132.281 129.208C133.633 125.104 138.017 122.568 142.329 122.208C144.465 122.024 146.625 122.272 148.729 122.024C150.833 121.776 153.073 120.896 154.185 119.064"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M130.009 259.616C119.609 265.008 115.913 259.456 110.121 254.864C104.329 250.272 108.129 242.064 106.921 228.904C105.713 215.744 108.721 193.36 108.721 193.36C108.721 193.36 111.313 192.768 118.505 188.368C125.697 183.968 140.833 177.264 140.833 177.264"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M140.313 239.696C140.313 239.696 138.665 234.472 134.009 234.336C129.353 234.2 122.009 236.296 116.777 246.192"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M132.944 234.024C132.655 234.005 132.371 233.938 132.104 233.824C128.266 232.443 124.1 232.253 120.152 233.28"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M117.473 200.504C125.945 209.864 130.073 222.248 133.985 234.248"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M175.041 175.2C175.041 175.2 190.401 178.864 197.905 179.736C205.409 180.608 206.625 181.656 206.625 181.656L208.025 186.888"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M246.617 184.304L194.305 186.312L190.033 240.112H198.993L244.417 229.944L250.129 185.592L246.617 184.304Z"
        fill="white"
      />
      <path
        d="M192.752 241.704L249.968 186.864L244.416 229.944L192.752 241.704Z"
        fill="#285CA1"
      />
      <path
        opacity="0.7"
        d="M192.752 241.704L249.968 186.864L244.416 229.944L192.752 241.704Z"
        fill="white"
      />
      <path
        d="M192.752 240.264L197.328 188.368L250.128 185.592L244.416 229.944L192.752 240.264Z"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M227.872 210.736C227.52 213.896 225.832 216.296 224.104 216.104C222.376 215.912 221.272 213.184 221.624 210.024C221.976 206.864 223.672 204.464 225.4 204.656C227.128 204.848 228.232 207.576 227.872 210.736Z"
        fill="#263238"
      />
      <path
        d="M244.416 229.944C244.416 229.944 268.592 229.256 276.152 234.248C283.712 239.24 288.152 264.272 287.104 277.568C286.056 290.864 276.936 321.376 276.936 321.376L259.464 301.6C259.464 301.6 250.856 270.304 255.552 258.048C255.552 258.048 232.352 259.352 223.216 256.488C223.216 256.488 215.032 243.088 201 238.656L244.416 229.944Z"
        fill="#263238"
      />
      <path
        d="M280.625 321.896C274.42 316.103 268.847 309.667 264.001 302.696C255.545 289.632 243.857 277.096 231.833 267.312C222.145 259.456 219.953 246.656 208.001 240.232C188.201 229.616 171.081 250.712 164.681 254.984C158.281 259.256 147.201 264.096 147.201 264.096C147.201 264.096 151.249 279.296 163.137 281.096C175.025 282.896 187.049 274.696 192.737 278.656C198.425 282.616 202.633 300.296 221.417 310.656C236.769 319.12 257.689 327.376 268.481 332.944C270.881 334.192 272.673 335.504 274.033 336.248C277.761 338.28 281.745 340.672 285.505 343.04L298.457 350.928C298.457 350.928 311.457 343.24 316.449 336.152C317.113 335.2 318.497 332.664 319.793 330.216C322.617 324.864 326.193 320.064 331.145 317.416C334.569 315.568 337.617 315.016 337.545 314.824C337.217 314.216 331.009 311.04 326.793 312.776C317.865 316.464 300.017 330.632 291.593 329.576C287.675 327.413 283.998 324.838 280.625 321.896Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M280.625 321.896C272.841 325.04 269.705 329.896 268.505 332.952C257.713 327.352 236.793 319.128 221.441 310.664C202.657 300.264 198.481 282.616 192.761 278.664C187.041 274.712 175.009 282.936 163.161 281.104C151.313 279.272 147.201 264.088 147.201 264.088C147.201 264.088 158.289 259.288 164.689 255.016C171.089 250.744 185.849 229.64 208.001 240.264C220.233 246.128 222.145 259.464 231.833 267.344C243.833 277.096 255.545 289.664 264.001 302.728C268.835 309.698 274.409 316.125 280.625 321.896Z"
        fill="#263238"
      />
      <path
        d="M222.129 254.072C222.129 254.072 216.137 241.896 206.529 239.536"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.721 229.832C184.113 228.392 176.857 235.696 173.801 234.328C171.529 233.312 167.793 239.328 167.793 239.328C161.643 240.216 155.425 240.529 149.217 240.264C140.313 239.696 136.097 241.432 132.945 246.192L128.545 257.848C128.545 257.848 140.153 268.248 152.545 262.192C164.937 256.136 181.401 237.864 192.745 237.928"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M282.969 341.456C282.969 341.456 293.817 342.344 291.273 329.384"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M334.089 321.376C333.289 322.976 323.345 331.44 314.777 338.84C314.777 338.728 317.177 334.208 318.169 332.28C320.449 328.024 326.337 316.352 337.545 314.816C339.137 315.776 334.089 321.376 334.089 321.376Z"
        fill="#263238"
      />
      <path
        d="M329.825 312.264C318.489 314.52 315.913 329.064 311.889 335.248C307.865 341.432 296.689 349.84 296.689 349.84"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M290.576 346.128C292.424 349.504 291.552 353.424 290.576 355.88C290.098 357.1 289.503 358.271 288.8 359.376C272.752 356.736 256.704 348.112 256.704 348.112L252.568 335.312L251.904 333.264L258.136 324.872"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M290.576 355.88C290.098 357.1 289.503 358.271 288.8 359.376C272.752 356.736 256.704 348.112 256.704 348.112L252.568 335.312C254.64 338.016 258.48 341.976 264.24 343.6C272.056 345.816 286.48 348.408 290.576 355.88Z"
        fill="#263238"
      />
      <path
        d="M190.033 238.24L194.305 186.312L246.617 184.304L250.129 185.592"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M291.201 335.248C291.201 335.248 298.353 336.528 303.849 332.048C309.345 327.568 318.201 317.432 318.201 317.432"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M298.481 328.8C298.481 328.8 296.753 331.4 298.481 336.616"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M303.552 326.312C302.766 328.905 302.997 331.7 304.2 334.128"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M309.601 322.808C309.601 322.808 308.153 327.152 309.601 330.984"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M259.552 323.792L253.816 330.696C253.816 330.696 259.592 337.008 267.744 339.632C275.896 342.256 290.456 346.712 291.664 351.456"
        stroke="#263238"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default NotLoggedIn;
