import React, { useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { apiUrl } from "../../utils/utils";
import axios from "axios";

export default function RelatedNews({ newsData, newsId }) {
  const navigate = useNavigate();

  useEffect(() => {
    fetchRelatedNews();
  }, []);

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "d MMMM 'at' h:mm a");
  };

  const handleClick = (news) => {
    navigate(`/news-portal/news/${news.id}`);
  };

  const fetchRelatedNews = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/portal-news`);
      const filteredNews = response.data.results
        .filter((news) => news.id !== newsId)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);
    } catch (error) {
      console.error("Error fetching related news", error);
    }
  };

  const handleClickShare = async (event, news) => {
    event.stopPropagation();
    try {
      const shareUrl = `${window.location.origin}/news-portal/news/${news.id}`;
      if (navigator.share) {
        await navigator.share({
          title: news.title,
          text: news.description,
          url: shareUrl,
        });
      } else {
        await navigator.clipboard.writeText(shareUrl);
        toast.info("Link copied to clipboard");
      }
    } catch (error) {
      console.error("Error sharing news:", error);
      toast.error("Error sharing article");
    }
  };

  return (
    <>
      <div className="">
        <p className="text-xl font-bold mt-2 p-4">Related News</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-2 px-4">
        {newsData?.map((news, index) => (
          <div
            key={index}
            onClick={() => handleClick(news)}
            className="flex items-center justify-between pr-4 bg-gray-50 cursor-pointer shadow-xl rounded transition-transform transform hover:scale-105 hover:shadow-2xl"
          >
            <div className="w-[100px] h-[100px] flex items-center justify-center bg-gray-100">
              {news.image ? (
                <img
                  src={news.image}
                  alt="news"
                  className="w-full h-full object-cover border-0 border-black"
                />
              ) : (
                <div className="w-full h-full bg-gray-300 rounded-lg"></div>
              )}
            </div>
            <div className="flex flex-col justify-between h-full py-2 border-0 w-4/5">
              <h3 className="text-base font-semibold text-gray-800">
                {news.title}
              </h3>
              <div className="flex justify-between items-center text-gray-600">
                <div>
                  <span className="text-sm mr-2">
                    {formatDate(news?.updated_at)}
                  </span>
                </div>
                <div className="space-x-4">
                  <BookmarksIcon
                    fontSize="inherit"
                    className="text-gray-600 cursor-pointer"
                  />
                  <ShareIcon
                    fontSize="inherit"
                    className="text-gray-600 cursor-pointer"
                    onClick={(event) => handleClickShare(event, news)} // Pass the event to the handler
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
