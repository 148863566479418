import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomSearch from "../CustomSearch";
import { toast } from "react-toastify";
import CancelIcon from "../Icons/CancelIcon";
import DeleteModal from "../DeleteModal";
import PencilIcon from "../Icons/PencilIcon";
import { formatDate, search } from "../../utils/utils";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IPOAlertModal from "./IPOAlertModal";
import { useClickOutside } from "@mantine/hooks";
const apiHost = process.env.REACT_APP_BACKEND_URL;

const IPOAlerts = () => {
    const [ipoData, setIpoData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [editMode, setEditMode] = useState(false); 
    const [alert, setAlert] = useState({
      alert_name: "",
      alert_type: "open",
    });
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
  
    const handleDeleteModalOpen = (data) => {
      setShowConfirmation(true);
      setSelectedData(data); // Store the data for deletion
    };
  
    const handleEdit = (data) => {
      setSelectedData(data);
      setAlert({
        alert_name: data?.alert_name || "",
        alert_type: data?.alert_type || "open",
      });
      setEditMode(true);
      setOpenModal(true);
    };
  
  
    const handleModal = () => {
      setOpenModal(true);
    };
  
    const handleModalClose = ()=>{
      setOpenModal(false)
      setAlert({
        alert_name: "",
        alert_type: "open",
      })
    }
  
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
      },
    };
  
    const fetchIPOData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${apiHost}/api/ipo-alert/`, config);
  
        setIpoData(response?.data?.results);
        setLoading(false)
      } catch (err){
        setLoading(false);
        // console.log(err.response.status)
        if (err.response.status == 401) {
          toast.error("Session Expired! Please login again");
          localStorage.clear();
          // Navigate to the home route
          navigate("/");
  
          // Reload the page
          window.location.reload();
        } else {
          toast.error("Unable to get IPO alerts at the moment!");
        }
        console.log(err);
      }
    };
  
    const removeSingleAlert = (id) => {
      // console.log(id)
      axios
        .delete(apiHost + `/api/ipo-alert/${id}`, config)
        .then((res) => {
          if (res.status === 200) {
            // Refresh the alerts list after deleting the single item
            fetchIPOData();
            toast.success("Successfully Deleted Alert");
          }
        })
        .catch((err) => {
          // console.log(err.response.status)
          if (err.response.status == 401) {
            toast.error("Session Expired! Please login again");
            localStorage.clear();
            // Navigate to the home route
            navigate("/");
  
            // Reload the page
            window.location.reload();
          } else {
            toast.error("Unable to remove alert at the moment!");
          }
          console.log(err);
        });
    };
  
    const handleChange = (e) => {
      setAlert({ ...alert, [e.target.name]: e.target.value });
    };
  
    const handleSave = async () => {
      try {
        setLoading(true);
        const url = editMode
          ? `${apiHost}/api/ipo-alert/${selectedData?.id}/`
          : `${apiHost}/api/ipo-alert/`;
  
        const method = editMode ? "PATCH" : "POST";
  
        const response = await axios({
          method,
          url,
          data: alert,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
          },
        });
  
        if (editMode) {
          // Update the existing alert in the list
          const updatedIPOData = ipoData.map((item) =>
            item.id === selectedData.id ? response.data : item
          );
          setIpoData(updatedIPOData);
        } else {
          // Add the new alert to the list
          setIpoData((prev) => [...prev, response.data]);
        }
  
        // Reset the state
        setEditMode(false);
        setSelectedData(null);
        setAlert({
          alert_name: "",
          alert_type: "open",
        });
        setOpenModal(false);
        setLoading(false);
        toast.success("Alert Saved Successfully!");
      } catch (err) {
        setLoading(false);
        // console.log(err.response.status)
        if (err.response.status == 401) {
          toast.error("Session Expired! Please login again");
          localStorage.clear();
          // Navigate to the home route
          navigate("/");
  
          // Reload the page
          window.location.reload();
        } else {
          toast.error("Unable to save alert at the moment!");
        }
        console.log(err);
      }
    };
  
    useEffect(() => {
      fetchIPOData();
    }, []);
  
    const tableHeader = [
      "Alert Name",
      "Alert Type",
      // "Created on",
      "triggeredOn",
      "Actions",
    ];
  
    const filteredIPOData = search(ipoData, searchTerm);

  
    
    return (
      // <></>
      <div className="overflow-auto" >
      
        {openModal && (
            <IPOAlertModal
            open={handleModal}
            close={handleModalClose}
            setAlert={setAlert}
            alert={alert}
            handleSave={handleSave}
            handleChange={handleChange}
            loading={loading}
            editMode={editMode} 
            />
            )}
        
        <div className="d-flex align-items-center justify-content-between mt-7 ml-3">
          <span className="h4"></span>
          <div className="flex items-center gap-3 flex-wrap-reverse">
            {/* {selectedArr.length > 0 ? (
                  //   <button
                  //     onClick={() => {
                  //       removeAlerts();
                  //     }}
                  //     className="btn bg-error hover:bg-errorHovered text-white rounded-full"
                  //   >
                  //     Delete Selected
                  //   </button>
                  ) : (
                    <></>
                  )} */}
            <button
              onClick={handleModal}
              className="btn rounded-full bg-primaryBtn hover:bg-primaryHovered text-white "
            >
              Add IPO Alert
            </button>
  
            <CustomSearch setSearch={setSearchTerm} search={searchTerm} />
          </div>
        </div>
  
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <input
                    className="form-check-input checked:bg-primaryBrown"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </th>
                {tableHeader.map((item, index) => (
                  <th key={index} scope="col" style={{ color: "#6c757d" }}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            {filteredIPOData && filteredIPOData?.length > 0 ? (
              filteredIPOData?.map((data, index) => (
                <tbody key={index}>
                  {
                    <tr>
                      <td scope="row">
                        <input
                          // onClick={() => {
                          //   selectedArr.includes(data?.id)
                          //     ? removeSelect(data?.id)
                          //     : selectAlert(data?.id);
                          // }}
                          className="form-check-input checked:bg-primaryBrown"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                      </td>
                      <td>{data?.alert_name}</td>
                      <td>
                        {data?.alert_type
                          ? data?.alert_type
                          : ""}
                      </td>
  
                      {/* <td>{formatDateTime(data?.created_at)}</td> */}
                      <td>
                        {data?.triggered_on
                          ? formatDate(data?.triggered_on, true)
                          : "-"}
                      </td>
  
                      <td>
                        <button onClick={() => handleEdit(data)}>
                          <PencilIcon />
                        </button>
                        <button
                          onClick={() => handleDeleteModalOpen(data)}
                          className="ml-2"
                        >
                          <CancelIcon />
                        </button>
                      </td>
                    </tr>
                  }
                </tbody>
              ))
            ) : (
              <>
                <tbody>
               
                      {loading
                        ? 
                        Array.from({ length: 10 }, (_, rowIndex) => (
                          <tr key={rowIndex}>
                          {tableHeader.map((_, colIndex) => (
                          <td key={colIndex}>
                          <Skeleton
                          animation="wave"
                          variant="rect"
                          height={20}  
                          width={100} 
                          />
                          </td>
                          
                          ))}
                           <td>
          <Skeleton animation="wave" variant="rect" height={20} width={100} />
        </td>
                          </tr>
                          ))
                        : 
                        <tr>
                          <td colSpan={"6"} className="text-center">
                            
                        You haven’t created any alerts
                          </td>
                        </tr>
                        }
                   
                </tbody>
              </>
            )}
          </table>
        </div>
        <DeleteModal
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          removeSingleAlert={removeSingleAlert}
          data={selectedData}
        />
      </div>
    );
}

export default IPOAlerts