import React from "react";

const CircleStackOutlined = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.1431 13.1429V16.5714C21.1431 18.0571 17.5614 20 13.1431 20C8.72478 20 5.14307 18.0571 5.14307 16.5714V13.7143"
        stroke="#8B939B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.479 14.0103C6.46758 15.3223 9.52472 16.5531 13.143 16.5531C17.5613 16.5531 21.143 14.7177 21.143 13.1406C21.143 12.2549 20.015 11.2846 18.2447 10.6034"
        stroke="#8B939B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7144 7.42857V10.8571C17.7144 12.3429 14.1326 14.2857 9.71436 14.2857C5.29607 14.2857 1.71436 12.3429 1.71436 10.8571V7.42857"
        stroke="#8B939B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71436 10.8389C14.1326 10.8389 17.7144 9.00343 17.7144 7.42629C17.7144 5.848 14.1326 4 9.71436 4C5.29607 4 1.71436 5.848 1.71436 7.42629C1.71436 9.00343 5.29607 10.8389 9.71436 10.8389Z"
        stroke="#8B939B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg> 
  );
};

export default CircleStackOutlined;
