import React from "react";

const PencilIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.0402 2.95979C20.736 2.6555 20.3747 2.41412 19.9772 2.24944C19.5797 2.08476 19.1536 2 18.7233 2C18.293 2 17.8669 2.08476 17.4693 2.24944C17.0718 2.41412 16.7106 2.6555 16.4063 2.95979L4.08455 15.2816C3.7179 15.6482 3.45125 16.1032 3.31209 16.6031L2.023 21.207C1.99322 21.3138 1.99234 21.4265 2.02047 21.5337C2.04859 21.6408 2.1047 21.7386 2.18305 21.817C2.2614 21.8953 2.35917 21.9514 2.46634 21.9795C2.57351 22.0077 2.68623 22.0068 2.79296 21.977L7.39602 20.6879C7.89594 20.548 8.35138 20.2817 8.71844 19.9146L21.0402 7.59285C21.6545 6.97841 21.9996 6.14515 21.9996 5.27632C21.9996 4.40748 21.6545 3.57422 21.0402 2.95979ZM17.2904 3.84307C17.6706 3.46306 18.1861 3.24961 18.7236 3.24969C19.2611 3.24977 19.7765 3.46336 20.1565 3.84348C20.5365 4.22361 20.75 4.73912 20.7499 5.27661C20.7498 5.81411 20.5362 6.32956 20.1561 6.70957L18.8728 7.99199L16.0072 5.12549L17.2904 3.84307ZM15.1239 6.00961L17.9904 8.8761L7.83433 19.0313C7.61922 19.2465 7.35233 19.4026 7.05937 19.4846L3.52541 20.4738L4.51452 16.9406C4.59671 16.6477 4.75281 16.3809 4.96783 16.1657L15.1239 6.00961Z"
        fill="#3F4852"
        stroke="#3F4852"
        stroke-width="0.2"
      />
    </svg>
  );
};

export default PencilIcon;
