
import axios from 'axios';
const apiHost = process.env.REACT_APP_BACKEND_URL;

// Fetch symbols from API and update the symbolOptions array
export const fetchSymbolOptions = async () => {
  try {
    const response = await axios.get(apiHost+'/api/stock-symbols');
    if (response.data && Array.isArray(response.data)) {
      // console.log(response.data)
      return response.data.map((symbolData) => symbolData.symbol);
    }
    throw new Error('Invalid API response');
  } catch (error) {
    console.error('Error fetching symbols:', error);
    return []; // Return empty array in case of an error
  }
};

// Default export of an empty array (initially)
export const symbolOptions = [];

// Fetch symbols and update symbolOptions on module load
fetchSymbolOptions().then((symbols) => {
  symbolOptions.push(...symbols);
});







// export const symbolOptions = [
//   "NIFTY 50",
//   "ADANIPORTS",
//   "ADANIPOWER",
//   "ATGL",
//   "BAJAJHIND",
//   "DELTACORP",
//   "IIFLSEC",
//   "IBREALEST",
//   "IBULHSGFIN",
//   "NDTV",
//   "PHOENIXLTD",
//   "BOMDYEING",
//   "CROMPTON",
//   "BCG",
//   "YESBANK",
//   "ZEEL",
//   "LAURUSLABS",
//   "LEMONTREE",
//   "WIPRO",
//   "CYIENT",
//   "M&M",
//   "MARUTI",
//   "DLF",
//   "INFY",
//   "TCS",
//   "RELIANCE",
//   "BALRAMCHIN",
//   "RENUKA",
//   "PVR",
//   "VRLLOG",
//   "CARTRADE",
//   "IDEA",
//   "KOTAKBANK",
//   "PAYTM",
//   "SUNTV",
//   "FORTIS",
//   "IEX"
// ];