import React from "react";

const RedDownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
    >
      <path
        d="M6 9L0.803847 9.78799e-07L11.1962 7.02746e-08L6 9Z"
        fill="#F70202"
      />
    </svg>
  );
};

export default RedDownArrow;
