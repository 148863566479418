import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CustomTable from './CustomTable'

const apiHost = process.env.REACT_APP_BACKEND_URL

const BulkDealsTab = ({symbol, type}) => {
    const [dealsData, setDealsData] = useState([])
    const [tableData, setTableData ] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        fetchBulkDeals()
    },[symbol,type])

    const fetchBulkDeals = async() =>{
        try{
            setLoading(true)
            const response = await axios.get(`${apiHost}/api/csv-data?type=${type}&symbol=${symbol}`)
            // console.log(response.data)
            setDealsData(response.data)
            if(type === "bulk"){
                const extractedData = response.data?.bulk_csv.map((deal) => ({ //bulk deals mapping
                    client_name: deal?.client_name,
                    date: deal?.date,
                    buy_sell: deal?.buy_sell,
                    quantity_traded: deal?.quantity_traded,
                    trade_price: deal?.trade_price

                  }));

                  setTableData(extractedData);
            }else{
                const extractedData = response.data?.block_csv.map((deal) => ({ //block deals mapping
                    client_name: deal?.client_name,
                    date: deal?.date,
                    buy_sell: deal?.buy_sell,
                    quantity_traded: deal?.quantity_traded,
                    trade_price: deal?.trade_price

                  }));

                  setTableData(extractedData);
            }
            setLoading(false)
        }catch(error){
            setLoading(true)
            console.log("Error at bulkDealsTab:",error)
        }
    }

    const tableHeader = ["Client Name", "Date", "Buy/Sell",  "Quantity Taded", "Trade Price"]
 
  return (
    <div className='text-red-500'>
        <CustomTable loading={loading} headers={tableHeader} rowData={tableData}/>
    </div>
  )
}

export default BulkDealsTab