import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationPanel from "./NotificationPanel";
import GoogleIcon from "./Icons/GoogleIcon";
import LogoutIcon from "./Icons/LogoutIcon";
import { generateToken } from "../utils/generateWatchlistToken";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import BSELogo from "../assets/image/BSELogo.png";

const apiHost = process.env.REACT_APP_BACKEND_URL;


export default function Navbar(props) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [stockSymbols, setStockSymbols] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const [indices, setIndices] = useState([{}]);

  const filteredDataRef = useRef(null);
  const searchRef = useRef(null);

  const tempToken = localStorage.getItem("TempToken");

  let isLoggedIn = props.login;
  let setisLoggedIn = props.setLogin;

  useEffect(() => {
    fetchStockSymbols();
    fetchIndices();
    if (!isLoggedIn) {
      fetchLoggedoutToken();
    }
  }, [isLoggedIn]);

  const fetchLoggedoutToken = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/get-client-token`);
      const data = response?.data?.token;
      localStorage.setItem("TempToken", data);
    } catch (error) {
      console.log("An error ocurred", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isSearchInputFocused = document.activeElement === searchRef.current;
      const isInputEmpty = !searchTerm;
    
      if (
        (!isSearchInputFocused && filteredDataRef.current) ||
        (isInputEmpty && searchRef.current)
      ) {
        if (
          (!searchRef.current || !searchRef.current.contains(event.target)) &&
          (!filteredDataRef.current || !filteredDataRef.current.contains(event.target))
        ) {
          setInputFocused(false);
        }
      }
    };
    

  document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchStockSymbols = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/stock-symbols`);
      if (response.data) {
        setStockSymbols(response.data);
      }
    } catch (error) {
      console.error("Error fetching stock symbols:", error);
    }
  };

  const fetchIndices = async () => {
    try {
      const watchlistToken = tempToken
        ? tempToken
        : JSON.parse(localStorage.getItem("WatchlistToken"));
      const apiURL = `https://stock.accordwebservices.com/BSEStock/GetLiveIndiceData?ExCode=BE49H6S&Top=1&PageNo=1&Pagesize=100&SortExpression=&SortDirection=Asc&token=${watchlistToken}`
      const response = await axios.get(apiURL);
      if (response.data) {
        setIndices(response.data.Table);
      }
    } catch (error) {
      console.error("Error fetching indices:", error);
    }
  };


  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    // Filtering based on both symbol and name
    const filteredResults = stockSymbols.filter(
      (stock) =>
        stock.symbol.toLowerCase().startsWith(searchTerm) ||
        stock.name.toLowerCase().startsWith(searchTerm)
    );

    setSearchTerm(event.target.value);
    setFilteredData(filteredResults);
  };

  useEffect(() => {
    // Check if the token has expired when the component mounts
    const token = localStorage.getItem("Token");
    if (token) {
      const decodedToken = parseJwt(token);
      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        // Token has expired, log the user out
        localStorage.clear();
        setisLoggedIn(false);
      }
    }
  }, [setisLoggedIn]);

  const handleSymbolClick = (symbol) => {
    const selectedSymbol = stockSymbols.find(
      (stock) => stock.symbol === symbol
    );
      if (selectedSymbol) {
      navigate(`/historic/${selectedSymbol.symbol}/${selectedSymbol.fincode}`);
      setSearchTerm("");
      setInputFocused(false);
    }
  };

  // Helper function to decode a JWT token (you may need to adjust this)
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        axios
          .post(apiHost + "/auth/google/", {
            access_token: response.access_token,
          })
          .then((res) => {
            if (res.data) {
              localStorage.setItem(
                "Token",
                JSON.stringify(res.data.access_token)
              );
              localStorage.setItem(
                "Username",
                JSON.stringify(
                  res.data.user.first_name + " " + res.data.user.last_name
                )
              );
              localStorage.removeItem("TempToken");
              generateToken();
              return res;
            }
          })
          .then((res) => {
            // window.location.reload(false);
            setisLoggedIn(true);
            // navigate("/");
          })
          .catch((err) => {
            toast.error("Something went wrong");
            console.log(err);
          });
      } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
      }
    },
  });

  const logout = () => {
    localStorage.clear();
    // window.location.reload(false);
    setisLoggedIn(false);
    window.location = "/news";
  };

  const BeforeSignin = () => {
    return (
      <button
        onClick={googleLogin}
        className="flex gap-2 items-center btn btn-outline-dark rounded-full text-primaryBtn border-primaryBtn"
      >
        <GoogleIcon />
        <div>Login</div>
      </button>
    );
  };

  const AfterSignin = () => {
    return (
      <div className="dropdown">
        <button
          className="dropdown-toggle btn hover:bg-[#F8F7F8] rounded-full border-1 text-primaryHovered border-primaryHovered hover:border-1 hover:border-black"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {JSON.parse(localStorage.getItem("Username"))}
        </button>
        <ul
          onClick={logout}
          className="dropdown-menu rounded-lg hover:bg-hoveredWhite  cursor-pointer"
        >
          <li className="flex px-2 py-1 justify-start gap-2 items-center">
            <LogoutIcon />
            <button className="  cursor-pointer">Logout</button>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <nav className="navbar navbar-expand-lg w-full bg-navbarBg h-[60px] fixed z-40 md:z-none md:relative ">
      <div className="flex justify-between w-full ms-3 md:mx-5 mt-1">
        <div className=" navbar-collapse " id="navbarSupportedContent">
          <div className="ml-8 md:ml-56 w-full flex justify-start">
            <div className="relative max-w-[500px] flex-grow">
              <div
                className={`absolute left-2 top-1 p-1 ${
                  inputHovered
                    ? inputFocused
                      ? ""
                      : "bg-searchPrimary bg-opacity-5 rounded-full"
                    : ""
                }`}
              >
                <SearchIcon className="text-primaryHovered" />
              </div>
              <input
                ref={searchRef}
                className={`ps-5 font-medium text-primaryHovered ${
                  inputFocused
                    ? searchTerm
                      ? "bg-searchPrimary bg-opacity-5 rounded-[20px] rounded-b-none shadow-md"
                      : "bg-white rounded-full shadow-md"
                    : inputHovered
                    ? "bg-white rounded-full"
                    : "bg-searchPrimary bg-opacity-5 rounded-full"
                } placeholder:text-inherit outline-none pe-3 py-2 lg:w-full shadow-sm`}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setInputFocused(true)}
                // onBlur={() => setInputFocused(false)}
                onMouseOver={() => setInputHovered(true)}
                onMouseLeave={() => setInputHovered(false)}
              />
              <div
                className={`${
                  searchTerm
                    ? "absolute right-2 top-1 p-1 cursor-pointer"
                    : "hidden"
                }`}
                onClick={() => setSearchTerm("")}
              >
                <CloseIcon />
              </div>
              {searchTerm && inputFocused && (
                <div
                  ref={filteredDataRef}
                  className={`absolute bg-white top-10 ${
                    searchTerm && inputFocused ? "" : ""
                  } border-t border-searchPrimary shadow-lg w-full z-50 h-72 rounded-b-[20px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300`}
                >
                  {filteredData.length === 0 ? (
                    <div className="py-3 text-center flex items-center justify-center h-full">
                      No data found
                    </div>
                  ) : (
                    filteredData.map((data, index) => (
                      <div
                        className="py-3 pl-2 flex items-center gap-4 overflow-hidden border-gray-300 cursor-pointer transition-transform transform hover:bg-secondaryHovered"
                        key={index}
                        onClick={() => handleSymbolClick(data?.symbol)}
                      >
                        <div className="flex w-6 h-4">
                          <img src={BSELogo} alt="BSE Logo" height={100} width={100} />
                        </div>
                        <div className="w-[80px] mr-1">{data?.symbol}</div>
                        {data?.name}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>

            <div className="ml-4 flex gap-4">
              {indices.map(
                (index) =>
                  index.INDEX_NAME === "SENSEX" && (
                    <div key={index.INDEX_ID}>
                      <p className="text-sm font-bold">{index.INDEX_NAME}</p>
                      <p
                        className="text-xs font-bold"
                        style={{
                          color: index.CHANGE >= 0 ? "green" : "red",
                        }}
                      >
                        {index.CLOSE}{" "}
                        <span className="font-normal">
                          {index.CHANGE >= 0 ? "+" : ""}
                          {index.CHANGE} ({index.PER_CHANGE}%)
                        </span>
                      </p>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        <div className="mr-4">
          <NotificationPanel isLoggedIn={isLoggedIn} />
        </div>
        <div className="hidden text-primaryHovered md:block">
          {isLoggedIn ? <AfterSignin /> : <BeforeSignin />}
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  title: PropTypes.string,
};
