import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Skeleton,
} from "@mui/material";
import {
  GraphicEq,
  GraphicEqOutlined,
  LocalOffer,
  LocalOfferOutlined,
  Search,
  Visibility,
  VisibilityOutlined,
  WavesOutlined,
} from "@mui/icons-material";

import MovingIcon from '@mui/icons-material/Moving';
import DialogBox from "./DialogBox/DialogBox";
import axios from "axios";
import NewAlert from "./NewAlert";
import { AlertContext } from "../store";
import Bell from "./Icons/Bell";
import { toast } from "react-toastify";
import Ads from "./Ads";
import WarningIcon from "./Icons/WarningIcon";
import CircleStack from "./Icons/CircleStack";
import PencilIcon from "./Icons/PencilIcon";
import CancelIcon from "./Icons/CancelIcon";
import CustomSearch from "./CustomSearch";
import DeleteModal from "./DeleteModal";
import CircleStackOutlined from "./Icons/CircleStackOutlined";
import WarningFilledIcon from "./Icons/WarningFilledIcon";
import RenderTable from "./RenderTable/RenderTable";
import DealsAlert from "./DealsAlert/DealsAlert";
import { formatDate, search } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import NotLoginDashboard from "../pages/NotLoginDashboard/NotLoginDashboard";
import IPOAlerts from "./IPO/IPOAlerts";
import ASMAlert from "./ASM/ASMAlert";
import AsmIcon from "./Icons/AsmIcon";

const apiHost = process.env.REACT_APP_BACKEND_URL;

const StockAlert = ({ setisLoggedIn, isLoggedIn, sideMenuCollapsed }) => {
  return (
    <>
      {" "}
      <HasAlerts setisLoggedIn={setisLoggedIn} isLoggedIn={isLoggedIn} sideMenuCollapsed={sideMenuCollapsed} />
    </>
  );
};

const HasAlerts = ({ setisLoggedIn, isLoggedIn, sideMenuCollapsed }) => {
  const { updated, setUpdated, alertsArr, setAlertsArr } =
    useContext(AlertContext);
  const [loading, setloading] = useState(false);
  const [selectedArr, setSelectedArr] = useState([]);
  const [watchlistData, setWatchlistData] = useState();
  const [editData, setEditData] = useState({});
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [modalType, setModalType] = useState(null);
  const [activeTable, setActiveTable] = useState("watchlist");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const navigate = useNavigate();

  let token = localStorage.getItem("Token");

  useEffect(() => {
    token = localStorage.getItem("Token");
  }, [isLoggedIn]);

  const handleDeleteModalOpen = (data) => {
    setShowConfirmation(true);
    setSelectedData(data); // Store the data for deletion
  };

  const openNewsModal = () => {
    setModalType("news");
    handleOpen();
  };
  const handleClose2 = () => {
    setOpen2(false);
    setWatchlistData(null);
  };
  const openPriceModal = () => {
    setModalType("price");
    handleOpen();
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  useEffect(() => {
    if ((activeTable === "news" || activeTable === "price") && token) {
      getAlerts();
    }
    setSearchTerm("");
  }, [activeTable, token]);

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
    },
  };

  const getAlerts = () => {
    setloading(true);
    axios
      .get(apiHost + "/api/stock-alert/", config)
      .then((res) => {
        if (res?.status === 200) {
          setAlertsArr(res.data);
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        // console.log(err.response.status)
        if (err.response.status == 401) {
          toast.error("Session Expired! Please login again");
          localStorage.clear();
          // Navigate to the home route
          navigate("/");

          // Reload the page
          window.location.reload();
        } else {
          toast.error("Unable to get alerts at the moment!");
        }
        console.log(err);
      });
  };

  const getWatchlistData = (id) => {
    setloading(true);
    axios
      .get(apiHost + `/api/stock-alert/${id}`, config)
      .then((res) => {
        if (res?.status === 200) {
          setWatchlistData({ ...res.data.watchlist_status });
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        // console.log(err.response.status)
        if (err.response.status == 401) {
          toast.error("Session Expired! Please login again");
          localStorage.clear();
          // Navigate to the home route
          navigate("/");

          // Reload the page
          window.location.reload();
        } else {
          toast.error("Unable to get watchlist at the moment!");
        }
        console.log(err);
      });
  };

  const selectAlert = (ind) => {
    setSelectedArr([...selectedArr, ind]);
  };

  const removeSelect = (ind) => {
    setSelectedArr(
      selectedArr.filter((data, index) => {
        return data != ind;
      })
    );
  };

  const removeAlerts = () => {
    selectedArr.forEach((ele) => {
      axios
        .delete(apiHost + `/api/stock-alert/${ele}`, config)
        .then((res) => {
          if (res.status === 204) {
            getAlerts();
            toast.success("Successfully Deleted Alert");
          }
        })
        .catch((err) => {
          toast.error("Unable to remove the alert at the moment!");
          console.log(err);
        });
    });
    setSelectedArr([]);
    setUpdated(!updated);
  };

  const removeSingleAlert = (id) => {
    axios
      .delete(apiHost + `/api/stock-alert/${id}`, config)
      .then((res) => {
        if (res.status === 204) {
          // Refresh the alerts list after deleting the single item
          getAlerts();
          toast.success("Successfully Deleted Alert");
        }
      })
      .catch((err) => {
        toast.error("Unable to remove selected alerts at the moment!");
        console.log(err);
      });
  };

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedDate + " " + formattedTime;
  }

  const priceAlertTableHead = [
    "Name",
    "Stock",
    "Status",
    "Triggered",
    "Actions",
  ];
  const newsAlertTableHead = ["Name", "Stock", "Actions"];

  const priceAlertFilteredData = search(alertsArr["price-alerts"], searchTerm);
  const newsAlertFilteredData = search(alertsArr["news-alerts"], searchTerm);

  // console.log(sideMenuCollapsed)

  return (
    <>
      <div className={`md:min-w-[50%] mt-16 md:mt-0 w-full  pt-2 pl-2 md:pl-0 flex    transition-all duration-500 `}>
        <div  className={`w-full ${sideMenuCollapsed ? "ml-0" : "md:ml-28"} bg-white rounded-xl p-2 px-2 mt-2  transition-all duration-500`}>
        <Tabs 
          value={activeTable}
          onChange={(event, newValue) => setActiveTable(newValue)}
          className="mt-0"
          sx={{
            height: "40px",
            borderBottom: "1px solid lightgray",
           "& .css-69z67c-MuiTabs-scroller":{
            width: "300px",
            minWidth: "200px",
           },
           "& .MuiTab-root": {
            minHeight: "30px",
          },
          }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            value="watchlist"
            className={
              activeTable === "watchlist"
                ? "btn-primary font-semibold"
                : "btn-light text-dark font-semibold"
            }
            icon={
              activeTable === "watchlist" ? (
                <Visibility />
              ) : (
                <VisibilityOutlined color="disabled" />
              )
            }
            iconPosition="start"
            label={
              <span
                style={{
                  color: activeTable === "watchlist" ? "initial" : "gray",
                }}
              >
                watchlist
              </span>
            }
            onClick={() => setActiveTable("watchlist")}
            sx={{padding:"10px", fontWeight: activeTable === "watchlist" ? "bold" : "normal"}}
          />
          <Tab
            value="news"
            className={
              activeTable === "news"
                ? "btn-primary font-bold"
                : "btn-light text-dark font-bold"
            }
            icon={
              activeTable === "news" ? <WarningFilledIcon /> : <WarningIcon />
            }
            iconPosition="start"
            label={
              <span
                style={{ color: activeTable === "news" ? "initial" : "gray" }}
              >
                News Alerts
              </span>
            }
            onClick={() => setActiveTable("news")}
            sx={{padding:"10px", fontWeight: activeTable === "news" ? "bold" : "normal"}}
          />
          <Tab
            value="price"
            className={
              activeTable === "price"
                ? "btn-primary font-semibold"
                : "btn-light text-dark font-semibold"
            }
            icon={
              activeTable === "price" ? (
                <CircleStack />
              ) : (
                <CircleStackOutlined />
              )
            }
            iconPosition="start"
            label={
              <span
                style={{ color: activeTable === "price" ? "initial" : "gray" }}
              >
                Price Alerts
              </span>
            }
            onClick={() => setActiveTable("price")}
            sx={{padding:"10px", fontWeight: activeTable === "price" ? "bold" : "normal"}}
          />
          <Tab
            value="deals"
            className={
              activeTable === "deals"
                ? "btn-primary font-semibold"
                : "btn-light text-dark font-semibold"
            }
            icon={
              activeTable === "deals" ? (
                <LocalOffer />
              ) : (
                <LocalOfferOutlined color="disabled" />
              )
            }
            iconPosition="start"
            label={
              <span
                style={{ color: activeTable === "deals" ? "initial" : "gray" }}
              >
                Deals Alerts
              </span>
            }
            onClick={() => setActiveTable("deals")}
            sx={{padding:"10px", fontWeight: activeTable === "deals" ? "bold" : "normal"}}
          />
          <Tab
            value="ipo"
            className={
              activeTable === "ipo"
                ? "btn-primary font-semibold"
                : "btn-light text-dark font-semibold"
            }
            icon={
              activeTable === "ipo" ? (
                <GraphicEq />
              ) : (
                <GraphicEqOutlined color="disabled" />
              )
            }
            iconPosition="start"
            label={
              <span
                style={{ color: activeTable === "ipo" ? "initial" : "gray" }}
              >
                IPO Alerts
              </span>
            }
            onClick={() => setActiveTable("ipo")}
            sx={{padding:"10px", fontWeight: activeTable === "ipo" ? "bold" : "normal"}}
          />
          <Tab
            value="asm"
            className={
              activeTable === "asm"
                ? "btn-primary font-semibold"
                : "btn-light text-dark font-semibold"
            }
            icon={
              activeTable === "asm" ? (
                <MovingIcon />
              ) : (
                <MovingIcon color="disabled" />
              )
            }
            iconPosition="start"
            label={
              <span
                style={{ color: activeTable === "asm" ? "initial" : "gray" }}
              >
                ASM/GSM
              </span>
            }
            onClick={() => setActiveTable("asm")}
            sx={{padding:"10px", fontWeight: activeTable === "asm" ? "bold" : "normal"}}
          />
        </Tabs>
     

        {activeTable === "watchlist" ? (
          <div className="w-full mt-2">
            {token ? (
              <RenderTable />
            ) : (
              <div className="flex  justify-center">
                <NotLoginDashboard />
              </div>
            )}
          </div>
        ) : activeTable === "deals" ? (
          <div className="w-full">
            {token ? (
              <DealsAlert setModalType={setModalType} />
            ) : (
              <div className="flex  justify-center">
                <NotLoginDashboard />
              </div>
            )}
          </div>
        ) : activeTable === "asm" ? (
          <div className="w-full">
            {token ? (
              <ASMAlert setModalType={setModalType} />
            ) : (
              <div className="flex  justify-center">
                <NotLoginDashboard />
              </div>
            )}
          </div>
        ) : activeTable === "ipo" ? (
          <div className="w-full">
            {token ? (
              <IPOAlerts setModalType={setModalType} />
            ) : (
              <div className="flex  justify-center">
                <NotLoginDashboard />
              </div>
            )}
          </div>
        ) : activeTable === "news" ? (
          //  News Alerts table content here
          <>
            {token && (
              <div className="d-flex overflow-auto align-items-center justify-content-between mt-7 ml-3">
                <span className="h4"></span>
                <div className="flex items-center flex-wrap-reverse gap-3">
                  {selectedArr.length > 0 ? (
                    <button
                      onClick={() => {
                        removeAlerts();
                      }}
                      className="btn bg-error hover:bg-errorHovered text-white rounded-full"
                    >
                      Delete Selected
                    </button>
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={openNewsModal}
                    className="btn rounded-full bg-primaryBtn hover:bg-primaryHovered text-white "
                  >
                    Add News Alert
                  </button>

                  <CustomSearch setSearch={setSearchTerm} search={searchTerm} />
                </div>
              </div>
            )}

            {!token ? (
              <div className="flex  justify-center">
                <NotLoginDashboard />
              </div>
            ) : (
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          className="form-check-input checked:bg-primaryBrown"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                      </th>
                      {newsAlertTableHead.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          style={{ color: "#6c757d" }}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {newsAlertFilteredData && newsAlertFilteredData.length > 0 ? (
                    newsAlertFilteredData?.map((data, index) => (
                      <tbody key={index}>
                        <tr>
                          <td scope="row">
                            <input
                              onClick={() => {
                                selectedArr.includes(data?.id)
                                  ? removeSelect(data?.id)
                                  : selectAlert(data?.id);
                              }}
                              className="form-check-input checked:bg-primaryBrown"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                          </td>
                          <td>{data?.alert_name}</td>
                          <td>
                            {data?.symbol
                              ? data?.symbol
                              : data.status == "running" || data.status == "hit"
                                ? "WATCHLIST"
                                : "WATCHLIST"}
                          </td>

                          {/* <td>{formatDate(data?.created_at)}</td> */}
                          <td>
                            <button
                              onClick={() => {
                                setModalType("news");
                                setOpen((prev) => !prev);
                                setEditData(data);
                                setEdit(true);
                              }}
                            >
                              <PencilIcon />
                            </button>
                            <button
                              onClick={() => handleDeleteModalOpen(data)}
                              className="ml-2"
                            >
                              <CancelIcon />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <>
                      <tbody>
                        {loading ? (
                          Array.from({ length: 10 }, (_, rowIndex) => (
                            <tr key={rowIndex}>
                              {newsAlertTableHead.map((_, colIndex) => (
                                <td key={colIndex}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height={20}
                                    width={100}
                                  />
                                </td>
                              ))}
                              <td>
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  height={20}
                                  width={100}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={"6"}>
                              You haven’t created any alerts
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            )}
          </>
        ) : (
          //  Price Alerts table content here

          <>
            {token && (
              <div className="d-flex overflow-auto align-items-center justify-content-between mt-7 ml-3">
                <span className="h4"></span>
                <div className="d-flex items-center flex-wrap-reverse gap-3">
                  {selectedArr.length > 0 ? (
                    <button
                      onClick={() => {
                        removeAlerts();
                      }}
                      className="btn bg-error hover:bg-errorHovered text-white rounded-full"
                    >
                      Delete Selected
                    </button>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={openPriceModal}
                    className="btn rounded-full bg-primaryBtn hover:bg-primaryHovered text-white "
                  >
                    Add Price Alert
                  </button>

                  <CustomSearch setSearch={setSearchTerm} search={searchTerm} />
                </div>
              </div>
            )}

            {!token ? (
              <div className="flex  justify-center">
                <NotLoginDashboard />
              </div>
            ) : (
              <div className="overflow-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          className="form-check-input checked:bg-primaryBrown"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                      </th>
                      {priceAlertTableHead.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          style={{ color: "#6c757d" }}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {priceAlertFilteredData &&
                    priceAlertFilteredData.length > 0 ? (
                    priceAlertFilteredData?.map((data, index) => (
                      <tbody key={index}>
                        <tr>
                          <td scope="row">
                            <input
                              onClick={() => {
                                selectedArr.includes(data?.id)
                                  ? removeSelect(data?.id)
                                  : selectAlert(data?.id);
                              }}
                              className="form-check-input checked:bg-primaryBrown"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                          </td>
                          <td>{data?.alert_name}</td>
                          <td>
                            {data?.symbol
                              ? data?.symbol
                              : data.status == "running" || data.status == "hit"
                                ? "WATCHLIST"
                                : "WATCHLIST"}
                          </td>
                          <td
                            style={{ textTransform: "capitalize" }}
                            onClick={
                              (data.status == "running" ||
                                data.status == "hit") &&
                                !data.symbol
                                ? () => {
                                  getWatchlistData(data?.id);
                                  handleOpen2();
                                }
                                : null
                            }
                          >
                            <span
                              style={
                                (data.status == "running" ||
                                  data.status == "hit") &&
                                  !data.symbol
                                  ? { cursor: "pointer", color: "#3366CC" }
                                  : {}
                              }
                            >
                              {data?.status}
                            </span>
                          </td>
                          <td
                            style={
                              !data.triggered_on ? { paddingLeft: "75px" } : {}
                            }
                          >
                            {data.triggered_on
                              ? formatDate(data?.triggered_on)
                              : "-"}
                          </td>
                          {/* <td>{formatDate(data?.created_at)}</td> */}
                          <td>
                            <button
                              onClick={() => {
                                setModalType("price");
                                setOpen((prev) => !prev);
                                setEditData(data);
                                setEdit(true);
                              }}
                            >
                              <PencilIcon />
                            </button>
                            <button
                              onClick={() => handleDeleteModalOpen(data)}
                              className="ml-2"
                            >
                              <CancelIcon />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <>
                      <tbody>
                        {loading ? (
                          Array.from({ length: 10 }, (_, rowIndex) => (
                            <tr key={rowIndex}>
                              {priceAlertTableHead.map((_, colIndex) => (
                                <td key={colIndex}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height={20}
                                    width={100}
                                  />
                                </td>
                              ))}
                              <td>
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  height={20}
                                  width={100}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={"6"}>
                              You haven’t created any alerts
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            )}
          </>
          )}

        <NewAlert
          type={modalType}
          edit={edit}
          data={editData}
          openfunc={handleOpen}
          getFunc={getAlerts}
          close={handleClose}
          open={open}
        />
        <DialogBox
          loading={loading}
          open={open2}
          close={handleClose2}
          data={watchlistData}
        />
      </div>
      <DeleteModal
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        removeSingleAlert={removeSingleAlert}
        data={selectedData}
      />
      <div className="hidden md:block">
        <Ads />
      </div>
       </div>
    </>
  );
};

const EmptyAlerts = () => {
  const [open, setOpen] = React.useState(false);
  const [modalType, setModalType] = useState(null);
  const { updated, setUpdated, alertsArr, setAlertsArr } =
    useContext(AlertContext);

  const openNewsModal = () => {
    setModalType("news");
    setOpen(true);
  };

  const openPriceModal = () => {
    setModalType("price");
    setOpen(true);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
    },
  };

  const getAlerts = () => {
    axios
      .get(apiHost + "/api/stock-alert/", config)
      .then((res) => {
        if (res?.status === 200) {
          setUpdated(!updated);
          setAlertsArr(res.data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  return (
    <>
      <div className="d-flex flex-column mt-8 align-items-center justify-content-lg-center col-md-9">
        <Bell />
        <div className="d-flex flex-column">
          <h1 style={{ color: "#ced4da" }} className="text-md-center #f8f9fa">
            You haven't created any alerts.
          </h1>
          <div className="flex gap-3">
            <button
              className="btn rounded-full bg-primaryBtn hover:bg-primaryHovered text-white px-4 mt-8 "
              onClick={openNewsModal}
            >
              Create News Alert
            </button>
            <button
              className="btn rounded-full bg-primaryBtn hover:bg-primaryHovered text-white px-4 mt-8 "
              onClick={openPriceModal}
            >
              Create Price Alert
            </button>
          </div>
          <NewAlert
            type={modalType}
            open={open}
            close={() => setOpen(false)}
            getFunc={getAlerts}
          />
        </div>
      </div>
    </>
  );
};

export default StockAlert;
