import React, { useEffect, useState } from "react";
import "./NewsCard.css";
import rightArrow from "../../assets/svg/right-arrow.svg";
import MoneyControl from "../../assets/image/MoneyControl.png";
import ET from "../../assets/image/ET.png";
import BS from "../../assets/image/BS.jpg";
import LiveMint from "../../assets/image/LiveMint.png";
import NSE from "../../assets/image/NSE.png";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import Tag from "./Tag";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import badNews from "../../assets/svg/BadNews.svg";
import goodNews from "../../assets/svg/GoodNews.svg";
import neutralNews from "../../assets/svg/NeutralNews.svg";
import Logo from "../../../src/assets/svg/logoFull.svg";
import { apiUrl } from "../../utils/utils";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const fetchCurrentPrice = async (finCode) => {
  const loggedInToken = JSON.parse(localStorage.getItem("WatchlistToken"));
  const tempToken = localStorage.getItem("TempToken");

  const watchlistToken = localStorage.getItem("Token") ? loggedInToken : tempToken;
  try {
    const response = await axios.get(
      `https://stock.accordwebservices.com/BSEStock/GetQuoteFinder?fincode=${finCode}&token=${watchlistToken}`
    );
    return response?.data?.Table[0]?.PER_CHANGE || "-";
  } catch (err) {
    console.log("Error fetching current price", err);
    return "-";
  }
};

function NewsCard({ data, openModal, closeModal, cardHeight, needGap, watchlistToken, sideMenuCollapsed,percentage }) {
  const [percentageChange, setPercentageChange] = useState("-");

  useEffect(() => {
    if (data.fincodes?.fincode) {
      fetchCurrentPrice(data.fincodes.fincode, watchlistToken).then(setPercentageChange);
    }
  }, [data.fincodes, watchlistToken]);

  function timeAgo(dateString) {
    const date = new Date(dateString);
    const currentTime = new Date();
    const timeDiff = currentTime - date;
    return formatTimeDiff(timeDiff);
  }

  function formatTimeDiff(timeDiff) {
    const minutes = Math.floor(timeDiff / (1000 * 60));
    if (minutes < 60) {
      return minutes + " min(s) ago";
    } else {
      const hours = Math.floor(minutes / 60);
      if (hours < 24) {
        return hours + " hour(s) ago";
      } else {
        const days = Math.floor(hours / 24);
        return days + " day(s) ago";
      }
    }
  }

  function trimText(data, length) {
    if (!data) return data;
    let trimmedData = removeLimited(data);
    if (trimmedData.length > (length ? length : 50)) {
      return trimmedData.substring(0, (length ? length : 50)) + "...";
    } else {
      return trimmedData;
    }
  }
  
  function removeLimited(data) {
    if (data.split(" ").length >= 3) {
      const regex = /\blimited\b/i;
      return data.replace(regex, "").trim();
    }
    return data;
  }
  
  function isGoodNews(summary) {
    const goodNewsRegex = /good\s*(news)?/i;
    const positiveNewsRegex = /positive\s*(news)?/i;
    return goodNewsRegex.test(summary) || positiveNewsRegex.test(summary);
  }

  function isBadNews(summary) {
    const badNewsRegex = /bad\s*(news)?/i;
    const negativeNewsRegex = /negative\s*(news)?/i;
    return badNewsRegex.test(summary) || negativeNewsRegex.test(summary); 
  }

  function isNeutralNews(summary) {
    const neutralNewsRegex = /neutral\s*(news)?/i;
    return neutralNewsRegex.test(summary);
  }

  const showXmlTooltip = data?.link.includes(".xml");
  const dailyStockHunt = data?.source === "dailystockhunt";
  const imageUrl = data.source === 'dailystockhunt' ? `${apiUrl}${data.image}` : data.image;

  const handleOnClick = () => {
    if (showXmlTooltip) {
      openModal(data);
    }else if (dailyStockHunt) {
      window.open(`/news-portal/news/${data?.id}`, "_blank");
    } 
    else if(data?.link === "") {
      openModal(data);
    }else {
      window.open(data?.link, "_blank");
    }
  };

  // console.log(data.pdf_content_tags.length)


  return (
    <div
      className={`relative news-card d-flex flex-column justify-content-between rounded-xl transition-all duration-500 ${sideMenuCollapsed ? "max-w-[400px]" : " max-w-[350px]"}  h-[${cardHeight}]`}
      onClick={handleOnClick}
    >
      <LightTooltip
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              padding: "16px",
            },
          },
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        placement="bottom"
        arrow
        title={showXmlTooltip ? null : data?.pdf_summary}
      >
        <div className={`news-body d-flex gap-1 bg-white rounded-xl`} style={{ height: `${cardHeight}px` }}>
          {data?.image && data?.image !== "None" ? (
            <div style={{ height: `${cardHeight}px` }} className={`w-[130px] overflow-hidden p-2 bg-mainBackground`}>
              <img
                className="object-cover w-full h-full rounded"
                src={imageUrl}
                alt="temp"
              />
            </div>
          ) : (
            <div style={{ height: `${cardHeight}px` }} className="w-[130px] overflow-hidden flex items-center justify-center bg-mainBackground p-2">
              <img
                src={
                  data?.source === "MoneyControl"
                    ? MoneyControl
                    : data?.source === "Economic Times"
                      ? ET
                      : data?.source === "Business Standard"
                        ? BS
                        : data?.source === "live Mint"
                          ? LiveMint
                          : data?.source === "NSE"
                            ? NSE
                            : data?.source === "dailystockhunt"
                              ? Logo
                              : "https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                }             
                alt="temp"
                className="w-full h-full rounded"
              />
            </div>
          )}
          <div className={`d-flex flex-column news-card-body ${needGap ? 'gap-5' : ""}`}>
            <div className="title capitalize flex justify-between gap-1">
              <span>{data.pdf_content_tags && data.pdf_content_tags.length > 0 ? trimText(data?.title, 40) : trimText(data?.title, 90)}</span>
              {percentageChange !== "-" && percentageChange !== "0.00" && percentage && (
                <div className={`flex current-price ${percentageChange > 0 ? "text-success" : "text-error"} text-sm font-semibold`}>
                  {percentageChange + "%"}
                  <span>
                    {
                      percentageChange > 0
                        ? <ArrowUpward fontSize="small" className="text-success" />
                        : <ArrowDownward fontSize="small" className="text-error " />
                    }
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex published justify-between">
              <div className="leading-4" style={{ minWidth: "100px" }}>
                {data?.source ? <span>{data?.source + ","} &nbsp;</span> : null}
                <span className=" leading-3">{timeAgo(data?.published)}</span>
                {data?.subject && <div className="subject">
              <span>Sub: {trimText(data?.subject, 40)}</span>
            </div>}
            </div>
              {data?.pdf_summary && isGoodNews(data.pdf_summary) ? (
                  <img src={goodNews}
                    alt="good news"
                    className="w-10 h-10 ml-2"
                  />
                ) : data?.pdf_summary && isBadNews(data.pdf_summary) ? (
                  <img src={badNews}
                    alt="bad news"
                    className="w-10 h-10"
                  />
                ) : data?.pdf_summary && isNeutralNews(data.pdf_summary) ? (
                  <img src={neutralNews}
                    alt="neutral news"
                    className="w-10 h-10"
                  />
                ) : null }
            </div>
            <div className={`z-0 md:z-10 ${data.pdf_content_tags === undefined ? "tag-container-disabled" : "tag-container"}`}>
              {Array.isArray(data?.pdf_content_tags) && data.pdf_content_tags.length > 0
                ? data.pdf_content_tags.length > 2
                  ? data.pdf_content_tags.slice(0, 3).map((tagData, index) => (
                    <Tag data={tagData} key={index} />
                  ))
                  : data.pdf_content_tags.map((tagData, index) => (
                    <Tag data={tagData} key={index} fullLength={true} />
                  ))
                : null}
            </div>  
          </div>
        </div>
      </LightTooltip>
    </div>
  );
}

export default NewsCard;
