import React from "react";
import { Autocomplete, Box, CircularProgress, Modal, TextField, MenuItem } from "@mui/material";
import { useClickOutside } from "@mantine/hooks";

const IPOAlertModal = ({ open, close, setAlert, alert, handleSave, loading, editMode }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%", 
        maxWidth: "450px", 
        bgcolor: "background.paper",
        boxShadow: 24,
        py: 2,
        borderRadius: "20px",
      };

      const ref = useClickOutside(() => close());
    
      return (
        <div className="absolute top-0 left-0  inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center">
          <Box sx={style}>
            <div className="d-flex justify-content-between font-semibold px-4">
              <span className="text-lg font-semibold text-secondaryPurple">
              <p className="font-semibold">{editMode ? "Edit IPO Alert" : "Create IPO Alert"}</p>
              </span>
              <span
                className="d-flex cursor-pointer text-primaryBrown"
                onClick={close}
              >
                {" "}
                X{" "}
              </span>
            </div>
            <hr className="mt-2 mb-6" />
    
            <div className="flex flex-col  gap-4 px-4">
              <TextField
                value={alert?.alert_name}
                onChange={(e) => {
                  setAlert({ ...alert, alert_name: e.target.value });
                }}
                id="outlined-basic"
                label="Alert Name"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    width:"100%",
                    maxWidth: "400px",
                  },
                }}
              />
    
           
    
              <div className="  gap-3 mb-3 flex flex-col ">
                <div className="d-flex flex-column gap-4">
                  <TextField
                    select
                    label="Select Type"
                    placeholder="Select Alert Type"
                    value={alert?.alert_type || "open"}
                    onChange={(e) => {
                      setAlert((prevState) => ({
                        ...prevState,
                        alert_type: e.target.value,
                        symbol: "", // Reset symbol when type changes
                      }));
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        width:"100%",
                        maxWidth: "400px",
                      },
                    }}
                  >
                    <MenuItem value="open">IPO Open</MenuItem>
                    <MenuItem value="listing">Listing</MenuItem>
                   
                  </TextField>
    
                </div>
              </div>
            </div>
    
            <hr></hr>
            <div
              className="d-flex mt-8 align-content-end"
              style={{ width: "100%", right: "0px", justifyContent: "right" }}
            >
              <div className="d-flex gap-3 px-4 text-white">
                <button
                  className="btn rounded-full px-4 text-white bg-primaryBtn hover:bg-primaryHovered"
                  onClick={handleSave}
                >
                  {loading ? <CircularProgress size={15} className="text-white" /> : "Save"}
                </button>
                <button
                  className="btn px-4 btn-outline-dark hover:text-primaryBrown text-primaryBrown rounded-full bg-secondaryBtn hover:bg-secondaryHovered"
                  onClick={close}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </div>
      );
}

export default IPOAlertModal