import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonNewsCards = ({count, height, width}) => {
  return (
    Array.from({ length: count }, (_, index) => (
        <div className={`border border-1 rounded ${width ? `w-[${width}px]` : "w-[350px]" } ${height ? `h-[${height}px]` : "h-[140px]"} mb-2 overflow-hidden flex gap-2`} key={index}>
          <Skeleton variant="square" animation="wave" width={100} height={120} />
          <div className="flex flex-col gap-2 mt-2">
            <Skeleton variant="square" animation="wave" width={200} height={20} />
            <Skeleton variant="square" animation="wave" width={100} height={20} />
            <div className="flex gap-2">
              <Skeleton variant="square" animation="wave" width={100} height={20} />
              <Skeleton variant="square" animation="wave" width={100} height={20} />
            </div>
          </div>
        </div>
      ))
  )
}

export default SkeletonNewsCards