import React from 'react';
import Drawer from '@mui/material/Drawer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Menu = ({ isOpen, onClose,login,logout,googleLogin }) => {
  const menuSections = [
    {
      title: 'My Account',
      items: ['Subscribe', 'My Watchlist', 'Newsletter', 'Notification', 'My Reads', 'For you']
    },
    {
      title: 'Top Sections',
      items: ['Today News', 'Companies', 'Results', 'Mutual Funds', 'Economy', 'Industry', 'Stocks for you']
    },
    {
      title: 'Explore Us',
      items: ['About Us', 'Contact Us', 'Terms of Use', 'Privacy Policy', 'Cookie Policy', 'Disclaimer']
    }
  ];

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 350,
        }
      }}
    >
      <div className="h-full flex flex-col bg-gray-100">
        <div className="py-4 px-3 border-b border-gray-200 flex justify-between items-center bg-black ">
          <h2 className="text-sm font-medium text-white items-center flex space-x-2"><AccountCircleIcon/> <span>Welcome, {login ? JSON.parse(localStorage.getItem("Username")) : 'Guest'}</span></h2>
          <button className='bg-white text-black px-2 py-1 text-sm rounded-md' onClick={login ? logout : googleLogin}> {login ? 'Logout' : 'Login'}</button>
        </div>
        <div className="flex-1 overflow-y-auto">
          {menuSections.map((section, index) => (
            <div key={index} className="mb-2 border-b py-2">
              <div className="flex justify-between items-center px-4 py-1">
                <h3 className="text-sm font-semibold text-gray-600">{section.title} </h3>
                <ArrowForwardIosIcon fontSize="inherit" />
              </div>
              <ul className="mt-1">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="px-4 py-1 hover:bg-gray-50 cursor-pointer">
                    <span className="text-sm text-gray-700">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default Menu;