import React from 'react';

import { useGoogleLogin } from "@react-oauth/google";
import { toast } from 'react-toastify';
import axios from 'axios';
import GoogleIcon from '../Icons/GoogleIcon';

const apiHost = process.env.REACT_APP_BACKEND_URL;

const BeforeGoogleSignin = ({
    generateToken,
    setisLoggedIn
}) => {
    const googleLogin = useGoogleLogin({
        onSuccess: async (response) => {
          try {
            axios
              .post(apiHost + "/auth/google/", {
                access_token: response.access_token,
              })
              .then((res) => {
                if (res.data) {
                  localStorage.setItem(
                    "Token",
                    JSON.stringify(res.data.access_token)
                  );
                  localStorage.setItem(
                    "Username",
                    JSON.stringify(
                      res.data.user.first_name + " " + res.data.user.last_name
                    )
                  );
                  localStorage.removeItem("TempToken");
                  generateToken();
                  return res;
                }
              })
              .then((res) => {
                // window.location.reload(false);
                setisLoggedIn(true);
                // navigate("/");
              })
              .catch((err) => {
                toast.error("Something went wrong");
                console.log(err);
              });
          } catch (error) {
            toast.error("Something went wrong");
            console.log(error);
          }
        },
      });
    

  return (
    <button
      onClick={googleLogin}
      className="flex bg-white gap-2 items-center btn btn-outline-dark rounded-full text-primaryBtn border-primaryBtn"
    >
      <GoogleIcon />
      <div >Login</div>
    </button>
  );
};

export default BeforeGoogleSignin;
