import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CommentIcon from "@mui/icons-material/Comment";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NewsAds from "../../components/NewsPortal/NewsAds";
import axios from "axios";
import doubleDownArrow from "../../assets/svg/doubleDownArrow.svg";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { apiUrl } from "../../utils/utils";
import RelatedNews from "../../components/RelatedNews/RelatedNews";

const NewsContainer = ({ newsTabs, newsData }) => {
  const { newsId } = useParams();
  const [data, setData] = useState([]);
  const [label, setLabel] = useState("Latest News");
  const [newsItems, setNewsItems] = useState([]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [comment, setComment] = useState("");
  const location = useLocation();
  const commentsRef = useRef(null);
  const [isLoggedIn, setisLoggedIn] = useState(Boolean(localStorage.getItem("Token")));
  const [firstnewsData, setfirstNewsData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [expandedNews, setExpandedNews] = useState({});

  const toggleExpandDescription = (index) => {
    setExpandedNews((prevExpandedNews) => ({
      ...prevExpandedNews,
      [index]: true,
    }));
  };

  const getTruncatedText = (text, percentage) => {
    const charLimit = Math.floor((percentage / 100) * text.length);
    return text.slice(0, charLimit);
  };

  useEffect(() => {
    const path = location.pathname;
    const selectedTab = newsTabs.find((tab) => tab.path === path);

    if (selectedTab) {
      setLabel(selectedTab.label);
    } else {
      setLabel("Latest News");
    }
  }, [location.pathname, newsTabs]);

  useEffect(() => {
    if (newsData && label) {
      filterNews();
    }
  }, [newsData, label]);

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
    },
  };

  const filterNews = () => {
    try {
      if (label === "Latest News") {
        setData(newsData);
      } else {
        const filteredData = newsData.filter((news) =>
          news.tags.some(
            (tagArray) =>
              Array.isArray(tagArray) &&
              tagArray[0] &&
              typeof tagArray[0] === "string" &&
              tagArray[0].toLowerCase().includes(label.toLowerCase())
          )
        );
        setData(filteredData);

      }
    } catch (error) {
      console.error("Error filtering news:", error);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const ids = data.slice(0, 3).map((item) => item.id);
      fetchNewsItems(ids);
      fetchNews();
    }
    else {
      setNewsItems([]);
    }
  }, [data]);

  const fetchNewsItems = async (ids) => {
    try {
      const responses = await Promise.all(
        ids.map((id) => axios.get(`https://api.dailystockhunt.com/api/portal-news/${id}`))
      );
      setNewsItems(responses.map((res) => res.data));
    } catch (error) {
      console.error("Error fetching news items:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-IN", options);
  };

  const scrollToComments = () => {
    commentsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleAddComment = async () => {
    try {
      await axios.post(
        `${apiUrl}/api/comment/`,
        {
          id: newsId,
          content_type: "portal-news",
          text: comment,
        },
        config
      );
      setShowCommentInput(false);
      setComment("");
    } catch (error) {
      console.error("Error adding comment", error);
    }
  };

  const handelCommentClick = () => {
    if (!isLoggedIn) {
      toast.error("Please login to comment");
    } else {
      setShowCommentInput(!showCommentInput);
      setComment("");
    }
  };

  const handleShareClick = (platform) => {
    const url = window.location.href;
    const title = newsItems[0]?.title;
    const summary = newsItems[0]?.summary;

    let shareUrl = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${title} ${url}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${title}&url=${url}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank", "noopener,noreferrer");
  };


  const fetchNews = async () => {
    const currentTab = newsTabs.find((tab) => tab.path === location.pathname);
    if (!currentTab) return;

    const tags = currentTab.id;

    try {
      const response = await axios.get(`${apiUrl}/api/portal-news?tags=${tags}`);
      const filteredNews = response.data.results
        .filter((news) => news.id !== newsId)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

      setfirstNewsData(filteredNews);
    } catch (error) {
      console.error("Error fetching related news", error);
    } finally {
      setDataFetched(true);
    }
  };

  return (
    <div className="md:w-[78%] w-full flex flex-col space-y-4">
      <div className="bg-white shadow rounded  min-h-[800px] max-h-[1160px] overflow-y-auto scrollbar-none">
        {newsItems.length > 0 ? (
          newsItems.map((newsItem, index) => (
            <div key={index} className="py-4 px-6">
              <h2 className="text-4xl font-semibold mb-2 text-black font-merriweather">
                {newsItem.title}
              </h2>
              <div>
                <p
                  className="text-md my-8 bg-yellow-100 px-4 py-3 italic border-l-4 border-black font-merriweather"
                  dangerouslySetInnerHTML={{ __html: newsItem.summary }}
                />
                <p className="text-xs text-gray-500 mb-1 font-semibold font-merriweather">
                  {newsItem.author}
                </p>
                <p className="text-xs text-gray-500 mb-4 font-merriweather">
                  {formatDate(newsItem.updated_at)}
                </p>

                <div className="flex justify-between items-center mt-12 mb-2">
                  <div className="flex space-x-3">
                    <button
                      className="flex items-center text-blue-900 text-sm"
                      onClick={scrollToComments}
                    >
                      <CommentIcon /> COMMENTS
                    </button>
                    <div className="flex space-x-3">
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("whatsapp")}
                      >
                        <img src="/whatsapp.png" alt="Share on WhatsApp" className="w-7 h-7" />
                      </button>
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("facebook")}
                      >
                        <img src="/facebook.png" alt="Share on Facebook" className="w-7 h-7" />
                      </button>
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("twitter")}
                      >
                        <img src="/twitter.png" alt="Share on Twitter" className="w-7 h-7" />
                      </button>
                      <button
                        className="flex items-center text-blue-900 text-sm"
                        onClick={() => handleShareClick("linkedin")}
                      >
                        <img src="/linkedin.png" alt="Share on LinkedIn" className="w-7 h-7" />
                      </button>
                    </div>

                  </div>
                  <div>
                    <button className="flex items-center text-blue-900 text-sm">
                      <BookmarksIcon />
                      READ LATER
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full h-[400px] mb-2">
                {newsItem.image ? (
                  <img
                    src={newsItem.image}
                    alt="news"
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-300 rounded-lg"></div>
                )}
              </div>
              {newsItem.image_description && (
                <p className="text-xs text-gray-500 py-2 ">{newsItem.image_description}</p>
              )}

              {/* <p className="prose max-w-full font-roboto_slab">{parse(newsItem.description)}</p> */}
              <div className="prose max-w-full py-2">
                <p className="font-merriweather">
                  {index === 0
                    ? parse(newsItem.description)
                    : (expandedNews[index]
                      ? parse(newsItem.description)
                      : parse(getTruncatedText(newsItem.description, 5))
                    )
                  }
                </p>

                {!expandedNews[index] && index !== 0 && (
                  <div className="flex justify-center">
                    <button
                      onClick={() => toggleExpandDescription(index)}
                      className="text-white bg-orange-500 px-3 py-1 text-lg rounded font-merriweather"
                    >
                      Read more
                    </button>
                  </div>
                )}
              </div>

              <div className="flex justify-between items-center mt-12 mb-2">
                <div className="flex space-x-3">
                  <button
                    className="flex items-center text-gray-500 text-sm"
                    onClick={scrollToComments}
                  >
                    <CommentIcon /> COMMENTS
                  </button>
                  <div className="flex space-x-3">
                    <button
                      className="flex items-center text-blue-900 text-sm"
                      onClick={() => handleShareClick("whatsapp")}
                    >
                      <img src="/whatsapp.png" alt="Share on WhatsApp" className="w-7 h-7" />
                    </button>
                    <button
                      className="flex items-center text-blue-900 text-sm"
                      onClick={() => handleShareClick("facebook")}
                    >
                      <img src="/facebook.png" alt="Share on Facebook" className="w-7 h-7" />
                    </button>
                    <button
                      className="flex items-center text-blue-900 text-sm"
                      onClick={() => handleShareClick("twitter")}
                    >
                      <img src="/twitter.png" alt="Share on Twitter" className="w-7 h-7" />
                    </button>
                    <button
                      className="flex items-center text-blue-900 text-sm"
                      onClick={() => handleShareClick("linkedin")}
                    >
                      <img src="/linkedin.png" alt="Share on LinkedIn" className="w-7 h-7" />
                    </button>
                  </div>
                </div>
              </div>
              <div ref={commentsRef} className="border-y py-4">
                <div className="mb-2 pb-2 border-b flex justify-between">
                  <p className="text-xl font-bold ">Comments</p>
                  <button
                    className="bg-black text-white font-bold py-2 px-4 rounded-lg font-merriweather"
                    onClick={handelCommentClick}
                  >
                    {showCommentInput ? "Close Comment" : "Add Comment"}
                  </button>
                </div>
                {showCommentInput && (
                  <div className="flex flex-col space-y-2 mb-4">
                    <textarea
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className="border border-gray-300 rounded p-2 w-full"
                      placeholder="Add your comment here..."
                    />
                    <button
                      onClick={handleAddComment}
                      className="bg-blue-500 text-white py-2 px-4 rounded"
                    >
                      Submit Comment
                    </button>
                  </div>
                )}

                {index !== newsItems.length - 1 && (
                  <div className="mt-5">
                    <div className="flex items-center">
                      <div className="flex-grow border-t border-black border-2"></div>
                      <h2 className="mx-4 text-center text-gray-700 font-medium text-sm">READ NEXT STORY</h2>
                      <div className="flex-grow border-t border-black border-2"></div>
                    </div>
                    <div className="flex justify-center mt-2 font-bold">
                      <img src={doubleDownArrow} alt="Double Down Arrow" className="h-6 w-6" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col  items-center bg-white z-10 p-10">
            <h2 className="text-2xl font-semibold mb-2 text-black font-merriweather">
              No News Available
            </h2>
            <p className="text-md text-gray-500">
              Please check back later for the latest news.
            </p>
          </div>
        )}
        {newsItems.length > 0 && (
          <>
            <RelatedNews newsData={firstnewsData} newsId={newsId} />
            <div className="flex flex-col md:flex-row w-full gap-4 min-h-[80px]">
              <div className="w-full md:w-[30%]">
                <NewsAds />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsContainer;