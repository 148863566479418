import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

const LineChart = ({ timeLabels, priceData, isNegative }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");

      if (chartContainer.current.chart) {
        chartContainer.current.chart.destroy();
      }

      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      if (isNegative) {
        gradient.addColorStop(0, "rgba(255, 255, 255, 0.4)"); // White with transparency
        gradient.addColorStop(1, "rgba(255, 0, 0, 0.6)"); // Red with transparency for negative changes
      } else {
        gradient.addColorStop(0, "rgba(255, 255, 255, 0.4)"); // White with transparency
        gradient.addColorStop(1, "rgba(144, 238, 144, 0.6)"); // Light green with transparency
      }

      const borderColor = isNegative
        ? "rgba(255, 0, 0, 0.75)"
        : "rgba(53, 113, 58, 0.75)";

      chartContainer.current.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: timeLabels,
          datasets: [
            {
              // label: "Stock Price",
              data: priceData,
              borderColor: borderColor,
              tension: 0.1,
              fill: true,
              backgroundColor: gradient,
              // pointRadius:2,
            },
          ],
        },
        options: {
          elements: {
            point: {
              borderWidth: 0,
              radius: 0,
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
          },
          animation: {
            duration: 0,
          },
maintainAspectRatio: false,
          plugins: {
            tooltip: {
              backgroundColor: "rgba(255, 255, 255, 1)", // White background color
              bodyColor: "#333", // Text color
              borderWidth: 1,
              borderColor: "#ccc",
              displayColors: false,
              padding: 10,
              titleColor: "#333",
              intersect: false,
              mode: "index",
            },

            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 5,
              },
            },
            y: {
              grid: {
                display: true,
              },
              ticks: {
                maxTicksLimit: 6,
              },
            },
          },
          interaction: {
            mode: "nearest",
          },
        },
      });
    }
  }, [priceData]);

  return <canvas ref={chartContainer} />;
};

export default LineChart;
