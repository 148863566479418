import React from "react";

const CollapseArrow = ({fill}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_359_6533)">
        <path
          d="M12.94 4.66667C12.9405 4.75441 12.9237 4.84138 12.8905 4.92261C12.8573 5.00383 12.8085 5.07771 12.7467 5.14L5.88667 12L12.7467 18.86C12.8559 18.9875 12.913 19.1516 12.9065 19.3194C12.9 19.4872 12.8304 19.6463 12.7117 19.765C12.593 19.8838 12.4338 19.9533 12.266 19.9598C12.0983 19.9663 11.9342 19.9092 11.8067 19.8L4 12L11.8067 4.19334C11.9003 4.1016 12.0188 4.03948 12.1475 4.01474C12.2762 3.99001 12.4093 4.00375 12.5303 4.05425C12.6512 4.10476 12.7546 4.18979 12.8274 4.29871C12.9003 4.40763 12.9395 4.53562 12.94 4.66667Z"
          fill={fill ? fill : "#fff"}
        />
        <path
          d="M20.2735 4.66667C20.274 4.75441 20.2572 4.84138 20.224 4.92261C20.1908 5.00383 20.142 5.07771 20.0802 5.14L13.2202 12L20.0802 18.86C20.1894 18.9875 20.2465 19.1516 20.24 19.3194C20.2335 19.4872 20.1639 19.6463 20.0452 19.765C19.9265 19.8838 19.7673 19.9533 19.5995 19.9598C19.4317 19.9663 19.2677 19.9092 19.1402 19.8L11.3335 12L19.1402 4.19334C19.2338 4.1016 19.3523 4.03948 19.481 4.01474C19.6097 3.99001 19.7428 4.00375 19.8638 4.05425C19.9847 4.10476 20.088 4.18979 20.1609 4.29871C20.2338 4.40763 20.273 4.53562 20.2735 4.66667Z"
          fill={fill ? fill : "#fff"}
        />
      </g>
      <defs>
        <clipPath id="clip0_359_6533">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(0 -1 1 0 0 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CollapseArrow;
