import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CustomTable from "./CustomTable";
import { accordBaseURl } from "../utils/utils";

const HistoricalDataTab = ({
  finCode,
  selectedTab,
  excode,
  watchlistToken,
}) => {
  const defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() - 5); // Set defaultFromDate to 5 days ago
  const defaultToDate = new Date();

  const [selectedFromDate, setSelectedFromDate] = useState(
    defaultFromDate.toISOString().split("T")[0]
  );

  const [selectedToDate, setSelectedToDate] = useState(
    defaultToDate.toISOString().split("T")[0]
  );
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false)

  const handleFromDateChange = (event) => {
    setSelectedFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setSelectedToDate(event.target.value);
  };

  useEffect(()=>{
    handleHistoricalData()
  },[finCode])

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleHistoricalData = async () => {
    if (!selectedFromDate || !selectedToDate) {
      toast.error("Please select both 'From' and 'To' dates.");
      return;
    }

    // Validate if the From date is greater than the To date
    const fromDate = new Date(selectedFromDate);
    const toDate = new Date(selectedToDate);
    if (fromDate > toDate) {
      toast.error("'From' date cannot be greater than 'To' date.");
      return;
    }

    try {
      setLoading(true)
      // Fetch historical data including one additional previous day
      const additionalFromDate = new Date(fromDate);
      additionalFromDate.setDate(additionalFromDate.getDate() - 1);
      const additionalToDate = toDate;
      const response = await axios.get(
        `${accordBaseURl}/BSEStock/GetChartData?ExCode=${excode}&Type=H&FINCODE=${finCode}&DateOption=&DateCount=&StartDate=${additionalFromDate.toISOString().split("T")[0]}&EndDate=${additionalToDate.toISOString().split("T")[0]}&token=${watchlistToken}`
      );
      const rawData = response.data?.Table;
  
      // Calculate percentage change
      const extractedData = rawData.map((stock, index) => {
        if (index === 0) {
          // Skip the first entry as it won't have a previous day for percentage change
          return null;
        }
  
        const previousPrice = rawData[index - 1].price;
        const percentageChange = ((stock.price - previousPrice) / previousPrice) * 100;
  
        return {
          date: formatDate(stock.Date),
          price: stock.price,
          open: stock.Open,
          high: stock.high,
          low: stock.low,
          volume: stock.volume,
          percentageChange: isNaN(percentageChange.toFixed(2)) ? 0 : percentageChange.toFixed(2),
        };
      }).filter(Boolean); // Filter out null entries

      setTableData(extractedData.reverse());
      setLoading(false);

    
    } catch (error) {
      setLoading(false)
      console.error("Error fetching historical data:", error);
      toast.error("Unable to fetch historical data at the moment!");
    }
  };

  // console.log(tableData);

  const currentDate = new Date().toISOString().split("T")[0];

  const tableHeader = ["Date", "Price", "Open", "High", "Low", "Volume", "% Change"];

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4 mt-4">
        <div className="flex items-center gap-1">
          <label>From:</label>
          <input
            type="date"
            id="fromDate"
            value={selectedFromDate}
            onChange={handleFromDateChange}
            max={currentDate} // Set max attribute to the current date
            className="border px-2 py-1 rounded-md"
          />
        </div>
        <div className="flex items-center gap-1">
          <label>To:</label>
          <input
            type="date"
            id="toDate"
            value={selectedToDate}
            onChange={handleToDateChange}
            max={currentDate} // Set max attribute to the current date
            className="border px-2 py-1 rounded-md"
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          className="disabled cursor-not-allowed"
          onClick={handleHistoricalData}
        >
          Fetch Data
        </Button>
      </div>
      <div className="mt-3 w-full">
        <CustomTable headers={tableHeader} rowData={tableData} loading={loading}/>
      </div>
    </div>
  );
};

export default HistoricalDataTab;
