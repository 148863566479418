import React, { useState, useEffect, useRef } from "react";
import "../News/News.css";
import NewsCard from "../../components/NewsCard/NewsCard";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import spinner from "../../assets/svg/spinner.gif";
import Next from "../../assets/svg/next.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import Loader from "../../components/Loader/Loader";
import Ads from "../../components/Ads";
import SkeletonNewsCards from "../../components/SkeletonNewsCards";

function Corporate({ lastRunTime, newsTags, corporateCount, sideMenuCollapsed }) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tag = searchParams.get("tag");
  const [news, setNews] = useState([]);
  const [tags, setTags] = useState(newsTags);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [active, setActive] = useState(tag ? tag : "All");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [count, setCount] = useState(null);
  const [slideCount, setSlideCount] = useState(0);

  const isMounted = useRef(false);
  const tagsContainerRef = useRef(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  //calculating the refresh time 1 hour on Saturday and Sunday, 5 minutes otherwise
  const refreshInterval =
    new Date().getDay() === 0 || new Date().getDay() === 6
      ? 60 * 60 * 1000
      : 5 * 60 * 1000;

  useEffect(() => {
    // Clear the stored currentPage value when the component first mounts
    // If the user reloads the app they see the first news page rather than
    // where the user was before
    localStorage.removeItem("currentPage");
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        //Using localStorage to keep a track of the currentPage the user is on
        const pageParam = searchParams.get("page");
        const currentPage = pageParam
          ? parseInt(pageParam)
          : localStorage.getItem("currentPage")
          ? parseInt(localStorage.getItem("currentPage"))
          : 1;
        setPage(currentPage);
        setCurrentPage(currentPage);
        await getCommonNews(tag, currentPage);
      } catch (error) {
        // Handle any errors from getCommonNews
        console.error("Error fetching news:", error);
      }
    };

    // Call fetchNews immediately after the component mounts or when dependencies change
    fetchNews();

    // Set up the interval to fetch news every 5 minutes
    const interval = setInterval(fetchNews, refreshInterval);

    // Clean up the interval when the component unmounts or when dependencies change
    return () => {
      clearInterval(interval);
    };
  }, [tag, location.search]);

  useEffect(() => {
    const tagsContainer = tagsContainerRef.current;

    const updateButtonVisibility = () => {
      if (!tagsContainer) return;

      const buffer = 5; // Small buffer to account for rounding issues
      setShowPrevButton(tagsContainer.scrollLeft > buffer);
      setShowNextButton(tagsContainer.scrollLeft + tagsContainer.clientWidth < tagsContainer.scrollWidth - buffer);
    };

    // Initial check
    updateButtonVisibility();

    // Add event listener for scroll
    tagsContainer.addEventListener('scroll', updateButtonVisibility);

    // Cleanup the event listener on component unmount
    return () => {
      tagsContainer.removeEventListener('scroll', updateButtonVisibility);
    };
  }, [tags]);

  const getCommonNews = async (tag, currentPage = 1) => {
    try {
      setLoading(true);
      const url =
        tag?.length > 0
          ? `/api/nse-news?tag=${tag}&page_size=10&page=${currentPage}`
          : `/api/nse-news?page=${currentPage}&page_size=10`;

      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + url);

      if (response.data) {
        // console.log(response)
        setNews([...response.data.results.news]);
        setTags([...response.data.results.tags_having_news]);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setLoading(false);
        setCount(response.data.count);
        // console.log(response.data.count);
        // Save the current page to localStorage
        localStorage.setItem("currentPage", currentPage);
      } else {
        console.error("Unexpected response:", response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };

  const onClickSearch = (val) => {
    setPage(1);
    const queryParams = new URLSearchParams();
    if (val?.length > 0) {
      queryParams.set("tag", val);
    } else if (search.length > 0) {
      queryParams.set("tag", search);
    }
    setNews([]);
    navigate(`/corporate?${queryParams.toString()}`);
  };

  const removeQueryParams = () => {
    const queryParams = new URLSearchParams();
    setSearch("");
    setActive("All");
    setPage(1);
    queryParams.delete("tag");
    navigate(`/corporate?${queryParams.toString()}`);
    getCommonNews();
  };

  const dateFormat = (lastRunDate) => {
    const date = new Date(lastRunDate);

    // Extract date components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    // Extract time components
    let hour = date.getHours();
    const minute = date.getMinutes();
    const amPm = hour >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    hour = hour % 12 || 12;

    // Format the date and time
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${amPm}`;

    return `${formattedDate}, ${formattedTime}`;
  };

  const openModal = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function XmlDataModal({ data, closeModal }) {
    // console.log(first)
    return (
      <>
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>{`${
            Object.keys(data?.xml_tags).length > 0
              ? data?.xml_tags?.NSESymbol
              : ""
          }`}</DialogTitle>
          <DialogContent>
            {data?.xml_tags_subject ? (
              <div
                dangerouslySetInnerHTML={{ __html: data?.xml_tags_subject }}
              />
            ) : Object.keys(data?.xml_tags).length > 0 ? (
              Object.entries(data?.xml_tags).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong> {value}
                </div>
              ))
            ) : (
              <>
                <div>No Information Available</div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  const handleScrollNext = () => {
    const tagsContainer = tagsContainerRef.current;
    tagsContainer.scrollLeft += 300;
  }

  const handleScrollPrev = () => {
    const tagsContainer = tagsContainerRef.current;
    tagsContainer.scrollLeft -= 300;
  }

  console.log(currentPage)

  return (
    <div className={`w-full mt-16 md:mt-0 md:col-md-9 ${sideMenuCollapsed ? "md:ml-0" : "md:ml-28"}  pt-2 md:pt-0 pl-2 md:pl-0 flex justify-center transition-all duration-500`}>
      <div
        className="news bg-white rounded-xl p-2 px-3 mt-2"
        style={{ width: "100%", minHeight: "500px" }}
      >
        <div className="flex flex-col w-full h-full">
          <div className="flex w-full">
            <div className="w-full">
              <div className="top-row justify-between">
                <div className="d-flex flex-column justify-start">
                  <h5
                    className="font-semibold text-xl text-primaryHovered"
                    onClick={() => {
                      removeQueryParams();
                    }}
                  >
                    Real Time Market News
                  </h5>
                  <span className="date">
                    {lastRunTime ? dateFormat(lastRunTime) : null}
                  </span>
                </div>
              </div>
              {/* Tags  */}
              <div className={`flex justify-between items-center gap-3 transition-all duration-500 ${sideMenuCollapsed ? "w-[69vw]" : 'w-[60vw]'}  `}>
                {showPrevButton && <button className=" flex px-2 py-0 h-6 font-bold text-xl bg-primaryHovered text-white rounded items-center" onClick={handleScrollPrev}>{"<"}</button>}

                {!news || (news.length === 0 && loading) ? (
                  <div className="flex gap-x-1 items-center flex-wrap mt-1 justify-between">
                    {<SkeletonNewsCards count={10} height={97} />}
                  </div>
                ) : (
                  <div className={`newsTags ${sideMenuCollapsed ? "w-[65vw]" : "w-[56vw]"} text-primaryHovered scroll-smooth`} id="tags" ref={tagsContainerRef}>
                    {tags?.length > 0 && (
                      <span
                      style={
                        active == "All"
                        ? {
                          background: "var(--color-primary-hovered)",
                          color: "white",
                          border: "none",
                          }
                          : {}
                          }
                          onClick={() => {
                            removeQueryParams();
                            setActive("All");
                        }}
                        className="text-primaryText px-2 py-1"
                      >
                        All
                      </span>
                    )}
                    {tags?.map((data, index) => {
                      if (data !== "IPO") {
                        return (
                          <span
                            key={index}
                            onClick={(e) => {
                              onClickSearch(data);
                              setActive(data);
                              }}
                              style={
                                active == data
                                ? {
                                  background: "var(--color-primary-hovered)",
                                  color: "white",
                                  border: "none",
                                  }
                                  : {}
                                  }
                                  className="text-primaryText border px-2 py-1"
                                  >
                            {data}
                          </span>
                        );
                        }
                        })}
                  </div>
                )}
                {showNextButton && <button className="flex px-2 py-0 h-6 font-bold text-xl bg-primaryHovered text-white rounded items-center" onClick={handleScrollNext}>{">"}</button>}
              </div>
            </div>
          </div>

          {loading ? (
            <div className="flex gap-0 flex-wrap mt-1 justify-between ">
              {<SkeletonNewsCards count={8} height={97} />}
            </div>
          ) : (
            <div className="page1 z-10 mt-2" style={{ width: "100%" }}>
              {news?.length > 0 ? (
                news?.map((data, idx) => (
                  <NewsCard
                    data={data}
                    key={idx}
                    openModal={openModal}
                    closeModal={closeModal}
                    cardHeight={97}
                    sideMenuCollapsed={sideMenuCollapsed}
                    percentage={true}
                  />
                ))
              ) : (
                <div className="h-full w-full flex items-center justify-center">
                  {" "}
                  No News Available{" "}
                </div>
              )}
            </div>
          )}
        {!loading && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={0}
          >
            {next ? (
              <Pagination
              // variant="outlined"
              
              color="primary"
              count={Math.ceil(count / 10)}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
                setPage(page);
                const url =
                  tag?.length > 0
                    ? `/api/news-feed?tag=${tag}&page_size=10&page=${page}`
                    : `/api/news-feed?page=${page}&page_size=10`;

                getCommonNews(tag, page);
              }}
            />
            ) : null}
          </Stack>
        )}
        </div>
      </div>
      {isModalOpen && <XmlDataModal data={modalData} closeModal={closeModal} />}
      <div className="hidden md:block">
        <Ads />
      </div>
    </div>
  );
}

export default Corporate;
