import React, { useState, useEffect } from "react";
import CustomChip from "./CustomChip";
import { Tabs, Tab, Button } from "@mui/material";
import CustomTable from "./CustomTable";
import CustomSlider from "./CustomSlider";
import { generateToken } from "../utils/generateWatchlistToken";
import axios from "axios";
import { accordBaseURl } from "../utils/utils";

const PAGE_SIZE = 5;
const apiHost = process.env.REACT_APP_BACKEND_URL;
const BuyersAndSellers = ({ groupType }) => {
  const [selectedFilter, setSelectedFilter] = useState("Buyers");
  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Group A");
  const [loadMore, setLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextUrl, setNextUrl] = useState("");

  const tempToken = localStorage.getItem("TempToken");

  const handleFilterClick = (title) => {
    setSelectedFilter(title);
    setLoadMore(false);
    setCurrentPage(1);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setLoadMore(false);
    setCurrentPage(1);
  };

  const fetchData = async () => {
    const excode = "BE49H6S";
    const watchlistToken = tempToken
      ? tempToken
      : JSON.parse(localStorage.getItem("WatchlistToken"));

    // Define mapping for tab values to group values
    const tabToGroupValue = {
      "Group A": "A",
      "Group B": "B",
      "Group M": "M",
      "Group T": "T",
      "Group MT": "MT",
      "Group Z": "Z",
    };

    const tabs = groupType.map((group) => ({
      label: group.INDICES, // Use INDICES as the tab label
      value: `Group ${group.Val}`, // Use Val to set the value for tab selection
    }));

    const groupValue = tabToGroupValue[selectedTab];

    // console.log(loadMore)

    const apiUrl = `${accordBaseURl}/BSEStock/GetOnlyBuyernSellers?ExCode=${excode}&Group=${groupValue}&SectorCode=&IndexCode=&Option=${selectedFilter.charAt(
      0
    )}&Top=&PageNo=${currentPage}&Pagesize=${PAGE_SIZE}&SortExpression=Symbol&SortDirection=Asc&token=${watchlistToken}`;

    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        setTableData(data.Table);
        setTotalPages(data.Table1[0].TotalRows);
        const extractedData = data?.Table?.map((stock) => ({
          company: stock?.S_NAME,
          prevClose: stock?.PrevClose,
          currentPrice: stock?.LastPrice,
          percentageChange: stock?.PerChng,
        }));
        if (loadMore) {
          setRowData((prev) => [...prev, ...extractedData]);
        } else {
          setRowData(extractedData);
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      if (error && localStorage.getItem("Token")) {
        console.log("Came here::", error);
        generateToken(fetchData);
      }
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataAfterMarketHours = async () => {
    try {
      // Define mapping for tab values to group values
      const tabToGroupValue = {
        "Group A": "A",
        "Group B": "B",
        "Group M": "M",
        "Group T": "T",
        "Group MT": "MT",
        "Group Z": "Z",
      };

      const groupValue = tabToGroupValue[selectedTab];
      const extractedUrl = nextUrl?.replace("http://", "https://");
      const apiUrl = loadMore
        ? extractedUrl
        : `${apiHost}/api/only-buyers-sellers?option=${selectedFilter.charAt(
            0
          )}&scrip_group=${groupValue}&ordering=${
            selectedFilter === "Buyers" ? "-per_change" : "per_change"
          }`;

      const response = await axios.get(apiUrl);

      // Check for successful response status (2xx)
      if (response.status >= 200 && response.status < 300) {
        setTableData(response.data?.results);
        setTotalPages(response.data.count);

        const extractedData = response.data?.results?.map((stock) => ({
          company: stock?.s_name,
          prevClose: stock?.prev_close,
          currentPrice: stock?.last_price,
          percentageChange: stock?.per_change,
        }));

        setNextUrl(response.data?.next);

        if (loadMore) {
          setRowData((prev) => [...prev, ...extractedData]);
        } else {
          setRowData(extractedData);
        }
      } else {
        // Handle non-successful response status
        console.error(
          `Error: Received non-successful response status ${response.status}`
        );
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error("An error occurred during the request:", error.message);
    }
  };

  const handlePageChange = () => {
    setCurrentPage((prev) => prev + 1);
    setLoadMore(true);
  };

  useEffect(() => {
    const checkMarketHours = () => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const isMarketHours = currentHour >= 9 && currentHour < 16;

      if (isMarketHours) {
        fetchData();
      } else {
        fetchDataAfterMarketHours();
      }
    };

    checkMarketHours(); // Initial check when the component mounts

    // Check market hours every minute
    const intervalId = setInterval(checkMarketHours, 60000); // 60000 milliseconds = 1 minute

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [selectedFilter, selectedTab, currentPage]);

  const tableHeader = [
    "Company Name",
    "Prev Close(₹)",
    "Current Price(₹)",
    "% Change",
  ];

  return (
    <div className="p-4">
      <div className="flex gap-10 items-center">
        <div>
          <div className="flex gap-2 mt-4 flex-wrap">
            <CustomChip
              title={"Only Buyers"}
              selected={selectedFilter === "Buyers"}
              onClick={() => handleFilterClick("Buyers")}
            />
            <CustomChip
              title={"Only Sellers"}
              selected={selectedFilter === "Sellers"}
              onClick={() => handleFilterClick("Sellers")}
            />
          </div>
        </div>

        {/* <CustomSlider /> */}
      </div>

      {/* Tabs for Groups */}
      <div className="mt-4">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-indicator": {
              height: "4px",
              borderRadius: "15px 15px 0px 0px",
            },
          }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {[
            "Group A",
            "Group B",
            "Group M",
            "Group T",
            "Group MT",
            "Group Z",
          ].map((tabLabel) => (
            <Tab
              key={tabLabel}
              label={tabLabel}
              value={tabLabel}
              sx={{
                fontWeight: selectedTab === tabLabel ? "bold" : "normal",
                padding: "1px",
              }}
            />
          ))}
        </Tabs>

        {/* Table */}
        <CustomTable
          type={selectedFilter === "Buyers" ? "increase" : "decrease"}
          headers={tableHeader}
          rowData={rowData}
        />
      </div>
      <div className="flex justify-center">
        {currentPage < Math.ceil(totalPages / PAGE_SIZE) &&
          nextUrl !== null && (
            <Button variant="contained" onClick={handlePageChange}>
              Load More
            </Button>
          )}
      </div>
    </div>
  );
};

export default BuyersAndSellers;
