import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "./CustomCarousel";
import SkeletonNewsCards from "./SkeletonNewsCards";
const NEWS_INTERVAL = 5
const WatchlistNews = ({ symbol, setMCap }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiHost = process.env.REACT_APP_BACKEND_URL;

  // TODO: Change the api to watchlist news and change the response
  const fetchNews = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        apiHost + `/api/watchlist-newses?symbol=${symbol}&news_intervel=${NEWS_INTERVAL}`,{
          headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
          }
        }
      );
      // console.log(response.data)
      // setMCap(response.data?.results?.market_cap);
      setNewsData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    symbol && fetchNews();
  }, [symbol]);

  if (symbol === "") {
    return (
      <div className="text-center font-semibold">
        Please select a stock to see the relevant news
      </div>
    );
  }
  return (
    <div className="w-full max-w-[800px] h-full">
      {loading ? (
        <></>
        // <div className="flex gap-6 overflow-hidden h-full">
        //   {/* <SkeletonNewsCards width={"205"} height={"142"} count={3} /> */}
        // </div>
      ) : !loading && newsData?.length == 0 ? (
        <div className="font-bold text-center">No Related News Available For the Past {NEWS_INTERVAL} Days</div>
      ) : (
        <Carousel  data={newsData} carouselKey="news-carousel" cardWidth={52} />
      )}
    </div>
  );
};

export default WatchlistNews;
