import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ForwardArrowIcon from "./Icons/ForwardArrowIcon";
import OpenSidebarIcon from "./Icons/OpenSidebarIcon";
import WatchlistIcon from "./Icons/WatchlistIcon";
import LiveNewsIcon from "./Icons/LiveNewsIcon";
import TablesIcon from "./Icons/TablesIcon";
import CorporateIcon from "./Icons/CorporateIcon";
import StockIcon from "./Icons/StockIcon";
import CollapseArrow from "./Icons/CollapseArrow";
import logo from "../../src/assets/image/appLogoLight.webp";
import { ReactComponent as Logo } from "../../src/assets/svg/logoLight.svg";
import { ReactComponent as LogoFull } from "../../src/assets/svg/logoFull.svg";
import MobileNavbar from "./MobileNAvbar";
import { Article, Assignment, GraphicEq, Newspaper, Visibility } from "@mui/icons-material";

const SideMenu = ({ newsNotif, isLoggedIn, corporateNotif, setisLoggedIn, setSideMenuCollapsed }) => {
  const [showIconsOnly, setShowIconsOnly] = useState(false);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const navItems = [
    // {
    //   to: "/watchlist",
    //   text: "Watchlist",
    //   icon: <WatchlistIcon />,
    // },
    {
      to: "/",
      text: "Live News",
      notif: newsNotif,
      icon: <Newspaper className="w-5 h-5"/>,
    },
    {
      to: "/table",
      text: "Reports",
      icon: <TablesIcon />,
    },
    {
      to: "/corporate",
      text: "Corp. News",
      notif: corporateNotif,
      icon: <Assignment fontSize="" className="text-8xl" sx={{
        fontSize: "26px"
      }} />,
    },
    {
      to: "/stock-in-action",
      text: "Stock In Action",
      icon: <StockIcon />,
    },
    {
      to: "/ipo",
      text: "IPO",
      icon: <GraphicEq className="w-5 h-5"/>,
    },
  ];
  useEffect(() => {
    setActiveTab(location.pathname);
    if (location.pathname === "/ipo") {
      setShowIconsOnly(true);
    }
  }, [location.pathname]);

  const handleSidebarChange = () => {
    setShowMobileSidebar(!showMobileSidebar);
    // console.log(showMobileSidebar)
  };

  useEffect(()=>{
    const collapsed = showIconsOnly === true ? true : false
    setSideMenuCollapsed(collapsed )
  },[showIconsOnly])
  
// console.log(activeTab)
  return (
    <>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className=" top-0 items-center p-2 mt-2 ml-3 text-sm  rounded-lg block  md:hidden focus:outline-none focus:ring-2  text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
        onClick={handleSidebarChange}
      >
        {<MobileNavbar isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} />}
        {/* <span className="sr-only">Open sidebar</span>
        <OpenSidebarIcon /> */}
      </button>

      <aside
        id="default-sidebar"
        className="hidden shadow md:block fixed top-0 left-0 z-40  h-screen overflow-x-hidden transition-transform -translate-x-full sm:translate-x-0 "
        aria-label="Sidebar"
      >
        <div
          className={`h-full  py-4 pt-0 overflow-y-auto overflow-x-hidden   text-white bg-white transition-all duration-550 ${
            showIconsOnly ? "w-16" : "w-44"
          }`}
        >
          <ul className="space-y-3">
            <li>
              <NavLink to={isLoggedIn ? "/" : "/news"}>
                <div
                  href="#"
                  className={`flex align-items-center  py-2 px-0 justify-content-center text-base font-normal rounded-lg text-white 
                   
                  `}
                >
                  {showIconsOnly && (
                    <div className="rounded-full overflow-hidden relative h-14 w-14 align-middle bg-white border shadow-md">
                      <Logo
                        width={74}
                        height={72}
                        className="absolute -top-2 -left-2"
                      />
                    </div>
                  )}
                  {!showIconsOnly && (
                    <div
                      className={`flex items-center overflow-hidden pt-3 w-full h-14 ${
                        showIconsOnly ? "whitespace-nowrap" : ""
                      } transition-all duration-550`}
                    >
                      <LogoFull/>
                    </div>
                  )}
                </div>
              </NavLink>
              <div
                className={`flex w-full cursor-pointer pr-1 ${
                  showIconsOnly ? "justify-center" : "justify-end"
                } transition-all duration-550`}
                onClick={() => setShowIconsOnly(!showIconsOnly)}
              >
                {showIconsOnly ? <ForwardArrowIcon fill={"#295FA6"}/> : <CollapseArrow fill={"#295FA6"}/>}
              </div>
            </li>

            <li>
              {/* <NavLink to={isLoggedIn ? "/stock-alerts" : "/"}> */}
              <NavLink to="/stock-alerts">
                <div
                  href="#"
                  className={`flex  items-center p-2 w-full text-base font-normal hover:text-white  text-primaryHovered hover:bg-primaryHovered  ${
                    activeTab === "/stock-alerts" 
                      ? "bg-primaryHovered border-r-4 text-white "
                      : ""
                  } transition-all duration-550`}
                >
                  <span className={`${ showIconsOnly  ? "ml-2" : ""}`}>

                  <Visibility />
                  </span>
                  {!showIconsOnly && <span className="ml-3 font-semibold">Stock Alerts</span>}
                </div>
              </NavLink>
            </li>

            {navItems.map((item, index) => (
              <li key={index}>
                <NavLink exact to={item.to}>
                  <div
                    href="#"
                    className={`flex items-center p-2  text-base font-normal hover:text-white  text-primaryHovered hover:bg-primaryHovered ${
                      activeTab === item.to
                        ? "bg-primaryHovered text-white border-r-4"
                        : ""
                    } transition-all duration-550`}
                  >
                    {showIconsOnly ? (
                     <div className={`ml-2 ${ activeTab === item.to ? " " : ""}`}>{item.icon}</div>
                    ) : (
                      <>
                        <div className={`${ activeTab === item.to ? " " : ""}`}>{item.icon}</div>
                        <span className="flex-1 ml-2 whitespace-nowrap font-semibold ">
                          {item.text}
                          {item.notif && item.notif !== 0
                            ? `(${item.notif})`
                            : null}
                        </span>
                      </>
                    )}
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideMenu;
