import React, { useEffect, useState } from "react";
import Ads from "../../components/Ads";
import IPOMainContainer from "../../components/IPO/IPOMainContainer";

const IPO = ({ isLoggedIn }) => {
  return (
    <div className="md:flex mt-16 md:mt-2   md:pl-0 overflow-auto">
      <IPOMainContainer isLoggedIn={isLoggedIn} />
      <div className="hidden md:block">
        <Ads />
      </div>
    </div>
  );
};

export default IPO;
