import React from "react";

const DownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="13"
      viewBox="0 0 22 13"
      fill="none"
    >
      <path
        d="M2.28493 0.39345L11.0059 9.1144L19.7269 0.39345C20.2475 -0.127244 21.0918 -0.127244 21.6125 0.39345C22.1331 0.914156 22.1331 1.75837 21.6125 2.27906L11.9487 11.9428C11.6986 12.1929 11.3595 12.3333 11.0059 12.3333C10.6523 12.3333 10.3131 12.1929 10.0631 11.9428L0.399312 2.27906C0.334219 2.21398 0.277273 2.14384 0.228459 2.0699C-0.113247 1.55237 -0.0563012 0.849063 0.399312 0.39345C0.920006 -0.127244 1.76423 -0.127244 2.28493 0.39345Z"
        fill="#6C4E62"
      />
    </svg>
  );
};

export default DownArrow;
