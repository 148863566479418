import { Button, Skeleton, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { formatDate } from "../utils/utils";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";

const AccordBseUrl = process.env.REACT_APP_ACCORD_BSE;

const BseAnnouncements = ({ finCode, token }) => {

  const defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() - 5); // Set defaultFromDate to 5 days ago
  const defaultToDate = new Date();

  const [bseAnnouncement, setBseAnnouncement] = useState([]);
  const [fromDate, setFromDate] = useState(
    defaultFromDate.toISOString().split("T")[0]
  );
  const [toDate, setToDate] = useState(
    defaultToDate.toISOString().split("T")[0]
  );
  const [totalRows, setTotalRows] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    fetchBseAnnouncements()
  },[finCode])

  const fetchBseAnnouncements = async () => {
    if (!fromDate || !toDate) {
      toast.error("Please select both 'From' and 'To' dates.");
      return;
    }
    if (fromDate > toDate) {
      toast.error("'From' date cannot be greater than 'To' date.");
      return;
    }
    
      try {
        setLoading(true)
        const response = await axios.get(
          `https://corpaction.accordwebservices.com/Bulletin/GetBseAnnouncementsNew?FINCODE=${finCode}&Top=&PageNo=1&Pagesize=10&SortExpression=&SortDirection=&FromDate=${fromDate}&ToDate=${toDate}&token=${token}`
        );

        // Set the fetched data to state if needed
        setTotalRows(response.data.Table1[0].TotalRows);
        const extractedData = response.data?.Table?.map((stock) => ({
          s_name: stock?.s_name,
          date: formatDate(stock?.Newsdate),
          heading: stock?.Heading,
          details: stock?.Details,
        }));

        setBseAnnouncement(extractedData);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Error fetching BSE announcements:", error);
      }
    } 

  const tableHeader = ["Name", "Date", "Heading", "Details"];

  return (
    <div className="mt-3">
      <div className="flex gap-4 flex-wrap">
        <div>
          <label>From: </label>
          <input
            type="date"
            className="border px-2 py-1 rounded-md"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div>
          <label>To: </label>
          <input
            type="date"
            className="border px-2 py-1 rounded-md"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <Button variant="contained" onClick={fetchBseAnnouncements}>
          Fetch Data
        </Button>
      </div>
      <div className="mt-3 overflow-auto">
        <table className="table">
          <thead className="thead-dark">
            <tr className="bg-secondaryHovered">
              {tableHeader.map((title, index) => (
                <th key={index} className="header" scope="col">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bseAnnouncement.length === 0 || loading ? (
               loading ? (
                Array.from({ length: 4 }, (_, rowIndex) => (
                  <tr key={rowIndex}>
                    {tableHeader.map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          height={20}
                          width={70}
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeader.length} className="text-center">
                    No data available
                  </td>
                </tr>
              )
            ) : (
              bseAnnouncement.map((row, index) => (
                <tr
                key={index}
                className={`${
                  index % 2 !== 0 ? "bg-slate-100" : "bg-white"
                } font-medium`}
              >
                {Object.keys(row).map((key, columnIndex) => (
                  <td key={key} className={"text-black"}>
                    {key === "details" ? (
                      <Tooltip title={row[key]} arrow>
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "250px" }}>
                          {row[key]}
                        </div>
                      </Tooltip>
                    ) : (
                      row[key]
                    )}
                  </td>
                ))}
              </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BseAnnouncements;
