import React from 'react';

const CustomSearch = ({ setSearch, search, onClickSearch, placeholder }) => {
  return (
    <div className="search d-flex">
      <div className=" relative flex items-center">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="py-2 px-3 pr-12 rounded-pill  outline-none border border-black"
          placeholder={placeholder || "Search"}
          aria-label="Search"
          aria-describedby="search-addon"
        />
        <i
          className="fa fa-search absolute right-4  cursor-pointer"
          // onClick={onClickSearch}
        ></i>
      </div>
    </div>
  );
};

export default CustomSearch;
