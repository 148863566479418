import { CancelOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import React from "react";

const DeleteModal = ({
  showConfirmation,
  setShowConfirmation,
  removeSingleAlert,
  data,
  handleDelete,
  forWatchlist,
}) => {
  return (
    <Modal
      open={showConfirmation}
      onClose={() => setShowConfirmation(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="bg-white h-[200px] w-[400px] rounded-xl flex flex-col">
        <div className="flex justify-between items-center p-2 px-3 py-2">
          <span className="text-secondaryPurple font-semibold px-2">Delete</span>
          <div onClick={() => setShowConfirmation(false)} className="h-10 pt-2  cursor-pointer">
          X
          </div>
        </div>
        <hr></hr>
        <div className="flex items-center justify-center p-4">
          Are you sure you want to delete this?
        </div>
        <hr></hr>
        <div className="flex justify-end gap-2 pr-4 py-4 items-center">
          <button
            className="btn rounded-full bg-secondaryBtn hover:bg-secondaryHovered  px-4 border border-b-2"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </button>
          <button
            className="btn rounded-full bg-error hover:bg-errorHovered text-white px-4  "
            onClick={
              forWatchlist
                ? () => {
                    handleDelete()
                    setShowConfirmation(false)
                  }
                : () => {
                    setShowConfirmation(false);
                    removeSingleAlert(data.id);
                  }
            }
          >
            Yes! Delete it
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
