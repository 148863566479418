import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const tempToken = localStorage.getItem("TempToken");

function NewsTicker() {
  const [newsItems, setNewsItems] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const watchlistToken = tempToken
        ? tempToken
        : JSON.parse(localStorage.getItem("WatchlistToken"));
      const groups = ["A", "B"];
      const selectedFilter = "Daily";
      const PAGE_SIZE = 5;

      const fetchGainersOrLosers = async (group, type) => {
        const apiUrl = `https://stock.accordwebservices.com/BSEStock/GetGainersnLosers?ExCode=BE49H6S&Group=${group}&Indices=&Type=${type}&Option=&Period=${selectedFilter.toUpperCase()}&PageNo=${1}&Pagesize=${PAGE_SIZE}&SortExpression=&SortDirect=&token=${watchlistToken}`;
        const response = await fetch(apiUrl);
        if (response.ok) {
          const data = await response.json();
          return data.Table.map((stock) => ({
            title: stock.S_NAME,
            value: stock.CLOSE_PRICE,
            change: stock.PERCHG,
            direction: type === "GAIN" ? 'up' : 'down',
          }));
        } else {
          throw new Error(`Failed to fetch ${type} for group ${group}`);
        }
      };

      const allNewsItems = await Promise.all(
        groups.flatMap(group => [
          fetchGainersOrLosers(group, "GAIN"),
          fetchGainersOrLosers(group, "LOSS"),
        ])
      );
      setNewsItems(allNewsItems.flat());
      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="bg-black text-white w-full overflow-hidden py-2 text-xs font-semibold">
      {dataFetched ? (
        <Marquee
          gradient={false}
          speed={50}
        >
          {newsItems.map((item, index) => (
            <div key={index} className="flex items-center ml-4 whitespace-nowrap">
              <span>{item.title} {item.value}</span>
              <span className={`ml-2 ${item.direction === 'up' ? 'text-green-500' : 'text-red-500'}`}>{item.change}</span>
              <span className={`ml-2 ${item.direction === 'up' ? 'text-green-500' : 'text-red-500'}`}>
                {item.direction === 'up' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
              </span>
            </div>
          ))}
        </Marquee>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default NewsTicker;
