import React, { useEffect, useState } from "react";
import axios from "axios";

const AboutUs = () => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    // Fetch data from the API
    const fetchAboutUs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/about-us`);
        setDescription(response.data.description);
      } catch (error) {
        console.error("Error fetching about us data:", error);
      }
    };

    fetchAboutUs();
  }, []);

  return (
    <div className="table-col mx-28 mt-10">
      <h1 className="text-black font-bold text-3xl text-center">About Us</h1>
      <div
        className="text-black text-lg max-w-full prose lg:prose-lg"
        dangerouslySetInnerHTML={{
          __html: description || "Loading about information...",
        }}
      />
    </div>
  );
};

export default AboutUs;
