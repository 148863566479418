import React from "react";

const TagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M15.8177 24.1593C15.5929 24.3818 15.3148 24.493 14.9835 24.493C14.6522 24.493 14.3753 24.3818 14.1528 24.1593L3.88617 13.8927C3.76172 13.7682 3.66644 13.6321 3.60033 13.4843C3.53422 13.3366 3.50078 13.1779 3.5 13.0083V4.66667C3.5 4.354 3.61628 4.08139 3.84883 3.84883C4.08217 3.61628 4.35478 3.5 4.66667 3.5H13.0083C13.1592 3.5 13.3093 3.53111 13.4587 3.59333C13.608 3.654 13.7375 3.73644 13.8472 3.84067L24.1138 14.1143C24.3518 14.3523 24.4747 14.6401 24.4825 14.9777C24.4903 15.3144 24.3826 15.5944 24.1593 15.8177L15.8177 24.1593ZM14.9753 23.3333L23.3263 14.9917L13.02 4.66667H4.66667V13.0083L14.9753 23.3333ZM7.581 8.75C7.90378 8.75 8.1795 8.63683 8.40817 8.4105C8.63606 8.18417 8.75 7.90922 8.75 7.58567C8.75 7.26289 8.63683 6.98717 8.4105 6.7585C8.18417 6.53061 7.90922 6.41667 7.58567 6.41667C7.26289 6.41667 6.98717 6.52983 6.7585 6.75617C6.53061 6.9825 6.41667 7.25744 6.41667 7.581C6.41667 7.90378 6.52983 8.1795 6.75617 8.40817C6.9825 8.63606 7.25744 8.75 7.581 8.75Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWdth="0.2"
      />
    </svg>
  );
};

export default TagIcon;
