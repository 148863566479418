import * as React from 'react';
import Box from '@mui/material/Box';
import './DialogBox.css'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { Table } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  p: 4,
  pt: 2,
  pr: 2
};

export default function DialogBox({ open, close, data, loading }) {
  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedDate + ' ' + formattedTime
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='d-flex flex-column'>
          <div className="close d-flex align-items-right justify-content-end mb-2" style={{ width: '100%' }} onClick={() => { close() }}>
            <CloseIcon sx={{ cursor: 'pointer' }} />
          </div>
          <Table className='watchlist-table'>
            <tr>
              <th>Stock</th>
              <th>Status</th>
              <th>Triggered On</th>
            </tr>

            {loading ?
              <tr className="loading">
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <CircularProgress className="spinner" sx={{ border: 'none' }} />
                </Box>
              </tr> : data ? Object.entries(data).filter(([key, value]) => value.status === 1).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>Hit</td>
                  <td>{formatDateTime(value?.triggered_on)}</td>
                </tr>
              )) : null}
          </Table>
        </Box>
      </Modal>
    </div>
  );
}