import axios from "axios";

export const generateToken = (callbackFn) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));

    axios
      .get( process.env.REACT_APP_BACKEND_URL + "/api/get-client-token", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((response) => {
        const newToken = response.data.token;
        localStorage.setItem("WatchlistToken", JSON.stringify(newToken));
        if(callbackFn){

          callbackFn();
        }
      })
      .catch((error) => {
        console.error("Error generating token:", error);
      });
  };