import React, { useEffect, useState, useRef } from "react";
import "./TableRow.css";
import CancelIcon from "../Icons/CancelIcon";
import GreenUpArrow from "../Icons/GreenUpArrow";
import RedDownArrow from "../Icons/RedDownArrow";
import DeleteModal from "../DeleteModal";
import WatchlistNews from "../WatchlistNews";
import { Badge } from "@mui/material";

export default function TableRows({ symbol,counter, rowData, handleDelete, setSelectedSymbol, selectedSymbol }) {
  const [highlight, setHighlight] = useState(false);
  const [dayLow, setDayLow] = useState(false);
  const [dayHigh, setDayHigh] = useState(false);
  const [ltpInc, setLtpInc] = useState(false);
  const [dayLowInc, setDayLowInc] = useState(false);
  const [dayHighInc, setDayHighInc] = useState(false);
  const renderCount = useRef(0);
  const prevLtpRef = useRef(null);
  const prevLow = useRef(null);
  const prevHigh = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // console.log(counter.counter)

  useEffect(() => {
    const currentLtp = rowData?.ltp;
    const currentLow = rowData?.day_low;
    const currentHigh = rowData?.day_high;

    // Skip the initial render and the second render
    if (renderCount.current < 4) {
      renderCount.current += 1;
      prevLtpRef.current = currentLtp;
      prevLow.current = currentLow;
      prevHigh.current = currentHigh;
      return;
    }

    if (
      currentLtp !== undefined &&
      currentLtp !== null &&
      currentLtp !== prevLtpRef.current
    ) {
      setHighlight(true);
      if (currentLtp > prevLtpRef.current) {
        setLtpInc(true);
      }
      setTimeout(() => {
        setHighlight(false);
        setLtpInc(false);
      }, 3000);
    }
    if (
      currentLow !== undefined &&
      currentLow !== null &&
      currentLow !== prevLow.current
    ) {
      setDayLow(true);
      if (currentLow > prevLow.current) {
        setDayLowInc(true);
      }
      setTimeout(() => {
        setDayLow(false);
        setDayLowInc(false);
      }, 3000);
    }
    if (
      currentHigh !== undefined &&
      currentHigh !== null &&
      currentHigh !== prevHigh.current
    ) {
      setDayHigh(true);
      if (currentHigh > prevHigh.current) {
        setDayHighInc(true);
      }
      setTimeout(() => {
        setDayHigh(false);
        setDayHighInc(false);
      }, 3000);
    }

    prevLtpRef.current = currentLtp;
    prevLow.current = currentLow;
    prevHigh.current = currentHigh;
  }, [rowData?.ltp]);
  // cff3e3


  const colSpan = 7;

  return (
    <>
      <tbody
        className={`tbody cursor-pointer hover:bg-white rounded-lg ${
          selectedSymbol === symbol && "bg-white"
        }`}
        id="table-body"
      >
        <tr onClick={() => setSelectedSymbol(symbol)} className="rounded">
          <td
            id="symbol"
            style={{
              height: "47.5px",
            }}
          >
            <img
              src={window.location.origin + "/assets/india.png"}
              alt="flag"
            />
            {rowData?.symbol}{" "}
            {counter?.counter > 0 && (
              <span className="text-white w-5 h-5 text-center rounded-full bg-red-600">
                {counter?.counter}
              </span>
            )}
          </td>
          <td>
            <span
              className={`${
                highlight ? (ltpInc ? "text-success" : "text-error") : {}
              } font-semibold flex items-center gap-1`}
            >
              {rowData?.ltp ? rowData.ltp : "-"}
              <span>
                {highlight ? ltpInc ? <GreenUpArrow /> : <RedDownArrow /> : ""}
              </span>
            </span>
          </td>

          <td>
            <span
              className={`${
                dayLow ? (dayLowInc ? "text-success" : "text-error") : {}
              } font-semibold flex items-center gap-1`}
            >
              {rowData?.day_high ? rowData.day_high : "-"}
              <span>
                {dayLow ? dayLowInc ? <GreenUpArrow /> : <RedDownArrow /> : ""}
              </span>
            </span>
          </td>
          <td>
            <span
              className={`${
                dayHigh ? (dayHighInc ? "text-success" : "text-error") : {}
              } font-semibold flex items-center gap-1`}
            >
              {rowData?.day_low ? rowData.day_low : "-"}
              <span>
                {dayHigh ? (
                  dayHighInc ? (
                    <GreenUpArrow />
                  ) : (
                    <RedDownArrow />
                  )
                ) : (
                  ""
                )}
              </span>
            </span>
          </td>
          <td
            style={
              rowData?.change > 0
                ? { color: "#0ea600", fontWeight: "500" }
                : { color: "red", fontWeight: "500" }
            }
          >
            {rowData?.change !== undefined ? (
              rowData.change >= 0 ? (
                <span style={{ color: "#0ea600", fontWeight: "500" }}>
                  &nbsp;&nbsp;{rowData.change}
                </span> // add space before positive value
              ) : (
                <span style={{ color: "red", fontWeight: "500" }}>
                  {" "}
                  {rowData.change}
                </span>
              )
            ) : (
              "-"
            )}
          </td>
          <td>{rowData?.volume}</td>
          <td>
            <DeleteModal
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              forWatchlist
              handleDelete={handleDelete.bind(this, symbol)}
            />
            <span onClick={(e) => setShowConfirmation(true)}>
              <CancelIcon />
            </span>
          </td>
        </tr>
        {/* {selectedSymbol === symbol && (
          <>
           
            <tr>
              <td ></td>

              <td className="overflow-x-auto" colSpan={colSpan}>
                <WatchlistNews symbol={selectedSymbol}/>
              </td>
            </tr>
          </>
        )} */}
      </tbody>
    </>
  );
}
