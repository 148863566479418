import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Skeleton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import { ASMFullForm, formatDate } from "../../utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
 borderRadius: 2
};

const ASMModal = ({ handleClose, open, DealName, rowData, loading }) => {

  // console.log(rowData)

  const tableHeader = ["Exchange","Stage", "Long/Small Term","Entry Date", "Exit Date" ];

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {DealName}
            </Typography>
            <div className=" pl-0 table-content mt-3">
              <Table className="table ">
                <TableHead className="bg-primaryHovered">
                  <TableRow className=" ">
                    {tableHeader.map((heading, index) => (
                      <TableCell className="text-white !font-semibold" key={index}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <tbody>
                  {loading ? (
                    <>
                      {Array.from({ length: 4 }, (_, rowIndex) => (
                        <tr key={rowIndex}>
                          {tableHeader?.map((_, colIndex) => (
                            <td key={colIndex}>
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={20}
                                width={50}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ) : (
                    rowData?.map((row, index) => (
                      <tr
                        key={index}
                        className={`text-primaryText ${
                          index % 2 === 0 && "bg-reportsTableHeading"
                        }`}
                      >
                        <td>{(row?.asm_deal.deal_type).toUpperCase()}</td>
                        <td>{"Stage "+(row?.stage).toUpperCase()}</td>
                        <td>{ASMFullForm(row?.asm_type)}</td>
                        <td>{row?.entry_date ? formatDate(row?.entry_date) : "Active"}</td>
                        <td>{row?.exit_date ? formatDate(row?.exit_date) : "Active"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ASMModal;
