import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const accordBaseURl = "https://stock.accordwebservices.com";

export const apiUrl = process.env.REACT_APP_BACKEND_URL

export const excode = "BE49H6S";

// Function to format the date

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { month: "short", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

//Fucntion for searchbars to fucntion
export function search(data, searchTerm) {
  if (!searchTerm || searchTerm.trim() === "") {
    // If the searchTerm is empty or only contains whitespace, return the original data
    return data;
  }

  // Convert the searchTerm to lowercase for case-insensitive search
  const lowercaseSearchTerm = searchTerm.toLowerCase();

  // Use filter to find objects in the data array that match the search criteria
  const filteredData = data.filter((item) => {
    // Check each property of the object for a match with the search term
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const propertyValue = String(item[key]).toLowerCase();
        if (propertyValue.includes(lowercaseSearchTerm)) {
          return true; // If a match is found, include the item in the filtered data
        }
      }
    }
    return false; // If no match is found in any property, exclude the item from the filtered data
  });

  return filteredData;
}

export const truncateText = (text, maxLength) => {
  if (text && text.split(" ").length > maxLength) {
    const words = text.split(" ");
    return words.slice(0, maxLength).join(" ") + "...";
  }
  return text;
};

export const ScrollToTop = () => { //To push the page to the top when the pathname changes
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
};

 export const ASMFullForm = (input) => {
   let text = "";
  text = input === "ltasm"
     ? ( "Long Term ASM")
     : input === "stasm"
     ? ( "Short Term ASM")
     : input?.toUpperCase();

   return text;
 };

