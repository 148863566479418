import React, { useState } from "react";
import Loader from "../../components/Loader/Loader";
import DownArrow from "../../components/Icons/DownArrow";
import { FormControl, FormControlLabel, Skeleton, Switch, ToggleButton } from "@mui/material";

const DealsTable = ({
  title,
  expand,
  setExpand,
  loading,
  csvData,
  dealsTableHeader,
  searchTableFunction,
  searchField,
  Minimize,
  Maximize,
  spinner,
  isSearchBar,
  handleToggle,
  selectedToggle,
  isToggle,
  rowData
}) => {
 console.log(csvData)
  return (
    <div className="table-row   pt-2 rounded-xl">
      <div className="bulk-deals d-flex flex-column mt-3">
        <div className="title">
          <h1 className="text-primaryBrown ">{title}</h1>
          {isToggle && (
            <div className="flex gap-3 items-center mt-1">
              <h2 className="font-semibold text-sm text-primaryHovered">NSE</h2>
            <FormControlLabel
               control={
                <Switch sx={{
                  "& .css-jsexje-MuiSwitch-thumb" : {
                    backgroundColor: "primary.main"
                  }
                }} onChange={handleToggle} checked={selectedToggle} />
              }
              label="BSE"
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: 'primary.main', 
                },
              }}
              />
              </div>
          )}
          <div className="title-right">
            <div
              className={`cursor-pointer transform transition-transform duration-300 h-4 ${
                expand ? "rotate-180" : "rotate-0"
              }`}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              <DownArrow />
            </div>
          </div>
        </div>

        <div
          className="table-content"
          style={expand ? { height: "auto" } : { maxHeight: "200px" }}
        >
          <table>
            <thead className="!bg-primaryHovered text-white font-bold">
              <tr>
                {dealsTableHeader.map((title, index) => (
                  <th className="font-semibold" key={index}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <>
                  {Array.from({ length: 4 }, (_, rowIndex) => (
                    <tr key={rowIndex}>
                      {dealsTableHeader.map((_, colIndex) => (
                        <td key={colIndex}>
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={20}
                            width={100}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
              csvData ? csvData?.map((data, index) => (
                  <tr
                    key={index}
                    style={
                      index % 2 ? { backgroundColor: "rgb(246,246,246)" } : {}
                    }
                    className="text-primaryWhite"
                  >
                    <td>{data?.date}</td>
                    <td>{data?.symbol}</td>
                    <td>{data?.security_name}</td>
                    <td>{data?.client_name}</td>
                    <td
                    // style={
                    //   data?.buy_sell == "BUY"
                    //     ? { color: "rgb(126,169,142)" }
                    //     : { color: "rgb(157,110,113)" }
                    // }
                    >
                      <p
                        className={`${
                          data?.buy_sell === "BUY"
                            ? "text-success"
                            : "text-error"
                        } font-semibold `}
                      >
                        {data?.buy_sell}
                      </p>
                    </td>
                    <td>{data?.quantity_traded}</td>
                    <td>{data?.trade_price}</td>
                  </tr>
                ))
                :
                (
                  rowData?.map((row, index) => (
                    <tr key={index} style={
                      index % 2 ? { backgroundColor: "rgb(246,246,246)" } : {}
                    }
                    className="text-primaryWhite">
                      {Object.keys(row).map((key, columnIndex) => (
                        <td
                          key={key}
                          className={
                            "text-black" // Default to black if type is not available
                                
                             
                          }
                        >
                         { row[key]}
                        </td>
                      ))}
                    </tr>
                  ))
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DealsTable;
