import React from "react";

const Bell = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_359_6526)">
        <path
          d="M15.0002 18V18.75C15.0002 19.5457 14.6842 20.3087 14.1216 20.8713C13.5589 21.4339 12.7959 21.75 12.0002 21.75C11.2046 21.75 10.4415 21.4339 9.87891 20.8713C9.3163 20.3087 9.00023 19.5457 9.00023 18.75V18M20.0477 16.4733C18.844 15 17.9941 14.25 17.9941 10.1883C17.9941 6.46876 16.0948 5.1436 14.5315 4.50001C14.3238 4.4147 14.1284 4.21876 14.0651 4.00548C13.7909 3.0722 13.0221 2.25001 12.0002 2.25001C10.9784 2.25001 10.2091 3.07266 9.93773 4.00641C9.87445 4.22204 9.67898 4.4147 9.47133 4.50001C7.90617 5.14454 6.00867 6.46501 6.00867 10.1883C6.00633 14.25 5.15648 15 3.95273 16.4733C3.45398 17.0836 3.89086 18 4.7632 18H19.242C20.1096 18 20.5437 17.0808 20.0477 16.4733Z"
          stroke="currentColor"
          strokeWidth="1.49003"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
       
      </g>
    </svg>
  );
};

export default Bell;
