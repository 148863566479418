import React from 'react';

function Ads({ width, height }) {
  return (
  
      <span ></span>
    
  );
}

export default Ads;
