import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "./CustomCarousel";
import Loader from "./Loader/Loader";
import SkeletonNewsCards from "./SkeletonNewsCards";

const HistoricDataNewsTab = ({ symbol, setMCap }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log(symbol)

  const apiHost = process.env.REACT_APP_BACKEND_URL;

  const fetchNews = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        apiHost + `/api/news-feed?tag=${symbol}`
      );
      setMCap(response.data?.results?.market_cap);
      setNewsData(response.data?.results?.news);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [symbol]);

  return (
    <div className="mt-3 max-w-[740px]">
      {loading && 
      <div className="flex gap-6 overflow-hidden">
      <SkeletonNewsCards width={"205"}  height={180} count={3}/>
      </div>
      }
      {!loading && newsData.length == 0 ? (
        <div>No Related News Available</div>
      ) : (
        <Carousel data={newsData} carouselKey="news-carousel" />
      )}
    </div>
  );
};

export default HistoricDataNewsTab;
