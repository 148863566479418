import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_359_5147)">
        <path
          d="M23.9878 12.2245C23.9878 11.2413 23.9061 10.5238 23.7294 9.77966H12.2383V14.2176H18.9833C18.8474 15.3205 18.113 16.9815 16.4811 18.0976L16.4583 18.2462L20.0915 20.9964L20.3433 21.0209C22.655 18.9347 23.9878 15.8653 23.9878 12.2245Z"
          fill="#4285F4"
        />
        <path
          d="M12.2383 23.9176C15.5428 23.9176 18.3169 22.8545 20.3433 21.0209L16.4811 18.0976C15.4476 18.8018 14.0605 19.2934 12.2383 19.2934C9.00175 19.2934 6.25478 17.2074 5.27556 14.324L5.13203 14.3359L1.35409 17.1927L1.30469 17.3269C3.31731 21.2334 7.45141 23.9176 12.2383 23.9176Z"
          fill="#34A853"
        />
        <path
          d="M5.27634 14.324C5.01797 13.5799 4.86844 12.7826 4.86844 11.9588C4.86844 11.1349 5.01797 10.3377 5.26275 9.5936L5.25591 9.43513L1.43062 6.53241L1.30547 6.59058C0.475969 8.21168 0 10.0321 0 11.9588C0 13.8855 0.475969 15.7058 1.30547 17.3269L5.27634 14.324Z"
          fill="#FBBC05"
        />
        <path
          d="M12.2383 4.62403C14.5365 4.62403 16.0867 5.59402 16.9707 6.40461L20.4248 3.10928C18.3034 1.1826 15.5428 0 12.2383 0C7.45141 0 3.31731 2.68406 1.30469 6.59056L5.26197 9.59359C6.25478 6.7102 9.00175 4.62403 12.2383 4.62403Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_359_5147">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
