import React, { useEffect, useState } from "react";
import axios from "axios";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [showFaq, setShowFaq] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFaq(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []); 

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/faq`,
          {
            params: {
              faq_type: "asm/gsm",
            },
          }
        );
        console.log("FAQs:", response.data);
        setFaqs(response.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFAQs();
  }, []);

  return (
    <>
      {showFaq && (
        <section>
          <div
            style={{
              display: "block",
            }}
            className="w-full table-row flex-col  pt-2 rounded-xl mt-4"
          >
            <div className="mx-auto px-8">
              <div className="flex flex-col items-center">
                <h2 className="mt-3 text-center text-3xl font-extralight tracking-tight md:text-5xl">
                  FAQ
                </h2>
              </div>
              <div className="mx-auto mt-5 grid max-full mb-5">
                {faqs.length > 0 ? (
                  faqs.map((faq, index) => (
                    <div
                      key={faq.id}
                      className={`py-2 ${
                        index === faqs.length - 1 ? "pb-0" : ""
                      }`}
                    >
                      <details className="group">
                        <summary className="flex cursor-pointer list-none items-center justify-between font-medium bg-gray-100 p-3 rounded-sm border-b-2 border-gray-500">
                          <span className="font-bold text-sm">
                            {faq.question}
                          </span>
                          <span className="transition group-open:rotate-180 text-black">
                            <svg
                              fill="none"
                              height="24"
                              shapeRendering="geometricPrecision"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.5"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div
                          className="group-open:animate-fadeIn mt-3 text-neutral-600 px-3 py-4 prose lg:prose-sm max-w-full"
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                      </details>
                    </div>
                  ))
                ) : (
                  <p>No FAQs available.</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Faq;
