import React from "react";

const ArrowUpandDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M8.74935 20.4166V7.58331M8.74935 7.58331L12.8327 11.6666M8.74935 7.58331L4.66602 11.6666M19.2493 7.58331V20.4166M19.2493 20.4166L23.3327 16.3333M19.2493 20.4166L15.166 16.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpandDownIcon;
