import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import StockIcon from "./Icons/StockIcon";
import CorporateIcon from "./Icons/CorporateIcon";
import TablesIcon from "./Icons/TablesIcon";
import LiveNewsIcon from "./Icons/LiveNewsIcon";
import { NavLink, useLocation } from "react-router-dom";
import BeforeGoogleSignin from "./GoogleLogin/BeforeGoogleSignin";
import { generateToken } from "../utils/generateWatchlistToken";
import AfterGoogleSignin from "./GoogleLogin/AfterGoogleSignin";
import WatchlistIcon from "./Icons/WatchlistIcon";
import { ReactComponent as LogoFull } from "../../src/assets/svg/logoFull.svg";
import { ReactComponent as Logo } from "../../src/assets/svg/logoLight.svg";
import { Article, GraphicEq, Newspaper } from "@mui/icons-material";

const drawerWidth = 240;

function MobileNavbar(props) {
  const { window, isLoggedIn, setisLoggedIn } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState(location.pathname);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navItems = [
    {
      to: "/watchlist",
      text: "Watchlist",
      icon: <WatchlistIcon />,
    },
    {
      to: "/news",
      text: "Live News",
      //   notif: newsNotif,
      icon: <Newspaper />,
    },
    {
      to: "/table",
      text: "Reports",
      icon: <TablesIcon />,
    },
    {
      to: "/corporate",
      text: "Corporate News",
      //   notif: corporateNotif,
      icon: <Article />,
    },
    {
      to: "/stock-in-action",
      text: "Stock In Action",
      icon: <StockIcon />,
    },
    {
      to: "/ipo",
      text: "IPO",
      icon: <GraphicEq />,
    },
  ];

  React.useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", width: "40px" }}>
      {/* <CssBaseline /> */}
      <AppBar
        component="nav"
        style={{
          width: "40px",
          left: "0",
          padding: "4px 4px 0px 0px",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: "block",
              md: "block",
              lg: "none",
              overflow: "hidden",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "white",
            },
          }}
        >
          <div className="flex justify-between items-center mt-2 ml-2 w-[225px] z-[100]">
            {isLoggedIn ? (
              <AfterGoogleSignin setisLoggedIn={setisLoggedIn} />
            ) : (
              <BeforeGoogleSignin
                isLoggedIn={isLoggedIn}
                setisLoggedIn={setisLoggedIn}
                generateToken={generateToken}
              />
            )}
            {/* Logo */}
            <NavLink to={isLoggedIn ? "/" : "/news"}>
              <div
                href="#"
                className={`flex align-items-center  p-2 justify-content-center text-base font-normal rounded-lg 
                   
                  `}
              >
                <div className="rounded-full relative h-10 w-10 align-middle bg-white mt-2 ">
                  <Logo
                    width={60}
                    height={58}
                    className="absolute -top-2 -left-2"
                  />
                </div>

                {/* <div className={`ml-3 flex items-center overflow-hidden  pt-2 w-full h-12 rounded-full bg-white `}>
                   <LogoFull height={130}/>
                    </div> */}
              </div>
            </NavLink>
          </div>

          <List>
            {navItems.map((item, index) => (
              <ListItem
                key={index}
                className="w-full"
                style={{
                  display: "block",
                  padding: "0px",
                }}
              >
                <NavLink exact to={item.to}>
                  <div
                    href="#"
                    className={`flex items-center p-2 ps-3 text-base font-normal hover:text-white  text-primaryHovered hover:bg-primaryHovered ${
                      activeTab === item.to
                        ? "bg-primaryHovered border-r-4 text-white "
                        : "text-primaryHovered"
                    }`}
                  >
                    <>
                      <div>{item.icon}</div>
                      <span className="flex-1 ml-3 whitespace-nowrap ">
                        {item.text}
                        {item.notif && item.notif !== 0
                          ? `(${item.notif})`
                          : null}
                      </span>
                    </>
                  </div>
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography></Typography>
      </Box>
    </Box>
  );
}

MobileNavbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MobileNavbar;
