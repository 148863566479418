import React from "react";
import { ASMFullForm, formatDate } from "../../utils/utils";
import { Skeleton } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ASMTable = ({
  tableHeader,
  rowData,
  loading,
  handleRowClick,
  sortColumn,
  sortOrder,
  handleSort,
  searchTerm
}) => {

  const navigate = useNavigate();

  const handleNameClick = (symbol, fincode) => {
    navigate(`/historic/${symbol}/${fincode}`);
  };

  return (
    <div className="my-2 w-full">
      <TableContainer component={Paper} sx={{ borderRadius: "0px" }}>
        <Table sx={{ borderRadius: "0px" }}>
          <TableHead
            className="bg-primaryHovered text-white font-extrabold"
            sx={{
              padding: "0px",
              "& .css-1ygcj2i-MuiTableCell-root": {
                padding: "2px 12px",
                fontWeight: "bold",
                fontSize: "16px",
              }
            }}
          >
            <TableRow>
              {tableHeader.map((header, index) => (
                <TableCell
                  key={index}
                  onClick={() => handleSort(header.value)}
                  className="cursor-pointer text-white font-semibold"
                >
                  <div className="flex gap-1 items-end">
                    {header.label}
                    {sortColumn === header.value && (
                      <span>{sortOrder === "asc" ? <ArrowDropDown /> : <ArrowDropUp />}</span>
                    )}
                    {header.value !== "" && (!sortColumn || sortColumn !== header.value) && (
                      <div className="flex flex-col items-center justify-center gap-0">
                        <span className="text-gray-400 h-4 w-4 overflow-hidden"><ArrowDropUp /></span>
                        <span className="text-gray-400 h-4 w-4 overflow-hidden"><ArrowDropDown /></span>
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: 10 }, (_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {tableHeader?.map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton animation="wave" variant="rectangular" height={20} width={100} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              rowData?.map((row, index) => (
                <TableRow
                  key={index}
                  className={`text-primaryText ${index % 2 === 0 && "bg-reportsTableHeading"}`}
                >
                  <TableCell
                    onClick={() => handleNameClick(row.symbol, row.fincode)}
                    className="!text-blue-500 cursor-pointer"
                  >
                    {row?.name}
                  </TableCell>
                  <TableCell>{(row?.deal_type).toUpperCase()}</TableCell>
                  <TableCell>{ASMFullForm(row?.asm_type)}</TableCell>
                  <TableCell>{"Stage " + row?.stage}</TableCell>
                  <TableCell>{row?.entry_date ? formatDate(row?.entry_date) : "Active"}</TableCell>
                  <TableCell>
                    <button
                      onClick={() => handleRowClick(row?.symbol, row?.name)}
                      className="rounded w-16 h-8 border text-blue-400 px-2 hover:border-blue-500 hover:bg-slate-200"
                    >
                      History
                    </button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ASMTable;
