import React from "react";

const CircleStack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1426 13.1429V16.5715C21.1426 18.0572 17.5609 20 13.1426 20C8.72429 20 5.14258 18.0572 5.14258 16.5715V13.7143"
        fill="currentColor"
      />
      <path
        d="M21.1426 13.1429V16.5715C21.1426 18.0572 17.5609 20 13.1426 20C8.72429 20 5.14258 18.0572 5.14258 16.5715V13.7143"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        cliprule="evenodd"
        d="M5.47852 14.0103C6.46709 15.3223 9.52423 16.5532 13.1425 16.5532C17.5608 16.5532 21.1425 14.7177 21.1425 13.1406C21.1425 12.2549 20.0145 11.2846 18.2442 10.6035"
        fill="currentColor"
      />
      <path
        d="M5.47852 14.0103C6.46709 15.3223 9.52423 16.5532 13.1425 16.5532C17.5608 16.5532 21.1425 14.7177 21.1425 13.1406C21.1425 12.2549 20.0145 11.2846 18.2442 10.6035"
        stroke="#F3F1F0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7139 7.42859V10.8572C17.7139 12.3429 14.1322 14.2857 9.71387 14.2857C5.29558 14.2857 1.71387 12.3429 1.71387 10.8572V7.42859"
        fill="currentColor"
      />
      <path
        d="M17.7139 7.42859V10.8572C17.7139 12.3429 14.1322 14.2857 9.71387 14.2857C5.29558 14.2857 1.71387 12.3429 1.71387 10.8572V7.42859"
        stroke="#F3F1F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.71387 10.8389C14.1322 10.8389 17.7139 9.00343 17.7139 7.42629C17.7139 5.848 14.1322 4 9.71387 4C5.29558 4 1.71387 5.848 1.71387 7.42629C1.71387 9.00343 5.29558 10.8389 9.71387 10.8389Z"
        fill="currentColor"
        stroke="#F3F1F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CircleStack;
