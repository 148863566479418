import React from "react";
import { ReactComponent as LogoFull } from "../../../src/assets/svg/footerLogo.svg";

const Footer = () => {
  return (
    <section className="rounded-lg">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 bg-blue-900 pt-4 rounded-lg">
        <div className="grid grid-cols-1 lg:grid-cols-10 gap-2 lg:gap-4 pb-3">
          <div className="lg:col-span-5 flex flex-row sm:flex-row justify-center gap-6 lg:gap-12">
            <div className="flex flex-col gap-4">
              <ul className="flex flex-col gap-2">
                <li>
                  <a href="/" className="text-sm font-normal text-white">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/about" className="text-sm font-normal text-white">
                    About
                  </a>
                </li>
                <li>
                  <a href="/careers" className="text-sm font-normal text-white">
                    Careers
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-4">
              <ul className="flex flex-col gap-2">
                <li>
                  <a
                    href="/news-portal/latest-news"
                    className="text-sm font-normal text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Latest News
                  </a>
                </li>
                <li>
                  <a
                    href="/news-portal/stock-in-news"
                    className="text-sm font-normal text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stock In News
                  </a>
                </li>
                <li>
                  <a
                    href="/news-portal/results"
                    className="text-sm font-normal text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Results
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-4">
              <ul className="flex flex-col gap-2">
                <li>
                  <a
                    href="/news-portal/companies"
                    className="text-sm font-normal text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Companies
                  </a>
                </li>
                <li>
                  <a
                    href="/news-portal/industry"
                    className="text-sm font-normal text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Industry
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:col-span-5 flex flex-col items-center gap-2 sm:gap-4 mt-3 sm:mt-1">
            <p className="text-gray-500 text-sm font-normal text-center">
              <span className="text-white font-medium">
                Mobile: +91-9440686852
              </span>
            </p>
            <p className="text-gray-500 text-sm font-normal text-center">
              <span className="text-white font-medium">
                Email: customerservice@dailystockhunt.com
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse gap-4 md:flex-row items-center justify-between border-t-2 border-gray-200 pt-4">
          <a href="/">
            <LogoFull className="w-32 h-16 md:w-48 md:h-20" />
          </a>
          <p className="font-normal text-xs text-center text-white">
            ©<a href="/">2024</a> Daily Stock Hunt. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
