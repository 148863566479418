import React, { useEffect, useState } from 'react';
import NewsAds from './NewsAds';
import { useNavigate } from 'react-router-dom';
import YoutubeEmbed from './YoutubeEmbed';
import axios from 'axios';
import { apiUrl } from "../../utils/utils";
import RightArrowBold from '../../assets/svg/rightArrowBold.svg';

const apiHost = process.env.REACT_APP_BACKEND_URL;

const Sidebar = ({ newsData }) => {
  const navigate = useNavigate();
  const [newsTabs, setNewsTabs] = useState([]);
  const [tabId, setTabId] = useState(null);
  const [industryNews, setIndustryNews] = useState([]);
  const [resultsNews, setResultsNews] = useState([]);

  const sortedNewsData = newsData.sort((a, b) => new Date(b.date) - new Date(a.date));

  const topThreeNews = sortedNewsData.slice(0, 3);

  useEffect(() => {
    getTabs();
  }, []);

  useEffect(() => {
    if (newsTabs.length) {
      const industryTab = newsTabs.find((tab) => tab.label === "Industry");
      const resultsTab = newsTabs.find((tab) => tab.label === "Results");

      if (industryTab) {
        fetchRelatedNews(industryTab.id, setIndustryNews);
      }

      if (resultsTab) {
        fetchRelatedNews(resultsTab.id, setResultsNews);
      }
    }
  }, [newsTabs]);

  // Function to handle news item click
  const handleClick = (id) => () => {
    console.log('Clicked on news item with id:', id);
    navigate(`/news-portal/news/${id}`);
  };

  const createSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const getTabs = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/portal-news-tags`);
      const sortedTabs = response.data.sort((a, b) => a.order - b.order);
      const tabs = sortedTabs.map((tab) => ({
        label: tab.tag,
        path: `/news-portal/${createSlug(tab.tag)}`,
        id: tab.id,
      }));
      tabs.unshift({ label: "Latest News", path: "/news-portal/latest-news" });
      setNewsTabs(tabs);
      setTabId(tabs[0].id);
    } catch (error) {
      console.error("Error fetching news tabs", error);
    }
  };

  const fetchRelatedNews = async (tabId, setNews) => {
    try {
      const response = await axios.get(`${apiUrl}/api/portal-news?tags=${tabId}`);
      setNews(response.data.results);
    } catch (error) {
      console.error("Error fetching related news", error);
    }
  };

  return (
    <div className='w-[25%] sticky top-0 space-y-2 hidden md:block'>
      {/* Latest News Section */}
      <div className='px-2 py-2 bg-[#f5f5dc] rounded'>
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">LATEST NEWS</p>
        <div className='space-y-2 pb-4'>
          {Array.isArray(topThreeNews) && topThreeNews.map((newsItem, index) => (
            <div
              key={index}
              className='space-y-2 cursor-pointer flex items-start'
              onClick={handleClick(newsItem.id)}
            >
              <div className='mr-2 flex-shrink-0 mt-2.5'>
                <img src={RightArrowBold} alt="Right Arrow" className="w-4 h-4" />
              </div>
              <div>
                <p className="text-sm text-gray-500 font-merriweather">{newsItem.category}</p>
                <p className="text-sm font-normal hover:underline font-merriweather border-b border-gray-400">{newsItem.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='flex justify-end'>
          <button
            className="text-blue-500 font-merriweather hover:underline"
            onClick={() => navigate('/news-portal/latest-news')}
          >
            View More
          </button>
        </div>
      </div>

      {/* Industry Section */}
      <div className='px-2 py-2 bg-[#fff4f0] rounded'>
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">INDUSTRY</p>
        <div className='space-y-2 pb-4'>
          {Array.isArray(industryNews) && industryNews.map((newsItem, index) => (
            <div
              key={index}
              className='space-y-2 cursor-pointer flex items-start'
              onClick={handleClick(newsItem.id)}
            >
              <div className='mr-2 flex-shrink-0 mt-2.5'>
                <img src={RightArrowBold} alt="Right Arrow" className="w-4 h-4" />
              </div>
              <div>
                <p className="text-sm text-gray-500 font-merriweather">{newsItem.category}</p>
                <p className="text-sm font-normal hover:underline font-merriweather border-b border-gray-400">{newsItem.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='flex justify-end'>
          <button
            className="text-blue-500 font-merriweather hover:underline"
            onClick={() => navigate('/news-portal/industry')}
          >
            View More
          </button>
        </div>
      </div>

      {/* Results Section */}
      <div className='px-2 py-2 bg-[#f2e8e0] rounded'>
        <p className="text-lg font-bold my-2 font-merriweather border-b-2 border-gray-400">RESULTS</p>
        <div className='space-y-2 pb-4'>
          {Array.isArray(resultsNews) && resultsNews.map((newsItem, index) => (
            <div
              key={index}
              className='space-y-2 cursor-pointer flex items-start'
              onClick={handleClick(newsItem.id)}
            >
              <div className='mr-2 flex-shrink-0 mt-2.5'>
                <img src={RightArrowBold} alt="Right Arrow" className="w-4 h-4" />
              </div>
              <div>
                <p className="text-sm text-gray-500 font-merriweather">{newsItem.category}</p>
                <p className="text-sm font-normal hover:underline font-merriweather border-b border-gray-400">{newsItem.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='flex justify-end'>
          <button
            className="text-blue-500 font-merriweather hover:underline"
            onClick={() => navigate('/news-portal/results')}
          >
            View More
          </button>
        </div>
      </div>

      {/* Youtube Videos Section */}
      <div className='p-4 bg-white shadow rounded'>
        <p className='text-xl font-bold my-4 font-merriweather'>Youtube Videos</p>
        <YoutubeEmbed embedId="tC_qwBJ1kos" />
        <YoutubeEmbed embedId="tC_qwBJ1kos" />
      </div>
    </div>
  );
};

export default Sidebar;

