import React, { useEffect, useState } from "react";
import ASMTable from "./ASMTable";
import axios from "axios";
import { Button, Pagination } from "@mui/material";
import ASMModal from "./ASMModal";
import { search } from "../../utils/utils";
import Faq from "../FAQ/faq";

const PAGE_SIZE = 40;

const ASM = ({ searchTerm, asmSearch, setAsmSearch, type }) => {
  const [ASMDeals, setASMDeals] = useState([]);
  const [individualASMDeal, setIndividualASMDeal] = useState([]);
  const [selectedASMName, setSelectedASMName] = useState("");
  const [ASMLoader, setASMLoader] = useState({
    ASMLoader: false,
    individuaASMLoader: false,
  });
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const tableHeader = [
    { label: "Stock Name", value: "name", width: "150px" },
    { label: "Exchange", value: "deal_type", width: "100px" },
    { label: "Long/Short Term", value: "asm_type", width: "150px" },
    { label: "Stage", value: "stage", width: "50px" },
    { label: "Entry date", value: "entry_date", width: "70px" },
    { label: "History", value: "", width: "50px" }, // No sorting for this column
  ];

  const fetchASMDeals = async (url) => {
    try {
      setASMLoader((prev) => ({
        ...prev,
        ASMLoader: true,
      }));

      let apiUrl =
        type === "asm-active"
          ? url ||
            `${process.env.REACT_APP_BACKEND_URL}/api/asm-deals/?page=${currentPage}&is_active=true`
          : `${process.env.REACT_APP_BACKEND_URL}/api/asm-deals/?page=${currentPage}&is_active=false`;

      // Add sorting parameters
      if (sortColumn) {
        apiUrl += `&ordering=${sortOrder === "desc" ? "-" : ""}${sortColumn}`;
      }

      if (asmSearch) {
        apiUrl += `&search=${searchTerm}`;
      }

      const response = await axios.get(apiUrl + `&page_size=${PAGE_SIZE}`);
      setASMDeals(response.data.results);
      setCount(response.data.count);
      setASMLoader((prev) => ({
        ...prev,
        ASMLoader: false,
      }));
      setAsmSearch(false);
    } catch (error) {
      setASMLoader((prev) => ({
        ...prev,
        ASMLoader: false,
      }));
      console.error("Error in ASM:", error);
    }
  };

  useEffect(() => {

    fetchASMDeals();
  }, [currentPage, sortColumn, sortOrder]);

  useEffect(() => {
    if (asmSearch) {
      fetchASMDeals();
    }

    if (asmSearch === false && searchTerm === "") {
      fetchASMDeals();
    }
  }, [asmSearch, searchTerm]);

  const handleSort = (column) => {
    setCurrentPage(1);
    if (column === "") return; //If history header is clicked then do nothing
    if (column === sortColumn) {
      // If same column is clicked again, toggle sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set it as the sort column with default sort order "asc"
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  // TODO: Make this api call a part of the fetchASMDeals for implementation of DRY
  const fetchIndividualASMDeals = async (symbol) => {
    try {
      setASMLoader((prev) => ({
        ...prev,
        individuaASMLoader: true,
      }));
      const apiURL =
        process.env.REACT_APP_BACKEND_URL + `/api/asm-deals/${symbol}`;
      const response = await axios.get(apiURL);
      setIndividualASMDeal(response.data);

      setASMLoader((prev) => ({
        ...prev,
        individuaASMLoader: false,
      }));
    } catch (error) {
      setASMLoader((prev) => ({
        ...prev,
        individuaASMLoader: false,
      }));
      console.error("Error in Individual ASM::", error);
    }
  };

  const handleRowClick = (symbol, name) => {
    handleOpen();
    fetchIndividualASMDeals(symbol);
    setSelectedASMName(name);
  };

  // console.log(searchTerm, asmSearch)
  return (
    <div>
      <div
        style={{
          display: "block",
        }}
        className="w-full table-row flex-col  pt-2 rounded-xl"
      >
        <ASMModal
          handleClose={handleClose}
          open={open}
          rowData={individualASMDeal}
          DealName={selectedASMName}
          loading={ASMLoader.individuaASMLoader}
        />
        <div className="flex flex-col">
          <div className="bulk-deals d-flex flex-column mt-3">
            <ASMTable
              tableHeader={tableHeader}
              rowData={ASMDeals}
              loading={ASMLoader.ASMLoader}
              handleRowClick={handleRowClick}
              handleSort={handleSort}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
            />
          </div>
          <div className="flex justify-center">
            <Pagination
              // variant="outlined"
              color="secondary"
              count={Math.ceil(count / PAGE_SIZE)}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
                // setPage(page);
                const url =
                  process.env.REACT_APP_BACKEND_URL +
                  `/api/asm-deals/?page=${currentPage}`;
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Faq />
      </div>
    </div>
  );
};

export default ASM;