import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../utils/utils";
import Ads from "../../components/Ads";

const IPODetail = ({ sideMenuCollapsed }) => {
  const { ipo_name } = useParams();
  const [ipoData, setIpoData] = useState({});

  const fetchIpoDetails = async () => {
    try {
      const response = await axios.get(apiUrl + `/api/ipo-data/${ipo_name}`);
      setIpoData(response.data);
    } catch (error) {
      console.error("Error While fetching ipo details::", error);
    }
  };

  useEffect(() => {
    fetchIpoDetails();
  }, []);

  const renderSection = (sectionKey, tableData, description) => {
    if (!tableData || tableData.length === 0) return null;
    const sectionName = sectionKey.replace(/_/g, " ").toUpperCase();
    return (
      <div>
        <div className="font-bold text-darkBlue flex flex-col md:px-6 mt-8">
          <h1 className="text-2xl">{`${ipo_name} ${sectionName}`}</h1>
          {description && (
            <div className="py-4 text-md font-normal">{description}</div>
          )}
        </div>
        <div
          className="overflow-x-auto overflow-y-auto w-full md:px-8"
          style={{ maxHeight: "500px" }}
        >
          <table className="bg-white table-fixed w-full">
            <thead>
              <tr className="bg-tableDarkHeader text-white">
                {Object.keys(tableData[0]).map((key) => (
                  <th key={key} className="px-2 py-1 border">
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, idx) => (
                <tr
                  key={idx}
                  className={idx % 2 === 0 ? "bg-tableBody" : "bg-white"}
                >
                  {Object.entries(row).map(([key, value], idx) => (
                    <td
                      key={idx}
                      className="px-2 py-1 border text-sm overflow-auto"
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="flex">
      <div
        className={`flex rounded-md flex-col pb-10 justify-between  md:px-0 mt-10 md:mt-2 bg-white h-full ${
          sideMenuCollapsed ? "md:w-full" : "md:w-[83%]"
        } transition-all duration-500`}
      >
        {/* Title */}
        <div className="h-16 font-bold text-darkBlue flex justify-center items-center border-b w-full">
          <h1 className="text-2xl">{ipo_name}</h1>
        </div>
        {/* Description */}
        <div
          className="flex justify-center md:px-16 py-4 text-md font-normal"
          dangerouslySetInnerHTML={{ __html: ipoData?.description }}
        ></div>
        <div>
          {/* Dynamic Sections */}
          {Object.entries(ipoData).map(([key, value]) =>
            renderSection(key, value.table_data, value.description)
          )}
        </div>
      </div>
      <div className="hidden md:block">
        <Ads />
      </div>
    </div>
  );
};

export default IPODetail;
