import { Slider, useTheme } from "@mui/material";
import React from "react";

const CustomSlider = ({handleSliderChange}) => {

  const theme = useTheme();
  return (
    <div className="w-72 px-2">
      <h3 className="font-semibold">Market Cap</h3>
      <div className="flex justify-between font-semibold">
        <p>₹0</p>
        <p>₹100K Cr</p>
      </div>
      <Slider
        min={0}
        max={100000}
        
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        sx={{
          height: "10px",
          "& .MuiSlider-track": {
            background: `linear-gradient(270deg, #6C4E62 7.72%, rgba(141, 106, 129, 0.50) 100%);
              `,
          },
          "& .MuiSlider-rail": {
            boxShadow: `inset 0px 2px 2px black`,
          },
          "& .MuiSlider-thumb": {
            backgroundColor: "white",
            border: "5px solid",
          },
          "& .MuiSlider-valueLabel": {
            borderRadius: "10px",
            backgroundColor: theme.palette.primary.main,
          },
        }}
      />
    </div>
  );
};

export default CustomSlider;
