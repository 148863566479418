import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Chip } from "@mui/material";
import "./StockInAction.css";
import StockCard from "./StockCard";
import Ads from "../../components/Ads";
import Bell from "../../components/Icons/Bell";
import NewspaperIcon from "../../components/Icons/NewspaperIcon";
import ArrowUpandDownIcon from "../../components/Icons/ArrowUpandDownIcon";
import TagIcon from "../../components/Icons/TagIcon";
import StockInNews from "../../components/StockInNews";
import GainersAndLosers from "../../components/GainersAndLosers";
import BuyersAndSellers from "../../components/BuyersAndSellers";
import axios from "axios";

const StockInAction = ({sideMenuCollapsed}) => {
  const [groupType, setGroupType] = useState([]);
  const [selectedTab, setSelectedTab] = useState("stock_in_news");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Update the selected tab when changed
  };

  // const filteredCards = cards?.filter((card) => card.stock_type === category);

  useEffect(() => {
    const fetchData = async () => {
      const excode = "BE49H6S";
      const watchlistToken = JSON.parse(localStorage.getItem("WatchlistToken"));
      try {
        const response = await axios.get(
          `https://stock.accordwebservices.com/BSEStock/GetIndexList?ExCode=${excode}&IndexCode=&token=${watchlistToken}`
        );
        const result = response.data?.Table || [];

        // Extract the first 21 values except the first one
        const slicedData = result.slice(1, 22); // Ensure 21 values starting from the second one

        setGroupType(slicedData);
        // console.log(groupType);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

// console.log(sideMenuCollapsed)
  return (
    <div className={`w-full h-full md:flex mt-16 md:mt-2 ${sideMenuCollapsed == true ? "md:ml-0" : "md:ml-28" } transition-all duration-500`}>
      <div className="stock-card-container bg-white mt-2 h-full md:w-[83%] overflow-auto !container rounded-lg pb-3 px-3">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="Stock In Action Tabs"
          indicatorColor="primary"
          sx={{ height: "60px", borderBottom: "1px solid lightgray" }}
        >
          <Tab
            label="Stock in News"
            value="stock_in_news"
            className={selectedTab === "stock_in_news" ? "selected" : ""}
            icon={<NewspaperIcon />}
            iconPosition="start"
          />
          <Tab
            label="Gainers & Losers"
            value="gainers_losers"
            className={selectedTab === "gainers_losers" ? "selected" : ""}
            icon={<ArrowUpandDownIcon />}
            iconPosition="start"
          />
          <Tab
            label="Only Buyers & Sellers"
            value="buyers_sellers"
            className={selectedTab === "buyers_sellers" ? "selected" : ""}
            icon={<TagIcon />}
            iconPosition="start"
          />
        </Tabs>
        {selectedTab === "stock_in_news" ? (
          <StockInNews />
        ) : selectedTab === "gainers_losers" ? (
          <GainersAndLosers groupType={groupType} />
        ) : (
          <BuyersAndSellers groupType={groupType} />
        )}
      </div>
      <div className="hidden md:block">

      <Ads />
      </div>
    </div>
  );
};

export default StockInAction;
