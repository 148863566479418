import React from "react";

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 2C8.26806 2 2 8.26806 2 16C2 23.7324 8.26806 30 16 30C23.7324 30 30 23.7324 30 16C30 8.26806 23.7324 2 16 2ZM16 28.2776C9.24544 28.2776 3.75 22.7546 3.75 15.9999C3.75 9.24538 9.24544 3.74995 16 3.74995C22.7546 3.74995 28.25 9.24541 28.25 15.9999C28.25 22.7545 22.7546 28.2776 16 28.2776ZM22.125 15.125H16.875V9.875C16.875 9.392 16.483 9 16 9C15.517 9 15.125 9.392 15.125 9.875V15.125H9.875C9.392 15.125 9 15.517 9 16C9 16.483 9.392 16.875 9.875 16.875H15.125V22.125C15.125 22.608 15.517 23 16 23C16.483 23 16.875 22.608 16.875 22.125V16.875H22.125C22.608 16.875 23 16.483 23 16C23 15.517 22.608 15.125 22.125 15.125Z"
        fill="#8B939B"
      />
    </svg>
  );
};

export default PlusIcon;
