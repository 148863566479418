import React from 'react';
import LogoutIcon from '../Icons/LogoutIcon';


const AfterGoogleSignin = ({  setisLoggedIn }) => {

    const logout = () => {
        localStorage.clear();
        // window.location.reload(false);
        setisLoggedIn(false);
        window.location = "/news";
      };

  return (
    <div className="dropdown">
      <button
        className="dropdown-toggle btn bg-white hover:bg-[#F8F7F8] rounded-full border-1 border-black hover:border-1 hover:border-black"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {JSON.parse(localStorage.getItem("Username"))}
      </button>
      <ul
        onClick={logout}
        className="dropdown-menu rounded-lg hover:bg-hoveredWhite  cursor-pointer"
      >
        <li className="flex px-2 py-1 justify-start gap-2 items-center">
          <LogoutIcon />
          <button className="  cursor-pointer">Logout</button>
        </li>
      </ul>
    </div>
  );
};

export default AfterGoogleSignin;
