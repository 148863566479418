import React, { Component, useEffect } from "react";
import TableRows from "../TableRow/TableRows";
import axios from "axios";
import "./RenderTable.css";

import { toast } from "react-toastify";
import { Skeleton, TextField } from "@mui/material";

import CancelIcon from "../Icons/CancelIcon";

import WatchlistNews from "../WatchlistNews";
const apiHost = process.env.REACT_APP_BACKEND_URL;

export default class RenderTable extends Component {
  constructor(props) {
    super(props);

    // this.ws = new WebSocket("wss://tradeinvst.com/ws/live-data/");
    this.state = {
      stockData: new Map(),
      stocks: [],
      finCode: [],
      counter: [],
      loading: false,
      selectedSymbols: new Set(),
      searchValue: "",
      selectedValues: [],
      newStockData: new Map(),
      selectedRowSymbol: "",
      symbolOptions: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchStockSymbols();
    this.fetchWathlist();

    // Initial fetch
    // this.fetchData();

    // Set interval to fetch data every 60 seconds
    this.fetchInterval = setInterval(this.fetchData, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchInterval);
  }

  fetchStockSymbols = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/stock-symbols`);
      this.setState({ symbolOptions: response.data });
    } catch (err) {
      console.error("Error fetching stock symbols:", err);
    }
  };

  fetchData = async () => {
    try {
      const watchlistToken = JSON.parse(localStorage.getItem("WatchlistToken"));
      const { finCode } = this.state;

      if (
        !Array.isArray(finCode) ||
        finCode.every((value) => value === undefined)
      ) {
        this.setState({
          loading: false,
        });
        return;
      }
      const promises = finCode.map(async (code) => {
        const response = await axios.get(
          `https://stock.accordwebservices.com/BSEStock/GetQuoteFinder?fincode=${code}&token=${watchlistToken}`
        );

        return response.data;
      });

      const dataArray = await Promise.all(promises);

      const extractedData = dataArray.reduce((acc, data) => {
        const extracted = data?.Table?.map((item) => ({
          symbol: item.SYMBOL === "" ? item.S_NAME.toUpperCase() : item.SYMBOL,
          ltp: item.CLOSE,
          day_high: item.HIGH,
          day_low: item.LOW,
          change: item.PER_CHANGE,
          volume: item.VOLUME,
        }));
        return [...acc, ...(extracted || [])];
      }, []);

      const newStockDataMap = new Map();
      extractedData.forEach((item) => {
        newStockDataMap.set(item.symbol, item);
      });

      this.setState({
        newStockData: newStockDataMap,
        loading: false,
      });
    } catch (err) {
      console.log(err);
      if (err.code == "ERR_NETWORK") {
        this.generateToken(this.fetchData);
      } else {
        console.log("Error:", err);
      }
    }
  };

  fetchWathlist = () => {
    if (!JSON.parse(localStorage.getItem("WatchlistToken"))) {
      console.log("Token doesn't exist");
      this.generateToken(this.fetchWathlist);
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
      },
    };

    axios
      .get(apiHost + "/api/watchlist/", config)
      .then((res) => {
        if (res && res.status && res.status === 200) {
          const watchlistData = res.data.symbols;
          const symbolList = Object.keys(watchlistData);
          const finCodeList = Object.values(watchlistData)?.map(
            (symbolData) => symbolData?.fincode
          );

          this.setState(
            {
              stocks: symbolList,
              finCode: finCodeList,
              counter: watchlistData,
            },
            () => {
              // Once the watchlist data is fetched, proceed to fetch the data using finCodes
              this.fetchData();
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response && err.response.status === 403) {
          this.generateToken(this.handleSubmit);
        }
        if (err.response.status == 401) {
          localStorage.clear();
          window.location.href = "/";
          toast.error("Session Expired! Please login again");
        } else {
          toast.error("Unable to fetch watchlist at the moment!");
        }
        console.log(err);
      });
  };

  symbolListItem = [];

  handleSubmit = (e) => {
    e.preventDefault();

    // Get selected symbols from the state
    const values = Array.from(this.state.selectedSymbols);

    // Call the post API to save stock symbols in the backend
    const data = {
      symbols: values,
    };

    axios
      .post(apiHost + "/api/watchlist/", data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
        },
      })
      .then((res) => {
        this.setState(
          {
            stocks: res.data.symbols,
            selectedSymbols: new Set(),
          },
          () => {
            this.setState({ searchValue: "" }, () => {
              this.fetchWathlist();
            });
          }
        );
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          this.generateToken(this.handleSubmit);
        }
        // console.log(err.response.status)
        if (err.response.status == 401) {
          localStorage.clear();
          window.location.href = "/";
          toast.error("Session Expired! Please login again");
        } else {
          toast.error("Unable to save watchlist at the moment!");
        }
        console.log(err);
      });
  };

  // call delete api to delete stock sybols in backend
  handleDelete = (symbol) => {
    let payload = {
      symbol: symbol,
    };
    let headers = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
    };
    axios
      .delete(apiHost + "/api/watchlist/", { headers, data: payload })
      .then((res) => {
        // Update the stocks array by removing the deleted symbol
        let newStocks = this.state.stocks.filter((item) => item !== symbol);

        // Update the newStockData map by deleting the corresponding entry
        let newStockData = new Map(this.state.newStockData);
        newStockData.delete(symbol);

        this.setState(
          {
            stocks: newStocks,
            newStockData: newStockData,
          },
          () => {
            // Add symbol back to options
            this.symbolListItem.push(symbol);
            this.fetchWathlist();
          }
        );

        toast.success(res.data.deleted);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          this.generateToken(this.handleDelete);
        }
        if (err.response.status == 401) {
          localStorage.clear();
          window.location.href = "/";
          toast.error("Session Expired! Please login again");
        } else {
          toast.error("Unable to delete watchlist item at the moment!");
        }
        console.log(err);
      });
  };

  //initialize connection

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleCheckboxChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const { selectedSymbols } = prevState;
      if (selectedSymbols.has(value)) {
        selectedSymbols.delete(value);
      } else {
        selectedSymbols.add(value);
      }
      // Create a new Set to update the state (since Sets are reference types)
      const updatedSelectedSymbols = new Set(selectedSymbols);
      return { selectedSymbols: updatedSelectedSymbols };
    });
  };

  generateToken = (callbackFn) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));

    axios
      .get(apiHost + "/api/get-client-token", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((response) => {
        const newToken = response.data.token;
        localStorage.setItem("WatchlistToken", JSON.stringify(newToken));
        if (callbackFn) {
          callbackFn();
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          localStorage.clear();
          window.location.href = "/";
          toast.error("Session Expired! Please login again");
        }
        console.error("Error generating token:", err);
      });
  };

  render() {
    // remove symbols from options which user already have in his watch list
    for (let i in this.state.newStockData) {
      let symbol = this.state.newStockData[i];

      let index = this.symbolListItem.indexOf(symbol);
      if (index > -1) {
        this.symbolListItem.splice(index, 1);
      }
    }
    const { symbolOptions } = this.state;
    const filteredOptions = symbolOptions.filter(
      (stock) =>
        stock.symbol
          .toLowerCase()
          .startsWith(this.state.searchValue.toLowerCase()) ||
        stock.name
          .toLowerCase()
          .startsWith(this.state.searchValue.toLowerCase())
    );
    this.symbolListItem = this.symbolListItem.sort();

    const dynamicOptions = filteredOptions.map((stock) => {
      return (
        <div
          className="mb-2 w-full  overflow-auto border-b-slate-800"
          key={stock.symbol}
        >
          <label className="flex gap-2 p-2 pl-4">
            <input
              type="checkbox"
              value={stock.symbol}
              onChange={this.handleCheckboxChange}
              checked={
                this.state.selectedSymbols.has(stock.symbol) ||
                this.state.stocks.includes(stock.symbol)
              }
              className=" accent-primaryBrown"
            />
            <div className="text-md">{stock.symbol}</div>
          </label>
          <hr></hr>
        </div>
      );
    });

    const tableHeader = [
      "Company",
      "Last",
      "High",
      "Low",
      "Change",
      "Volume",
      "Remove",
    ];

    const removeSelectedSymbol = (value) => {
      this.setState((prevState) => {
        const { selectedSymbols } = prevState;
        selectedSymbols.delete(value);
        const updatedSelectedSymbols = new Set(selectedSymbols);
        return { selectedSymbols: updatedSelectedSymbols };
      });
    };

    // console.log(this.state.counter)
    // console.log(this.state.newStockData)

    return (
      <div className="flex flex-col gap-3 px-1 md:px-2 w-full">
        <div
          className="  bg-mainBackground  w-full  h-full mt-0 rounded-xl shadow-md"
          style={{ overflow: "auto" }}
        >
          <div className="container-fluid px-0 md:px-2 mt-1 w-full">
            <div className="container my-1">
              <div className="flex justify-between items-center my-2">
                <h5 className="text-xl text-primaryBrown">WatchList</h5>
                <button
                  className="btn rounded-full bg-primaryBtn hover:bg-primaryHovered text-white px-2 py-0"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Add Stock
                </button>
              </div>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered ">
                  <div className="modal-content rounded-xl">
                    <form onSubmit={this.handleSubmit}>
                      <div className="modal-header">
                        <h5
                          className="modal-title font-semibold text-secondaryPurple"
                          id="exampleModalLabel"
                        >
                          Add Stocks
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={this.state.searchValue}
                          onChange={this.handleSearchChange}
                          label="Type here to search..."
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                          className="mb-2"
                        />

                        {this.state.searchValue ? (
                          <div className="symbolList bg-primaryHovered bg-opacity-50 rounded-md h-[400px]">
                            {dynamicOptions}
                          </div>
                        ) : (
                          <div className="symbolList bg-primaryHovered bg-opacity-50 rounded-md h-[400px] flex justify-center items-center font-semibold">
                            Search a stock to see the results
                          </div>
                        )}

                        {this.state.selectedSymbols.size > 0 && (
                          <div className="mt-2">
                            <div className="font-semibold text-primaryBrown">
                              Selected Stocks:
                            </div>
                            <div className="mt-2 flex flex-wrap rounded-xl p-2 pb-0">
                              {Array.from(this.state.selectedSymbols).map(
                                (value) => (
                                  <span
                                    className="flex gap-1 mr-2 mb-2 border-1 border-black   rounded-xl bg-secondaryHovered h-10 p-2 "
                                    key={value}
                                  >
                                    {value}
                                    <span
                                      className="cursor-pointer "
                                      onClick={() =>
                                        removeSelectedSymbol(value)
                                      }
                                    >
                                      <CancelIcon />
                                    </span>
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="modal-footer">
                        <button
                          type="submit"
                          className="btn px-4 rounded-full bg-primaryBtn hover:bg-primaryHovered text-white "
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="min-h-52 max-h-64 overflow-y-auto">
                <table className="table w-full" id="renderTable">
                  <thead
                    className=" bg-primaryHovered text-white font-semibold rounded-t"
                    style={{ position: "sticky", top: 0, zIndex: 1 }}
                  >
                    <tr>
                      {tableHeader.map((title, index) => (
                        <th key={index} className="text-white" scope="col">
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {this.state.loading ? (
                    <tbody className="h-96  overflow-y-auto">
                      {Array.from({ length: 10 }, (_, rowIndex) => (
                        <tr key={rowIndex}>
                          {tableHeader.map((_, colIndex) => (
                            <td key={colIndex}>
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={20}
                                width={50}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  ) : this.state.newStockData?.size > 0 ? (
                    Array.from(this.state.newStockData, ([symbol, rowData]) => (
                      <TableRows
                        key={symbol}
                        symbol={symbol}
                        rowData={rowData}
                        counter={this.state.counter[symbol]}
                        handleDelete={this.handleDelete}
                        setSelectedSymbol={() =>
                          this.setState({ selectedRowSymbol: symbol })
                        }
                        selectedSymbol={this.state.selectedRowSymbol}
                      />
                    ))
                  ) : (
                    <tbody>
                      <tr>
                        <td className="pt-2" colSpan={6} align="center">
                          Click on the <b>Add Stock</b> button to add items to
                          your watchlist
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primaryPurple rounded p-2 px-1 shadow-md flex justify-center w-full">
          <WatchlistNews symbol={this.state.selectedRowSymbol} />
        </div>
        {/* <Ads /> */}
      </div>
    );
  }
}
